import { StyleSheet } from "react-native";
import { ifSmall } from "../../utils/utils";
import buttons from "../pieces/buttons";
import cells from "../pieces/cells";
import msgs from "../pieces/msgs";
import { common, header, i } from "../pieces/shared";

const txt = {
  marginTop: 15,
  fontFamily: "Roboto",
  fontSize: 13,
  color: "#6A8287",
}

const tblCtSmall = ifSmall({
  borderRightWidth: 2*StyleSheet.hairlineWidth,
  borderRightColor: '#888',  
},{})

const p = {
  ...common,
  ...header,
  ...cells,
  ...msgs,
  ...buttons,

  // msg
  msgBodyCt: {
    paddingVertical: 5,
    paddingHorizontal: 20,
  },
  msgBody: {
    ...txt
  },

  // Detail Table
  tblCt: {width: '87%', alignSelf: 'center', marginTop: 35, borderBottomColor: '#888', borderBottomWidth: StyleSheet.hairlineWidth, ...tblCtSmall},
  tblLine: {flex: 1, flexDirection: 'row', alignItems: 'center', borderWidth: StyleSheet.hairlineWidth, borderColor: '#888'},
  cellLeft: {color: "#6A8287", fontFamily: 'Roboto', paddingLeft: 10, paddingRight: 10, fontSize: 13},
  cellRight: {alignSelf: "center", backgroundColor: 'transparent', color: "#6A8287", fontFamily: 'Roboto', flex: .65, padding: 10, fontSize: 13},
  // , marginBottom: 50
  infoCellTxt: { color: "#6A8287", fontFamily: 'Roboto', flex: .7, fontSize: 13 },
  infoCellLink: { color: "#9dafb3", fontFamily: 'Roboto', flex: .7, fontSize: 12 },

  infoMsg: {
    marginTop: 30, 
    paddingHorizontal: 50, 
    alignSelf: 'center',
    fontSize: 13,
    fontFamily: "Roboto",
    color: "yellow",
    flex: 0,
  },
}

const s = StyleSheet.create(p)

const i2 = i;
export { s, i2 as i };

 