import { WebAlertComponent, WebAlertComponentState } from "./web-alert-component"

class WebAlert {
  component: any

  setComponent(component: WebAlertComponent){
    this.component = component
  }

  show(conf: WebAlertComponentState){
    return this.component.show(conf)
  }

  close(){
    if (!this.component)
      return
    this.component.hide()
  }
}

export const webAlert = new WebAlert()
