import { Body, Icon, Left, ListItem, Right, Text } from "native-base";
import React from "react";
import { s } from "../theme/screens/leadList";
import { dateHour, checkValidPhase, ci, blank } from "../utils/utils";

export interface LeadListCellProps {
  leadTapped: Function
  data: any|null
}

export class LeadListCell extends React.Component<LeadListCellProps, {}> {
  showBlueCircle = (lead) => {
    return lead.new_msg_count > 0
  }

  showRedCircle = (lead) => {
    return lead.is_new
  }

  // activityColor = (lead) => {
  //   if ([0, 1, 5].indexOf(lead.status) != -1) {
  //     if (lead.activity_late) {
  //       return 'statusBarRed'
  //     } else {
  //       return 'statusBarBlue'
  //     }
  //   } else if(lead.status == 2) {
  //     return 'statusBarGreen'
  //   } else {
  //     return 'statusBarTransp'
  //   }
  // }

  statusColor = (lead) => {
    if (checkValidPhase(lead)) {
      if (lead.activity_late && lead.activity_status == 0) {
        return 'subTitleRed'
      } else {
        return 'subTitleBlue'
      }
    } else if (lead.crmphase == "#5ed30d") {
      return 'subTitleGreen'
    } else {
      return 'subTitleNormal'
    }
  }

  lead2Row = (lead: any) => {
    return {
      id: lead.id,

      // activityColor: this.activityColor(lead),
      showBlueCircle: this.showBlueCircle(lead),
      showRedCircle: this.showRedCircle(lead),
      statusColor: this.statusColor(lead),

      title: lead.customer.name,
      desc: lead.custom_desc,
      sub_title: lead.sub_title,
      isFavorite: lead.is_favorite,

      time: lead.show_time,
      price: lead.product.price,
      seller: lead.seller.name,

      key: lead.key,
    }
  }

  render() {
    const item = this.lead2Row(this.props.data)
    return (
      <ListItem style={s.leadItem} key={item.key} onPress={() => this.props.leadTapped(this.props.data)}>
        {/* <Left style={s[item.activityColor]} /> */}
        <Left style={s.statusBar} />
        <Body style={s.balls}>
          {item.showBlueCircle && <Text style={s.blue}>●</Text>}
          {item.showRedCircle  && <Text style={s.red}>●</Text>}
          {item.isFavorite     && <Icon type='Ionicons' name='ios-star' style={s.star}/>}</Body>
        <Body style={s.mainBody}>
          <Text style={s.title}>{item.title}</Text>
          <Text style={s.desc} numberOfLines={1}>{item.desc}</Text>
          <Text style={s[item.statusColor]} >{item.sub_title} </Text></Body>
        <Right>
          <Text style={s.info} numberOfLines={1}>{item.time}</Text>
          <Text style={s.price} numberOfLines={1}>{item.price}</Text>
          <Text style={s.infoOwner}>{item.seller}</Text></Right>
      </ListItem>
    )
  }
}
