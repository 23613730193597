import { Button, Icon } from "native-base";
import React from "react";
import { StyleSheet } from "react-native";
import { header } from "../theme/pieces/shared";
import conn from "../utils/conn";
import { LeadSession } from "../utils/lead-session-ct";
import { lastIf, loge, simpleCalert } from "../utils/utils";
const s = StyleSheet.create(header)

export interface LeadStarProps {
  leadSession: LeadSession|null
}

interface State {}

export class LeadStar extends React.Component<LeadStarProps, State> {
  get lead(): any {
    if (!this.props.leadSession) return {}
    return this.props.leadSession.lead
  }

  constructor(props) {
    super(props)
    this.state = {}
  }

  star = () => {
    this.toogleFav()
    conn.fetch('contacts/favorite', 'POST', {
      is_favorite: this.lead.is_favorite,
      lead_id: this.lead.id,
    }).then(resp => this.onStarSuccess(resp))
    .catch(err => this.onStarError(err))
  }

  onStarSuccess = (resp: any) => {
    if (!resp.success) {
      this.toogleFav()
      simpleCalert(resp.message || 'Erro ao salvar.')
      return
    }

    const { lead } = this.props.leadSession || {lead: null}
    lead.dirty = true
  }

  onStarError = (error: any) => {
    this.toogleFav()
    simpleCalert('Erro ao salvar favorito')
    loge(error, "onStarError")
  }

  toogleFav= () => {
    const l = this.lead
    l.is_favorite = 
      l.is_favorite == '0' 
      ? '1' 
      : '0'
    this.setState({lead: l})
  }

  render() {
    return (
      <Button transparent testID="starBtn">
        <Icon type='Ionicons' onPress={this.star}
              name={this.lead.is_favorite == '1' ? 'ios-star' : 'ios-star-outline'} 
              style={lastIf([s.h_btn, s.h_btn_gold], this.lead.is_favorite == '1')} />
      </Button>
    )
  }
}
