import React from 'react'
import { Toast } from "native-base"
import { toast } from "../../plataform/react-toastify"
import { ci, isWeb } from "../utils/utils"
import { TextIcons } from "./text-with-icons"
import deepmerge from "deepmerge"

export class ButterToast {
  render(msg, opt) {
    if (isWeb) {
      opt.text = {}
      opt.icon_left = {}

      const merge = webDefaults[opt.type]
      if (merge)
        opt = deepmerge(merge, opt)

      opt.text.content = msg
      
      toast(
        <TextIcons
          text={opt.text}
          iconLeft={opt.icon_left}
          iconRight={opt.icon_right} 
        />,
        opt.params
      )

    } else {
      Toast.show({
        text: msg,
        buttonText: opt.buttonText || '>>',
        buttonTextStyle: {fontSize: 10},
        duration: opt.duration || 6000,
        type: opt.type, // danger, success, warning,
        position: opt.position,
        buttonStyle: {alignSelf: 'center'}
      })
    }
  }
}

const webDefaults = {
  success: {
    text: {
      content: "Sucesso!",
      style: {
        color: "white",
      }
    },
    icon_left: {
      name: "check",
      type: "Entypo",
      style: {
        color: "white",
        paddingRight: 10,
        alignSelf: 'center',
        fontSize: 22
      }
    },
    params: {
      style: {
        backgroundColor: '#52AA5E',
        borderRadius: 10,
      }
    }
  },
  warning: {
    text: {
      content: "Atenção!",
      style: {
        color: "black",
      }
    },
    icon_left: {
      name: "warning-outline",
      type: "Ionicons",
      style: {
        color: "black",
        paddingRight: 10,
        alignSelf: 'center',
        fontSize: 22
      }
    },
    params: {
      style: {
        backgroundColor: '#EBC55C',
        borderRadius: 10,
      }
    }
  },
  danger: {
    text: {
      content: "Erro!",
      style: {
        color: "white",
      }
    },
    icon_left: {
      name: "error-outline",
      type: "MaterialIcons",
      style: {
        color: "white",
        paddingRight: 10,
        alignSelf: 'center',
        fontSize: 22
      }
    },
    params: {
      style: {
        backgroundColor: '#DE5F54',
        borderRadius: 10,
      }
    }
  }
}

// Alternative:

// import Toast from 'react-native-toast-message'

// text1: msg,
// // buttonText: isIos? opt.buttonText || '' : '',
// // buttonText: opt.buttonText || 'OK', // TODO: decide if keep OK before release
// visibilityTime: opt.duration || 6000,
// type: opt.type ? {danger: 'error', success: 'success', warning: 'error'}[opt.type] : 'info', // danger, success, warning,
// position: (opt.position && opt.position != 'center') ? opt.position : 'bottom',
