import { StyleSheet } from "react-native";
import { isWeb, ifWeb, ifMob } from "../../utils/utils";

const viewContainer:any = {
  flex: isWeb ? null : 1,
  flexDirection: 'column',
  justifyContent: 'center',
  width: '100%',
  paddingTop: isWeb ? 10 : 15,
  paddingBottom: isWeb ? 30 : 15,
  paddingHorizontal: 16,
  backgroundColor: '#c8eeff',
  borderColor: '#0fb2ff',
  marginBottom: ifMob(10),
  ...ifWeb({
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
  }, {
    borderWidth: StyleSheet.hairlineWidth,
  })
}

const leadActivityWarning = StyleSheet.create({
  // container
  viewContainer: {
    ...viewContainer,
  },
  viewContainerLate: {
    ...viewContainer,
    borderColor: '#ff9a90',
    backgroundColor: '#ffdada',
  },

  // rows
  header: { flex: 1, flexDirection: 'row', justifyContent: 'center', marginBottom: 7 },
  activityDesc: { flex: 1, flexDirection: 'row', justifyContent: 'center' },
  buttons: { flex: 1, flexDirection: 'row', justifyContent: 'center', marginTop: 12 },

  // activityDesc
  textName: {
    fontSize: 14,
    color: 'darkslategrey',
  },
  textDate: {
    marginLeft: 10,
    fontSize: 16,
    marginTop: -2,
    fontWeight: '400',
    color: '#555'
  },


  // buttons
  btnDone: {
    elevation: 0,
    height: 32,
    borderColor: 'green',
    // borderWidth: 2,
    backgroundColor: 'transparent',
    ...ifWeb({
      borderTopWidth: 1,
      borderBottomWidth: 1,
      borderLeftWidth: 1,
      borderRightWidth: 1,
    }, {
      borderWidth: StyleSheet.hairlineWidth,
    })
  },
  btnDoneTxt: {
    fontSize: 12,
    color: 'green'
  },

  btnCancel: {
    elevation: 0,
    marginLeft: 30,
    height: 32,
    borderColor: '#333',
    // borderWidth: 2,
    backgroundColor: 'transparent',
    ...ifWeb({
      borderTopWidth: 1,
      borderBottomWidth: 1,
      borderLeftWidth: 1,
      borderRightWidth: 1,
    }, {
      borderWidth: StyleSheet.hairlineWidth,
    })
  },
  btnCancelTxt: {
    fontSize: 12,
    color: '#333',
  },

  //noteBox
  txtNote: {
    alignSelf: 'center',
    fontSize: 13,
    fontFamily: "Roboto",
    color: "#6A8287",
  },
  helpIcon: {
    marginLeft: 15, 
    fontSize: 18,
    color: '#F6B12D'
  },
  txtInputNotes: {
    borderRadius: 10, 
    fontSize: 13, 
    padding: 10,
  },
  help: {
    backgroundColor: '#ffedca', 
    borderColor: '#F6B12D', 
    borderWidth: StyleSheet.hairlineWidth, 
    borderRadius: 5, 
    padding: 10, 
    fontSize: 13, 
    color: '#888', 
    textAlign: 'center'
  },
  helpCt: {
    borderColor: '#ccc', 
    width: "90%",
    alignSelf: 'center',
    marginBottom: 10,
  },
});

export default leadActivityWarning;