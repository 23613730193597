import Sentry from '../../../plataform/sentry';
import { Button, Container, Content, Spinner, Text, View, Icon } from 'native-base';
import React from "react";
import { GlobalHeader } from '../../pieces/global-header';
import { s } from "../../theme/screens/obs";
import conn from '../../utils/conn';
import { LeadSession, leadSessionCt } from '../../utils/lead-session-ct';
import { loge, simpleCalert, isWeb, isIos, blank, logMsg, sflat, logScreen, ci } from '../../utils/utils';
import { TextInput, Linking } from 'react-native';
import Select from 'react-select';
import getWhatsappMessageURL from '../../utils/get-whatsapp-url';
import { checkOrAskContactPermission, saveContactToDevice } from '../../../plataform/device-contacts';
import { rmsg } from '../../utils/utils-view';

interface Props {navigation: any}

interface ITextInputIOSProps {
  selection?: any,
  onSelectionChange?: any
}

interface State {
  loading: boolean
  loadingList: boolean
  tplMsgBody: string
  editedBody: string
  customerZap: any
  customerContact: any
  selection: any
  replyOpts: any[]
  isSelectionChanged: boolean
  checkingPerm: any
}

export class MsgWriteScreen extends React.Component<Props, State> {
  leadSession: LeadSession|null = null
  firstSelectionChange = true
  tplIsSet = false

  public readonly state: State = {
    loading: false,
    loadingList: false,
    tplMsgBody: '',
    editedBody: '',
    customerZap: {},
    customerContact: {},
    selection: null,
    replyOpts: [],
    isSelectionChanged: false,
    checkingPerm: {},
  }

  navParams(): any{
    return this.props.navigation.state.params
  }

  componentDidMount() {
    Sentry.addBreadcrumb({message: `mounted MsgWriteScreen`})
    const { leadSessionId, msgTplId, msgTplOpts } = this.navParams()
    this.leadSession = leadSessionCt.get(leadSessionId)

    logScreen('msgWrite')

    if (isWeb)
      this.setState({replyOpts: msgTplOpts})
    else
      this.load(msgTplId);
  }

  // Load tpl

  load = (msgTplId: number) => {
    if (!this.leadSession) return
    this.setState({loadingList: true})
    conn.fetch('message_templates/build?msg_tpl_id=' + msgTplId + '&lead_id=' + this.leadSession.lead.id, 'GET')
      .then(this.onloadSuccess)
      .catch(this.onloadError)
  }

  onloadSuccess = (resp: any) => {
    const newState = {
      loadingList: false,
      tplMsgBody: resp.body,
      editedBody: resp.body,
      isSelectionChanged: false,
      customerZap: resp.customer_zap,
      customerContact: resp.customer_contact,
      selection: {start: resp.sel_pos, end: resp.sel_pos}
    }
    // if (Platform.OS != 'android') {
      // newState['selection'] = {start: resp.sel_pos, end: resp.sel_pos};
    // }
    this.setState(newState)
  }

  onloadError = (error: any) => {
    this.setState({loadingList: false})
    simpleCalert('Erro ao carregar mensagens')
    loge(error, "lead view onloadError")
  }

  // Send msg
  // save = () => {
  //   this.checkPerm('send_msg', "Erro ao enviar mensagem.", this.doSave)
  // }

  checkingPerm = (action: string, checking: boolean = true) => {
    this.setState(oldState => {
      const ns = {...oldState}
      ns.checkingPerm[action] = checking
      return ns
    })
  }

  checkPerm = (action, defaultErr, spin = true, callback) => {
    if (!this.leadSession) return
    if (spin) this.checkingPerm(action)
    const self = this

    conn.fetch('check_perm', 'POST', {
      lead_id: this.leadSession.lead.id, 
      action_type: action,
    
    }).then(resp => {
      if (spin) self.checkingPerm(action, false)
      if (resp.success)
        callback()
      else
        simpleCalert(resp.message || defaultErr)
    
    }).catch(e => {
      if (spin) self.checkingPerm(action, false)
      simpleCalert(defaultErr)
      loge(e)
    })
  }

  save = () => {
    if (!this.leadSession) return
    
    if (blank(this.state.editedBody)) {
      rmsg("A mensagem não foi enviada. Escreva uma mensagem ou seleciona uma das respostas antes de enviar.", {type: 'e'})
      return
    }
    
    this.setState({loading: true})

    conn.fetch('messages/build', 'POST', {
      lead_id: this.leadSession.lead.id,
      body: this.state.editedBody,
    }).then(this.saveSuccess)
    .catch(this.saveError)
  }

  saveSuccess = resp => {
    if (!resp.success) {
      simpleCalert(resp.message || "Erro ao enviar mensagem")
    } else {
      if (!this.leadSession) return
      this.leadSession.lead = resp.lead
      this.leadSession.lead.dirty = true
      this.leadSession.reloadMsgs = true  
    }
    this.setState({loading: false}, this.goBack)
  }

  saveError = e => {
    loge(e)
    this.setState({loading: false})
    simpleCalert('Erro ao enviar mensagem')
  }

  goBack = () => this.props.navigation.goBack();

  // onZapTap

  onZapTap = (spin = true) => {
    if (isWeb && !this.tplIsSet) {
      simpleCalert("Selecione uma das respostas antes de enviar.")
      return
    }

    if (blank(this.state.editedBody)) {
      simpleCalert("A mensagem está em branco. Escreva uma mensagem ou selecione uma das respostas antes de enviar.")
      return
    }

    if (blank(this.state.customerZap)) {
      simpleCalert("Este lead não tem um número de telefone válido. Para respondê-lo clique no botão Enviar ao lado.")
      return
    }
    
    this.checkPerm('send_zap', "Erro ao enviar mensagem.", spin, () => this.sendThroughZap(this.state.editedBody))
  }

  sendThroughZap = async (message: string) => {
    const url = getWhatsappMessageURL(message, this.state.customerZap)

    Linking.canOpenURL(url).then(async y => {
      if (!y) {
        logMsg("cannot open url: " + url)
        return simpleCalert("Não é possível abrir o WhatsApp. Verifique se o número é válido.")
      }

      if (!isWeb)
        if (await checkOrAskContactPermission())
          await saveContactToDevice(this.state.customerContact).catch(e => loge(e))

      this.saveZapLeadMsg(message)

      if (isWeb) {
        window.open(url, '_blank', 'noopener')
      } else {
        Linking.canOpenURL(url).then(ok => {
          if (ok) {
            Linking.openURL(url)
          } else {
            rmsg("Erro ao abrir WhatsApp. Verifique se o número é válido.", {type: 'e'})
          }
        })
      }
    })
  }

  saveZapLeadMsg = (message) => {
    if (!this.leadSession) return
    const { lead } = this.leadSession

    conn.fetch('contact_actions/action', 'POST', {
      contacted_by: 'whatsapp',
      lead_id: lead.id,
      answer: message

    }).then(resp => {
      if (!resp.success) {
        simpleCalert(resp.message)
      } else {
        if (!this.leadSession) return
        this.leadSession.lead = resp.lead
        this.leadSession.lead.dirty = true
        this.leadSession.reloadMsgs = true 
      }

    }).catch(error => {
      loge(error)
      simpleCalert("Erro ao registrar sua mensagem no Leadfy.")
    })
  }

  right = () => {
    let opts: any[] = []
    const arc = conn.user.allow_reply_channels || 3

    if (arc == 2 || arc == 3)
      opts.push(
        <Button transparent onPress={() => this.onZapTap(false)} testID='whatsAppSendBtn'>
          <Icon
            style={s.rightHeaderIcons}
            name="logo-whatsapp"
            type="Ionicons"
          />
        </Button>
      )
    
    if (arc == 1 || arc == 3)
      opts.push(
        <Button transparent onPress={this.save} testID='emailSendBtn'>
          <Icon
            style={s.rightHeaderIcons}
            name="send"
            type="MaterialIcons"
          />
        </Button>
      )

    return opts
  }

  onReplyTypeSelected = (selectedOption) => {
    this.tplIsSet = true
    this.load(selectedOption.value)
  }

  onChangeText = editedBody => {
    this.setState({
      ...this.state,
      isSelectionChanged: true,
      editedBody,
    });
  }

  onSelectionChange = ({}) => {
    if (this.firstSelectionChange) {
      this.firstSelectionChange = false;
      return;
    }
    this.setState({isSelectionChanged: true});
  }

  render () {
    const arc = conn.user.allow_reply_channels || 3
    const lead = this.leadSession ? this.leadSession.lead : null

    if (!lead || !lead.customer || !lead.product)
      return <Container><Button onPress={this.goBack} style={{alignSelf: "center", marginTop: 100}}>
               <Text>back</Text>
             </Button></Container>

    const textInputIOSProps:ITextInputIOSProps = {};
    
    if(isIos) {
      textInputIOSProps.selection = !this.state.isSelectionChanged ? this.state.selection : null;
      textInputIOSProps.onSelectionChange = this.onSelectionChange;
    }

    return (
      <Container>
        <GlobalHeader
          title={'Responder'}
          navigation={this.props.navigation}
          right={this.right()}
        />

        <Content style={s.content}>
          {this.state.loading &&
          <View style={s.overlay}><Spinner style={s.overlaySpinner} size='small' color='#6A8287' /></View>}

          {/* reply type */}
          {isWeb &&
          <View style={ sflat([s.cellAct, {zIndex: 2}])}>
            <View style={s.cellLineFirstActivity}>
              <Text style={s.cellTitAction}>Resposta:</Text>
              <View style={s.webSelectCt}>
                <Select
                  // autoFocus={true}
                  // defaultMenuIsOpen={true}
                  placeholder="selecione..."
                  // menuPortalTarget={document.querySelector('body')}
                  onChange={this.onReplyTypeSelected}
                  options={this.state.replyOpts.map(i =>
                    ({value: i.id, label: i.text})
                  )}
                  />
              </View>
            </View>
          </View>}

          {/* anúncio */}
          {lead &&
          <View style={s.cell}>
              <View style={s.cellLineFirst}>
                <Text style={s.cellTitObs}>Para:</Text>
                <Text style={s.cellSubContact} testID={lead.customer.name}>{lead.customer.name}</Text></View></View>}

          {/* body */}
          {!this.state.loadingList &&

          <TextInput
            {...textInputIOSProps}
            autoFocus
            value={this.state.editedBody}
            onChangeText={this.onChangeText}
            style={s.textArea}
            multiline={true}
            autoCorrect={true}
            numberOfLines={10}
            autoCapitalize='sentences'
            placeholder="Clique para editar..."
            placeholderTextColor='#bbb' />
            || 
            <View style={{height: 400}}>
              <Spinner size="small" color="#6A8287"></Spinner>
            </View>
          }
          {/* <View style={s.bodySpinnerCt}>
            {!this.state.loadingList &&
              <Form>
                <Textarea rowSpan={25} selectTextOnFocus={false} placeholder="toque para editar..."
                  style={s.obsBody} placeholderTextColor={"#ddd"} editable={true} autoFocus={true}
                  onSelectionChange={this.onSelectionChange}
                  selection={this.state.selection}
                  defaultValue={this.state.tplMsgBody}
                  onChangeText={v => this.state.editedBody = v}
                  /></Form>
              || <Spinner size="small" color="#6A8287"></Spinner>}</View> */}
          {
            isWeb &&
            <View
              style={s.submitControls}>
              
              {/* <WhatsappLink
                message={this.state.editedBody}
                phone={this.leadSession ? this.leadSession.lead.customer.phone : null}
                preCallSync={()=>{this.onZapTap()}}
              >
                <Button
                  iconLeft
                  disabled
                  style={s.sendByWhatsappButton}>
                    <Icon
                      name="logo-whatsapp"
                      type="Ionicons"
                    />
                    <Text style={s.sendButtonText}>Enviar por Whatsapp</Text>
                </Button>
              </WhatsappLink> */}

              {(arc == 2 || arc == 3) &&
              <Button
                iconLeft
                onPress={() => this.onZapTap()}
                style={s.sendByWhatsappButton}>
                <Icon
                  name="logo-whatsapp"
                  type="Ionicons"
                />
                <Text style={s.sendButtonText}>Enviar por Whatsapp</Text>
                {this.state.checkingPerm['send_zap'] && <Spinner color='white' size="small" style={{marginRight: 10}} />}
              </Button>}

              {(arc == 1 || arc == 3) &&
              <Button
                iconLeft
                onPress={this.save}
                style={s.saveButton}>
                  <Icon
                    name="send"
                    type="MaterialIcons"
                  />
                  <Text style={s.sendButtonText}>Enviar</Text>
              </Button>}
            </View>
          }
        </Content>
        {/* {
          !isWeb && this.leadSession &&
          <KeyboardAvoidingView behavior="padding" enabled={isIos}>
            <MsgWriteActionsTab
              whatsappPreCallSync={()=>{this.onZapTap()}}
              save={this.save}
              message={this.state.editedBody}
              phone={this.leadSession.lead.customer.phone}
            />
          </KeyboardAvoidingView>
        } */}
      </Container>
    )
  }
}