
export default {
    btnBack: require('./btnBack.png'),
    loginBg: require('./loginBg.png'),
    logo:    require('./logo.png'),
    forward: require('./forward.png'),
    whiteBall: require('./white-ball.png'),
    forwardDark: require('./forwardDark.png'),
    party: require('./party.png'),
    appStoreBadge: require('./app-store.png'),
    googlePlayBadge: require('./google-play-badge.png'),
    logoLeadfyImobBlue: require('./logo-leadfy-imob-blue.png'),
}
