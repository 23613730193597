import { Button, Container, Content, Icon, Text, View } from 'native-base';
import React from "react";
import { Linking, TouchableOpacity, ActivityIndicator, StyleSheet, Dimensions } from "react-native";
import { GlobalHeader } from '../pieces/global-header';
import { s } from "../theme/screens/leadView";
import { addKey, loge, simpleCalert, blank, ci, isWeb, parseSpec, sflat, logScreen } from '../utils/utils';
import Sentry from '../../plataform/sentry';
import conn from '../utils/conn';
import { Config } from '../config';
import { menuHandler } from '../utils/menu-handler';

var { width, height } = Dimensions.get('window');

interface Props {navigation: any}

interface State {
  firstLoading: boolean,
  tableData: any[],
}

export class TableScreen extends React.Component<Props, State> {
  spec: any = {}

  constructor(props) {
    super(props);
    this.state = {
      firstLoading: true,
      tableData: [],
    };
  }

  navParams(): any{
    return this.props.navigation.state.params
  }

  componentDidMount(){
    Sentry.addBreadcrumb({message: `mounted TableScreen`})
    const { spec } = this.navParams()
    this.spec = parseSpec(spec)
    logScreen('table', spec)
    this.load()
  }

  load = () => {
    this.setState({firstLoading: true})

    conn.fetch(this.spec.load_path, 'GET')
      .then(resp => {
        this.setState({firstLoading: false})
        if (resp.success) {
          this.setState({tableData: resp.list})
        } else
          simpleCalert("Erro ao obter dados.")
      
      }).catch((e) => {
        loge(e)
        this.setState({firstLoading: false})
        simpleCalert("Erro ao obter dados.")
      })
  }

  goBack = () => this.props.navigation.goBack()

  openUrl(url: string) {
    if (!blank(url)) {
      if (isWeb)
        window.open(url, '_blank')
      else
        Linking.openURL(url);
    }
  }

  appVersion = () => Config.appVersion

  appDateVersion = () => Config.appVersionDate

  windowDimensions = () => {
    const w = Math.round(width  * 100 + Number.EPSILON) / 100
    const h = Math.round(height * 100 + Number.EPSILON) / 100
    return `${w}x${h}`
  }

  itemValue = (item) => {
    switch (item.type) {
      case 'text':
        return <Text style={s.cellRight} selectable>{item.text}</Text>
        break;
      case 'function':
        return this[item.function].call()
        break;
      case 'link':
        return (
          <TouchableOpacity style={s.cellRight} onPress={() => this.openUrl(item.link)}>
            <Text style={s2.cellRightLink} selectable>{item.link_text}</Text>
          </TouchableOpacity>
        )
        break;
      default: 
        return null
    }  
  }

  leftBtns = () => {
    if (this.spec.back_btn) {
      return null
    } else {
      return isWeb
        ? []
        : [<Button transparent style={s.h_left} onPress={menuHandler.open} testID='menuBtn'>
            <Icon style={s.h_btn_hamb} name='menu' /></Button>]
    }
  }

  render () {
    const {tableData} = this.state
    return (
      <Container>
        <GlobalHeader 
          title={this.spec ? this.spec.title : '...'}
          navigation={this.props.navigation} 
          left={this.leftBtns()}
          />

        <Content scrollEnabled={true} style={s.content}>
          {!this.state.firstLoading &&
          <>
            {/* top text */}
            {!blank(this.spec.top_text) &&
              <View style={s2.topTextCt}>
                <Text style={s2.topText}>{this.spec.top_text}</Text>
              </View>
            }
            
            <View style={sflat([s.tblCt, {marginBottom: 50}])} testID="table">
              {tableData.map(addKey).map((item:any) =>
                <View key={item.key} style={s.tblLine}>
                  <View style={{backgroundColor: '#E6E9EE', height: '100%', width: '45%', justifyContent: 'center'}}>
                    <Text selectable={true} style={s.cellLeft}>{item.name}</Text>
                  </View>
                 {this.itemValue(item)}
                </View>
              )}
            </View>
          </> ||
          <>
            <View style={s.graphLoadingCt}>
              <ActivityIndicator />
              <Text style={s.graphLoading}>Carregando...</Text></View>
          </>}

        </Content>
      </Container>
    )
  }
}

const s2 = StyleSheet.create({
  cellRightLink: {backgroundColor: '#F2F4F7', color: "#6A8287", fontFamily: 'Roboto', fontSize: 13},
  topTextCt: {paddingHorizontal: 20, paddingTop: 30, paddingBottom: 15, backgroundColor: "#F2F4F7", alignSelf: 'center'},
  topText: {textAlign: 'center', fontSize: 15, color: '#999', lineHeight: 22},
})