import { View, Text, Switch } from "native-base";
import React from "react";
import { s } from "../../../theme/screens/leadNew";
import { StyleSheet } from "react-native";
import { FieldProps } from "./_shared_props";
import { sflat } from "../../../utils/utils";
import { RFUtil } from "../rf-util";

interface State {
  value: boolean
}

export class Fswitch extends React.Component<FieldProps, State> {
  constructor(props){
    super(props)
    this.state = {
      value: props.iniVal
    }
  }

  onChange = (v) => {
    this.setState(prevState => {
      const newState = {...prevState}
      newState.value = v
      return newState
    }, () => {
      this.props.onChange(v)
    })
  }

  render() {
    const { value } = this.state
    const { field } = this.props
    const help = RFUtil.help(field)
    return (
      <>
        <View style={sflat([s2.ct_style, field.ct_style])}>
          <Text style={{flex: 1, width: 150, color: '#888'}}>{field.label}</Text>
          <Switch 
            ref={ref => this.props.setRef && this.props.setRef(ref)}
            style={{marginRight: 30, alignSelf: 'center'}}
            value={value}
            testID={field.label}
            onValueChange={this.onChange} 
          />
        </View>

        {help && 
          <View style={s.helpCt}>
            <Text style={s.help}>{help}</Text></View>
        ||
          field.disable_underline ?
          null
          :
          <View style={{flex: 0, backgroundColor: '#ccc', 
            height: StyleSheet.hairlineWidth, width: '100%'}}></View>
        }
      </>
    )
  }
}

const s2 = StyleSheet.create({
  ct_style: {flexDirection: 'row', marginTop: 25, marginLeft: 0, marginBottom: 20}  
})
