import { Icon, List, Text, View, Button } from "native-base";
import React from "react";
import { ActivityIndicator, StyleSheet, RefreshControl } from "react-native";
import { s } from "../theme/screens/leadList";
import { DatasourceList } from "../utils/datasource-list";
import { ListFilter } from "./list-filter";
import { addKey, ci, ifWeb } from "../utils/utils";

export interface FullListProps {
  dataSource: DatasourceList
  cell: any
  initialActiveFilter: string
  onLoadSuccess?: Function
}

interface State {
  list: any[],
  page: number,
  loading: boolean,
  loadingMore: boolean,
  refreshing: boolean,
  errorMsg: string|null,
  filterButtons: any[],
}

export class FullList extends React.Component<FullListProps, State> {
  reloadSingleLeadId: number|null = null

  public readonly state: State = {
    list: [],
    page: 1,
    loading: true,
    loadingMore: false,
    refreshing: false,
    errorMsg: null,
    filterButtons: [],
  }

  constructor(props){
    super(props)
    this.props.dataSource.setOnEvents({
      onLoadError: this.onLoadError,
      onLoadSuccess: this.onLoadSuccess,
      onFilter: this.onFilter,
      onRefresh: this.onRefresh,
    })
  }

  componentWillUnmount = () => {
    this.props.dataSource.unsetOnEvents()
  }

  componentDidMount = () => {
    this.props.dataSource.load()
  }
    
  onLoadError = (errorMsg) => {
    this.setState({
      errorMsg: errorMsg,
      loading: false
    })    
  }

  onLoadSuccess = (list: any[], filterButtons: any[]) => {
    this.setState({
      filterButtons: filterButtons,
      list: list,
      loading: false,
      loadingMore: false,
      refreshing: false,
    })
    if (this.props.onLoadSuccess)
      this.props.onLoadSuccess()
  }

  filter = (atts) => {
    this.props.dataSource.filter(atts)
  }

  onFilter = () => {
    this.setState({
      loading: true, 
      page: 1,
    })
  }

  handleRefresh = () => {
    this.props.dataSource.refresh()
  }

  onRefresh = () => {
    this.setState({
      page: 1,
      refreshing: true
    })
  }

  loadMore = () => {
    if (!this.props.dataSource.hasMore())
      return
    
    this.setState((prevState) => {
      return {
        page: prevState.page + 1,
        loadingMore: true
      }
    })

    this.props.dataSource.loadMore()
  }

  closeSearch = () => {
    this.props.dataSource.reset()
  }

  searchResultLabel = () => {
    const total = this.props.dataSource.total
    return `${total} resultado${total > 1 ? 's' : ''}`
  }

  render() {
    return (
      <View style={{flex: 1}}>

        {/* filters */}
        {!this.props.dataSource.filters.fast_search &&
        <ListFilter filterButtons={this.state.filterButtons} 
                    filter={this.filter} 
                    initialActiveFilter={this.props.initialActiveFilter} />}

        {this.props.dataSource.filters.fast_search &&
        <View style={{backgroundColor: '#fff5e6', height: 50, width: '100%',}}>
          <View style={{flex: 0, flexDirection: 'row', paddingTop: 11, paddingHorizontal: 25,}}>
            <Text style={{flex: 1, color: '#888', marginTop: 7, fontSize: 15}}>{this.searchResultLabel()}</Text>
            <Button style={{flex: 0, backgroundColor: '#fbe5c5', borderWidth: StyleSheet.hairlineWidth, borderColor: '#888', height: 30,}} onPress={this.closeSearch}>
              <Text style={{color: '#888', fontSize: ifWeb(14, 12)}}>Fechar busca</Text>
            </Button></View></View>}

        {/* list */}
        {!this.state.loading && this.state.list.length > 0 &&
          <View style={{flex: 1}}>
            <List style={s.list} dataArray={this.state.list.map(addKey)} testID='leadList' 
              // keyExtractor={item => item.id.toString()}
              // onRefresh={this.handleRefresh}
              // refreshing={this.state.refreshing}
              refreshControl={
                <RefreshControl refreshing={this.state.refreshing} onRefresh={this.handleRefresh.bind(this)}/>
              }
              onEndReached={this.loadMore}
              onEndReachedThreshold={0.2}
              initialListSize={ifWeb(20, 10)}
              renderRow={this.props.cell}
              renderFooter= {() => this.state.loadingMore &&
                <View style={s.footer}>
                  <ActivityIndicator/></View> ||
                <View style={{height: 50}}/>}
            /></View>}

        {/* empty */}
        {!this.state.loading && this.state.list.length == 0 &&
        <View style={s.noMessageCt}>
          <Icon name="message-bulleted-off" type="MaterialCommunityIcons" style={s.noMessageIco} />
          <Text style={s.noMessage}>Não há leads para o filtro escolhido</Text>
        </View>}

        {/* spinner */}
        {this.state.loading &&
        <View style={s.loadingCt}>
          <ActivityIndicator />
          <Text style={s.loading}>Carregando...</Text></View>}

      </View>
    )
  }
}
