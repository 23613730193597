import { isDev } from "../config"
import { prependMenuItems } from "../dev-helpers/prepend-menu"
import { menuNavigate } from "../navigation/menu-navigate"
import conn from "./conn"
import { blank, ci, loge } from "./utils"
import { rmsg } from "./utils-view"

class MenuHandler {
  menuOpen: any
  nav: any
  updateOptions: any

  open = () => {
    this.menuOpen(true)
    this.update()
  }
  close = () => this.menuOpen(false)

  update = () => {
    conn.fetch('users/menu?version=2', 'GET')
      .then(resp => {
        if (!resp || blank(resp.items)) {
          rmsg((resp && resp.msg) || "Erro ao carregar menu.")
          return
        }
        // TODO remove mocked menu items before release
        // let items = [
        //   {
        //     "id": 1,
        //     "seq": 1,
        //     "text": "Leads",
        //     "screen_title": null,
        //     "route": "leadListScreen",
        //     "icon_name": "message-text-outline",
        //     "icon_type": "MaterialCommunityIcons",
        //     "bottom_space": false,
        //     "admin_only": false,
        //     "mobile_off": false,
        //     "load_path": null,
        //     "page_spec_class": null,
        //     "created_at": "2020-08-23T22:33:40.000-03:00",
        //     "updated_at": "2020-08-23T22:33:40.000-03:00",
        //     "is_default": true,
        //     "company_id": null,
        //     "seller_id": null,
        //     "min_version": 0,
        //     "max_version": 999999999,
        //     "enabled": true,
        //     "spec": null,
        //     "testID": "lead"
        //   },
        //   {
        //     "id": 99,
        //     "seq": 50,
        //     "text": "ChatBot",
        //     "screen_title": null,
        //     "route": "listScreen",
        //     "icon_name": "group-add",
        //     "icon_type": "MaterialIcons",
        //     "bottom_space": false,
        //     "admin_only": true,
        //     "mobile_off": false,
        //     "load_path": null,
        //     "page_spec_class": null,
        //     "created_at": "2020-08-23T22:33:40.000-03:00",
        //     "updated_at": "2020-08-23T22:33:40.000-03:00",
        //     "is_default": true,
        //     "company_id": null,
        //     "seller_id": null,
        //     "min_version": 4040000,
        //     "max_version": 999999999,
        //     "enabled": true,
        //     "spec": "{\"submit_path\":null,\"load_path\":\"chatbot/list\",\"title\":\"ChatBot\",\"show_search\":true,\"right_btns\":[{\"route\":\"formScreen\",\"action\":\"goToEdit\",\"spec\":\"chatbot/spec\",\"icon\":{\"style\":{\"color\":\"white\",\"fontSize\":18},\"type\":\"Fontisto\",\"name\":\"plus-a\"}}],\"cell_tap_btn\":{\"action\":\"goToEditItem\",\"route\":\"formScreen\",\"spec\":\"chatbot/spec\"}}",
        //     "testID": "chatbot"
        //   },
        //   {
        //     "id": 27,
        //     "seq": 10,
        //     "text": "Criar lead",
        //     "screen_title": null,
        //     "route": "formScreen",
        //     "icon_name": "address-card-o",
        //     "icon_type": "FontAwesome",
        //     "bottom_space": false,
        //     "admin_only": false,
        //     "mobile_off": false,
        //     "load_path": null,
        //     "page_spec_class": null,
        //     "created_at": "2021-08-14T21:42:03.000-03:00",
        //     "updated_at": "2021-08-14T21:42:03.000-03:00",
        //     "is_default": true,
        //     "company_id": null,
        //     "seller_id": null,
        //     "min_version": 4040000,
        //     "max_version": 999999999,
        //     "enabled": true,
        //     "spec": "contacts/spec",
        //     "testID": "create_lead"
        //   },
        //   {
        //     "id": 15,
        //     "seq": 21,
        //     "text": "Checkin",
        //     "screen_title": null,
        //     "route": "formScreen",
        //     "icon_name": "check-square-o",
        //     "icon_type": "FontAwesome",
        //     "bottom_space": false,
        //     "admin_only": false,
        //     "mobile_off": false,
        //     "load_path": null,
        //     "page_spec_class": null,
        //     "created_at": "2020-08-23T22:33:40.000-03:00",
        //     "updated_at": "2020-08-23T22:33:40.000-03:00",
        //     "is_default": true,
        //     "company_id": null,
        //     "seller_id": null,
        //     "min_version": 4040000,
        //     "max_version": 999999999,
        //     "enabled": true,
        //     "spec": "users/verify_checkin",
        //     "testID": "checkin_menu"
        //   },
        //   {
        //     "id": 33,
        //     "seq": 30,
        //     "text": "Gráficos",
        //     "screen_title": null,
        //     "route": "webviewScreen",
        //     "icon_name": "graph",
        //     "icon_type": "SimpleLineIcons",
        //     "bottom_space": false,
        //     "admin_only": false,
        //     "mobile_off": false,
        //     "load_path": "",
        //     "page_spec_class": null,
        //     "created_at": "2020-08-23T22:33:40.000-03:00",
        //     "updated_at": "2020-08-23T22:33:40.000-03:00",
        //     "is_default": true,
        //     "company_id": null,
        //     "seller_id": null,
        //     "min_version": 4040000,
        //     "max_version": 999999999,
        //     "enabled": true,
        //     "spec": "{\"title\":\"Gráficos\",\"load_path\":\"https://report-app2.leadfy.com.br/graph\",\"inject_js\":\"function handleAClick(e){var l=this.href;return e.preventDefault(),ReactNativeWebView.postMessage(JSON.stringify({cmd:\\\"open_link\\\",link:l})),!1}var allAs=document.querySelectorAll(\\\"a.open-external\\\");allAs.forEach(e=\\u003ee.addEventListener(\\\"click\\\",handleAClick));\"}"
        //   },
        //   {
        //     "id": 40,
        //     "seq": 30,
        //     "text": "Relatórios",
        //     "screen_title": null,
        //     "route": "webviewScreen",
        //     "icon_name": "table",
        //     "icon_type": "MaterialCommunityIcons",
        //     "bottom_space": false,
        //     "admin_only": false,
        //     "mobile_off": false,
        //     "load_path": null,
        //     "page_spec_class": null,
        //     "created_at": "2021-10-27T14:08:40.000-03:00",
        //     "updated_at": "2021-10-27T14:08:40.000-03:00",
        //     "is_default": true,
        //     "company_id": null,
        //     "seller_id": null,
        //     "min_version": 4040000,
        //     "max_version": 999999999,
        //     "enabled": true,
        //     "spec": "{\"title\":\"Relatórios\",\"load_path\":\"report_form\",\"inject_js\":\"function handleAClick(e){var l=this.href;return e.preventDefault(),ReactNativeWebView.postMessage(JSON.stringify({cmd:\\\"open_link\\\",link:l})),!1}var allAs=document.querySelectorAll(\\\"a.open-external\\\");allAs.forEach(e=\\u003ee.addEventListener(\\\"click\\\",handleAClick));\"}"
        //   },
        //   {
        //     "id": 41,
        //     "seq": 30,
        //     "text": "Exportar leads",
        //     "screen_title": null,
        //     "route": "webviewScreen",
        //     "icon_name": "export",
        //     "icon_type": "Fontisto",
        //     "bottom_space": false,
        //     "admin_only": false,
        //     "mobile_off": false,
        //     "load_path": null,
        //     "page_spec_class": null,
        //     "created_at": "2021-10-27T14:08:40.000-03:00",
        //     "updated_at": "2021-10-27T14:08:40.000-03:00",
        //     "is_default": true,
        //     "company_id": null,
        //     "seller_id": null,
        //     "min_version": 4040000,
        //     "max_version": 999999999,
        //     "enabled": true,
        //     "spec": "{\"title\":\"Exportar leads\",\"load_path\":\"lead_export_form\",\"inject_js\":\"function handleAClick(e){var l=this.href;return e.preventDefault(),ReactNativeWebView.postMessage(JSON.stringify({cmd:\\\"open_link\\\",link:l})),!1}var allAs=document.querySelectorAll(\\\"a.open-external\\\");allAs.forEach(e=\\u003ee.addEventListener(\\\"click\\\",handleAClick));\"}"
        //   },
        //   {
        //     "id": 26,
        //     "seq": 40,
        //     "text": "Usuários",
        //     "screen_title": null,
        //     "route": "listScreen",
        //     "icon_name": "user-follow",
        //     "icon_type": "SimpleLineIcons",
        //     "bottom_space": false,
        //     "admin_only": true,
        //     "mobile_off": false,
        //     "load_path": null,
        //     "page_spec_class": "Leadfy::AppLists::Users",
        //     "created_at": "2021-08-14T21:29:39.000-03:00",
        //     "updated_at": "2021-08-14T21:29:39.000-03:00",
        //     "is_default": true,
        //     "company_id": null,
        //     "seller_id": null,
        //     "min_version": 4040000,
        //     "max_version": 999999999,
        //     "enabled": true,
        //     "spec": "{\"submit_path\":null,\"load_path\":\"users/mlist\",\"title\":\"Usuários\",\"show_search\":true,\"right_btns\":[{\"route\":\"formScreen\",\"action\":\"goToEdit\",\"spec\":\"users/spec\",\"icon\":{\"style\":{},\"type\":\"Fontisto\",\"name\":\"plus-a\"}}],\"cell_tap_btn\":{\"action\":\"goToEditItem\",\"route\":\"formScreen\",\"spec\":\"users/spec\"}}",
        //     "testID": "user_list"
        //   },
        //   {
        //     "id": 28,
        //     "seq": 50,
        //     "text": "Grupos",
        //     "screen_title": null,
        //     "route": "listScreen",
        //     "icon_name": "group-add",
        //     "icon_type": "MaterialIcons",
        //     "bottom_space": false,
        //     "admin_only": true,
        //     "mobile_off": false,
        //     "load_path": null,
        //     "page_spec_class": "Leadfy::AppLists::Groups",
        //     "created_at": "2020-08-23T22:33:40.000-03:00",
        //     "updated_at": "2020-08-23T22:33:40.000-03:00",
        //     "is_default": true,
        //     "company_id": null,
        //     "seller_id": null,
        //     "min_version": 4040000,
        //     "max_version": 999999999,
        //     "enabled": true,
        //     "spec": "{\"submit_path\":null,\"load_path\":\"groups/mlist\",\"title\":\"Grupos\",\"show_search\":true,\"right_btns\":[{\"route\":\"formScreen\",\"action\":\"goToEdit\",\"spec\":\"groups/spec\",\"icon\":{\"style\":{\"color\":\"white\",\"fontSize\":18},\"type\":\"Fontisto\",\"name\":\"plus-a\"}}],\"cell_tap_btn\":{\"action\":\"goToEditItem\",\"route\":\"formScreen\",\"spec\":\"groups/spec\"}}",
        //     "testID": "groups"
        //   },
        //   {
        //     "id": 18,
        //     "seq": 72,
        //     "text": "Respostas",
        //     "screen_title": null,
        //     "route": "listScreen",
        //     "icon_name": "edit",
        //     "icon_type": "FontAwesome",
        //     "bottom_space": false,
        //     "admin_only": true,
        //     "mobile_off": false,
        //     "load_path": "",
        //     "page_spec_class": "Leadfy::AppLists::MsgTpls",
        //     "created_at": "2020-08-23T22:33:40.000-03:00",
        //     "updated_at": "2020-08-23T22:33:40.000-03:00",
        //     "is_default": true,
        //     "company_id": null,
        //     "seller_id": null,
        //     "min_version": 4030000,
        //     "max_version": 999999999,
        //     "enabled": true,
        //     "spec": "{\"submit_path\":null,\"load_path\":\"msg_tpls\",\"title\":\"Respostas\",\"top_text\":\"Estes são os modelos de respostas habilitados.\\n *Para escolher outros clique no ícone 'editar' acima.\\n *Para criar um novo clique no ícone 'adicionar' acima.\",\"item_btns\":[{\"text\":\"editar\",\"action\":\"goToEditItem\",\"route\":\"formScreen\",\"load_path\":\"msg_tpls/edit_spec\",\"spec\":\"msg_tpls/edit_spec\"}],\"right_btns\":[{\"route\":\"formScreen\",\"action\":\"goToEdit\",\"spec\":\"msg_tpls/edit_spec\",\"load_path\":\"msg_tpls/edit_spec\",\"icon\":{\"style\":{\"fontSize\":25},\"type\":\"MaterialIcons\",\"name\":\"playlist-add\"}},{\"action\":\"goToPickup\",\"page_spec\":{\"submit_path\":\"msg_tpls/save_list\",\"load_path\":\"msg_tpls?all=1\",\"title\":\"Selecionar\",\"top_text\":\"Selecione os modelos e clique em Salvar.\",\"back_btn\":true,\"right_btns\":[{\"action\":\"saveTapped\",\"icon\":{\"type\":\"FontAwesome\",\"name\":\"save\"}}]},\"spec\":{\"submit_path\":\"msg_tpls/save_list\",\"load_path\":\"msg_tpls?all=1\",\"title\":\"Selecionar\",\"top_text\":\"Selecione os modelos e clique em Salvar.\",\"back_btn\":true,\"right_btns\":[{\"action\":\"saveTapped\",\"icon\":{\"type\":\"FontAwesome\",\"name\":\"save\"}}]},\"icon\":{\"type\":\"FontAwesome\",\"name\":\"edit\"}}]}",
        //     "testID": "answer"
        //   },
        //   {
        //     "id": 17,
        //     "seq": 74,
        //     "text": "Fontes",
        //     "screen_title": null,
        //     "route": "listScreen",
        //     "icon_name": "web",
        //     "icon_type": "MaterialCommunityIcons",
        //     "bottom_space": false,
        //     "admin_only": true,
        //     "mobile_off": false,
        //     "load_path": "",
        //     "page_spec_class": "Leadfy::AppLists::LeadSources",
        //     "created_at": "2020-08-23T22:33:40.000-03:00",
        //     "updated_at": "2020-08-23T22:33:40.000-03:00",
        //     "is_default": true,
        //     "company_id": null,
        //     "seller_id": null,
        //     "min_version": 4030000,
        //     "max_version": 999999999,
        //     "enabled": true,
        //     "spec": "{\"submit_path\":null,\"load_path\":\"lead_sources\",\"spec\":\"lead_sources\",\"title\":\"Fontes\",\"top_text\":\"Estas são as fontes habilitadas para criação de leads manual e por importação de planilha. \\n\\n**Se pretende fazer integração com suas fontes de leads, dirija-se a tela Integrações no menu da versão Web ou entre em contato com nosso suporte.\",\"item_btns\":[{\"text\":\"editar\",\"action\":\"goToEditItem\",\"route\":\"formScreen\",\"load_path\":\"lead_sources/edit_spec\",\"spec\":\"lead_sources/edit_spec\"}],\"right_btns\":[{\"route\":\"formScreen\",\"action\":\"goToEdit\",\"spec\":\"lead_sources/edit_spec\",\"load_path\":\"lead_sources/edit_spec\",\"icon\":{\"style\":{\"fontSize\":25},\"type\":\"MaterialIcons\",\"name\":\"playlist-add\"}},{\"action\":\"goToPickup\",\"spec\":{\"submit_path\":\"lead_sources/save_list\",\"load_path\":\"lead_sources?all=1\",\"title\":\"Selecionar\",\"top_text\":\"Selecione as fontes e clique em Salvar.\",\"back_btn\":true,\"right_btns\":[{\"action\":\"saveTapped\",\"icon\":{\"type\":\"FontAwesome\",\"name\":\"save\"}}]},\"page_spec\":{\"submit_path\":\"lead_sources/save_list\",\"load_path\":\"lead_sources?all=1\",\"title\":\"Selecionar\",\"top_text\":\"Selecione as fontes e clique em Salvar.\",\"back_btn\":true,\"right_btns\":[{\"action\":\"saveTapped\",\"icon\":{\"type\":\"FontAwesome\",\"name\":\"save\"}}]},\"icon\":{\"type\":\"FontAwesome\",\"name\":\"edit\"}}]}",
        //     'testID': 'fonts'
        //   },
        //   {
        //     "id": 16,
        //     "seq": 76,
        //     "text": "Motivos de perda",
        //     "screen_title": null,
        //     "route": "listScreen",
        //     "icon_name": "file-archive-o",
        //     "icon_type": "FontAwesome",
        //     "bottom_space": false,
        //     "admin_only": true,
        //     "mobile_off": false,
        //     "load_path": "",
        //     "page_spec_class": "Leadfy::AppLists::LostReasons",
        //     "created_at": "2020-08-23T22:33:40.000-03:00",
        //     "updated_at": "2020-08-23T22:33:40.000-03:00",
        //     "is_default": true,
        //     "company_id": null,
        //     "seller_id": null,
        //     "min_version": 4030000,
        //     "max_version": 999999999,
        //     "enabled": true,
        //     "spec": "{\"submit_path\":null,\"load_path\":\"lost_reasons\",\"title\":\"Motivos de perda\",\"top_text\":\"Estes são os motivos de perda habilitados. Para escolher outros clique no ícone editar acima.\",\"item_btns\":[{\"text\":\"editar\",\"action\":\"goToEditItem\",\"route\":\"formScreen\",\"spec\":\"lost_reasons/edit_spec\",\"load_path\":\"lost_reasons/edit_spec\"}],\"right_btns\":[{\"route\":\"formScreen\",\"action\":\"goToEdit\",\"load_path\":\"lost_reasons/edit_spec\",\"spec\":\"lost_reasons/edit_spec\",\"icon\":{\"style\":{\"fontSize\":25},\"type\":\"MaterialIcons\",\"name\":\"playlist-add\"}},{\"action\":\"goToPickup\",\"page_spec\":{\"submit_path\":\"lost_reasons/save_list\",\"load_path\":\"lost_reasons?all=1\",\"title\":\"Selecionar\",\"top_text\":\"Selecione os motivos de perda \\n e clique em Salvar.\",\"back_btn\":true,\"right_btns\":[{\"action\":\"saveTapped\",\"icon\":{\"type\":\"FontAwesome\",\"name\":\"save\"}}]},\"spec\":{\"submit_path\":\"lost_reasons/save_list\",\"load_path\":\"lost_reasons?all=1\",\"title\":\"Selecionar\",\"top_text\":\"Selecione os motivos de perda \\n e clique em Salvar.\",\"back_btn\":true,\"right_btns\":[{\"action\":\"saveTapped\",\"icon\":{\"type\":\"FontAwesome\",\"name\":\"save\"}}]},\"icon\":{\"type\":\"FontAwesome\",\"name\":\"edit\"}}]}",
        //     "testID": "lost_reasons"
        //   },
        //   {
        //     "id": 20,
        //     "seq": 77,
        //     "text": "Atividades",
        //     "screen_title": null,
        //     "route": "listScreen",
        //     "icon_name": "calendar-edit",
        //     "icon_type": "MaterialCommunityIcons",
        //     "bottom_space": false,
        //     "admin_only": true,
        //     "mobile_off": false,
        //     "load_path": "",
        //     "page_spec_class": "Leadfy::AppLists::TaskTypes",
        //     "created_at": "2020-08-23T22:33:40.000-03:00",
        //     "updated_at": "2020-08-23T22:33:40.000-03:00",
        //     "is_default": true,
        //     "company_id": null,
        //     "seller_id": null,
        //     "min_version": 4030000,
        //     "max_version": 999999999,
        //     "enabled": true,
        //     "spec": "{\"submit_path\":null,\"load_path\":\"task_types\",\"title\":\"Tipos de atividade\",\"top_text\":\"Estes são os tipos de atividade habilitados. Para escolher outros clique no ícone editar acima.\",\"item_btns\":[{\"text\":\"editar\",\"action\":\"goToEditItem\",\"route\":\"formScreen\",\"load_path\":\"task_types/edit_spec\",\"spec\":\"task_types/edit_spec\"}],\"right_btns\":[{\"route\":\"formScreen\",\"action\":\"goToEdit\",\"load_path\":\"task_types/edit_spec\",\"spec\":\"task_types/edit_spec\",\"icon\":{\"style\":{\"fontSize\":25},\"type\":\"MaterialIcons\",\"name\":\"playlist-add\"}},{\"action\":\"goToPickup\",\"page_spec\":{\"submit_path\":\"task_types/save_list\",\"load_path\":\"task_types?all=1\",\"title\":\"Selecionar\",\"top_text\":\"Selecione os tipos de atividade \\n e clique em Salvar.\",\"back_btn\":true,\"right_btns\":[{\"action\":\"saveTapped\",\"icon\":{\"type\":\"FontAwesome\",\"name\":\"save\"}}]},\"spec\":{\"submit_path\":\"task_types/save_list\",\"load_path\":\"task_types?all=1\",\"title\":\"Selecionar\",\"top_text\":\"Selecione os tipos de atividade \\n e clique em Salvar.\",\"back_btn\":true,\"right_btns\":[{\"action\":\"saveTapped\",\"icon\":{\"type\":\"FontAwesome\",\"name\":\"save\"}}]},\"icon\":{\"type\":\"FontAwesome\",\"name\":\"edit\"}}]}",
        //     "testID": "activity" 
        //   },
        //   {
        //     "id": 29,
        //     "seq": 80,
        //     "text": "Avisos",
        //     "screen_title": null,
        //     "route": "listScreen",
        //     "icon_name": "computer",
        //     "icon_type": "MaterialIcons",
        //     "bottom_space": true,
        //     "admin_only": false,
        //     "mobile_off": false,
        //     "load_path": null,
        //     "page_spec_class": "Leadfy::AppLists::SysMsgs",
        //     "created_at": "2021-08-15T07:59:59.000-03:00",
        //     "updated_at": "2021-08-15T07:59:59.000-03:00",
        //     "is_default": true,
        //     "company_id": null,
        //     "seller_id": null,
        //     "min_version": 4040000,
        //     "max_version": 999999999,
        //     "enabled": true,
        //     "spec": "{\"title\":\"Avisos\",\"load_path\":\"v0/system_messages/list\",\"cell_tap_btn\":{\"action\":\"navigate\"}}",
        //     "testID": "warnings"
        //   },
        //   {
        //     "id": 32,
        //     "seq": 89,
        //     "text": "Ajuda",
        //     "screen_title": null,
        //     "route": "webviewScreen",
        //     "icon_name": "help-circle-outline",
        //     "icon_type": "MaterialCommunityIcons",
        //     "bottom_space": false,
        //     "admin_only": false,
        //     "mobile_off": false,
        //     "load_path": "",
        //     "page_spec_class": null,
        //     "created_at": "2020-08-23T22:33:40.000-03:00",
        //     "updated_at": "2020-08-23T22:33:40.000-03:00",
        //     "is_default": true,
        //     "company_id": null,
        //     "seller_id": null,
        //     "min_version": 4040000,
        //     "max_version": 999999999,
        //     "enabled": true,
        //     "spec": "{\"title\":\"Ajuda\",\"load_path\":\"support_screen\",\"inject_js\":\"function handleAClick(e){var l=this.href;return e.preventDefault(),ReactNativeWebView.postMessage(JSON.stringify({cmd:\\\"open_link\\\",link:l})),!1}var allAs=document.querySelectorAll(\\\"a.open-external\\\");allAs.forEach(e=\\u003ee.addEventListener(\\\"click\\\",handleAClick));\"}"
        //   },
        //   {
        //     "id": 39,
        //     "seq": 89,
        //     "text": "Novidades!",
        //     "screen_title": null,
        //     "route": "webviewScreen",
        //     "icon_name": "smile",
        //     "icon_type": "Feather",
        //     "bottom_space": false,
        //     "admin_only": false,
        //     "mobile_off": false,
        //     "load_path": "",
        //     "page_spec_class": null,
        //     "created_at": "2020-08-23T22:33:40.000-03:00",
        //     "updated_at": "2020-08-23T22:33:40.000-03:00",
        //     "is_default": true,
        //     "company_id": null,
        //     "seller_id": null,
        //     "min_version": 4040000,
        //     "max_version": 999999999,
        //     "enabled": true,
        //     "spec": "{\"title\":\"Novidades!\",\"load_path\":\"news\",\"inject_js\":\"function handleAClick(e){var l=this.href;return e.preventDefault(),ReactNativeWebView.postMessage(JSON.stringify({cmd:\\\"open_link\\\",link:l})),!1}var allAs=document.querySelectorAll(\\\"a.open-external\\\");allAs.forEach(e=\\u003ee.addEventListener(\\\"click\\\",handleAClick));\"}"
        //   },
        //   {
        //     "id": 34,
        //     "seq": 90,
        //     "text": "Testar notificação",
        //     "screen_title": null,
        //     "route": "webviewScreen",
        //     "icon_name": "bell-outline",
        //     "icon_type": "MaterialCommunityIcons",
        //     "bottom_space": false,
        //     "admin_only": false,
        //     "mobile_off": false,
        //     "load_path": "",
        //     "page_spec_class": null,
        //     "created_at": "2021-06-13T15:23:11.000-03:00",
        //     "updated_at": "2021-06-13T15:23:11.000-03:00",
        //     "is_default": true,
        //     "company_id": null,
        //     "seller_id": null,
        //     "min_version": 4040000,
        //     "max_version": 999999999,
        //     "enabled": true,
        //     "spec": "{\"title\":\"Testar notificação\",\"load_path\":\"test_notif\",\"inject_js\":\"function handleAClick(e){var l=this.href;return e.preventDefault(),ReactNativeWebView.postMessage(JSON.stringify({cmd:\\\"open_link\\\",link:l})),!1}var allAs=document.querySelectorAll(\\\"a.open-external\\\");allAs.forEach(e=\\u003ee.addEventListener(\\\"click\\\",handleAClick));\"}"
        //   },
        //   {
        //     "id": 36,
        //     "seq": 100,
        //     "text": "Sobre",
        //     "screen_title": null,
        //     "route": "webviewScreen",
        //     "icon_name": "compass",
        //     "icon_type": "SimpleLineIcons",
        //     "bottom_space": false,
        //     "admin_only": false,
        //     "mobile_off": false,
        //     "load_path": null,
        //     "page_spec_class": null,
        //     "created_at": "2020-08-23T22:33:40.000-03:00",
        //     "updated_at": "2020-08-23T22:33:40.000-03:00",
        //     "is_default": true,
        //     "company_id": null,
        //     "seller_id": null,
        //     "min_version": 4040000,
        //     "max_version": 999999999,
        //     "enabled": true,
        //     "spec": "{\"title\":\"Sobre\",\"load_path\":\"https://www.leadfy.com.br/politica-de-privacidade.html\",\"inject_js\":\"function handleAClick(e){var l=this.href;return e.preventDefault(),ReactNativeWebView.postMessage(JSON.stringify({cmd:\\\"open_link\\\",link:l})),!1}var allAs=document.querySelectorAll(\\\"a.open-external\\\");allAs.forEach(e=\\u003ee.addEventListener(\\\"click\\\",handleAClick));\"}"
        //   },
        //   {
        //     "id": 31,
        //     "seq": 110,
        //     "text": "Info",
        //     "screen_title": null,
        //     "route": "tableScreen",
        //     "icon_name": "information-outline",
        //     "icon_type": "MaterialCommunityIcons",
        //     "bottom_space": false,
        //     "admin_only": false,
        //     "mobile_off": false,
        //     "load_path": null,
        //     "page_spec_class": null,
        //     "created_at": "2020-08-23T22:33:40.000-03:00",
        //     "updated_at": "2020-08-23T22:33:40.000-03:00",
        //     "is_default": true,
        //     "company_id": null,
        //     "seller_id": null,
        //     "min_version": 4040000,
        //     "max_version": 999999999,
        //     "enabled": true,
        //     "spec": "{\"title\": \"Info\",\"load_path\": \"info\"}",
        //     "testID": "info"
        //   },
        //   {
        //     "id": 35,
        //     "seq": 130,
        //     "text": "Sair (detox2)",
        //     "screen_title": null,
        //     "route": ":exit",
        //     "icon_name": "location-exit",
        //     "icon_type": "MaterialCommunityIcons",
        //     "bottom_space": false,
        //     "admin_only": false,
        //     "mobile_off": false,
        //     "load_path": null,
        //     "page_spec_class": null,
        //     "created_at": "2020-08-23T22:33:40.000-03:00",
        //     "updated_at": "2020-08-23T22:33:40.000-03:00",
        //     "is_default": true,
        //     "company_id": null,
        //     "seller_id": null,
        //     "min_version": 4040000,
        //     "max_version": 999999999,
        //     "enabled": true,
        //     "spec": null,
        //     "testID": 'logout'
        //   }
        // ]
        if (isDev) prependMenuItems(resp.items)
        this.updateOptions(resp.items)
      })
      .catch(e => {
        loge(e)
        rmsg("Erro ao carregar menu.")
      })
  }

  onItemTouch = (item) => {
    if (!item) return
    this.close()
    if (item.route == ':exit') {
      this.exit()
    } else {
      menuNavigate(this.nav, item)
    }
  }

  exit = () => {
    if (this.menuOpen) 
      this.close()
    
    conn.delUser()
      .then(() => {
        this.nav.navigate({ name: 'dummyScreen' })
        this.nav.navigate({ name: 'signinScreen' })
      }).catch(loge)
  }
}

export const menuHandler = new MenuHandler()
