import { ifWeb } from "../../utils/utils";

export default {  
  noMessage:{  
     alignSelf: "center",
     marginTop: 10,
     fontSize: 13,
     color: "#aaa",
     fontStyle: "italic"
  },
  noMessageCt:{  
     flex: 1,
     justifyContent: "center",
     backgroundColor: "white",
     width: ifWeb("100%"),
  },
  noMessageIco:{  
     alignSelf: "center",
     color: "#aaa"
  }
}
