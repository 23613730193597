import { blank } from "./utils";

export default function getWhatsappMessageURL(message:string, zapOpts: any) {
  let query: string[] = []

  if (!blank(zapOpts.phone_number))
    query.push(`phone=${zapOpts.phone_number}`)

  query.push(`text=${encodeURIComponent(message)}`)

  return `${zapOpts.base_url}${query.join("&")}`
}

// https://wa.me/5513903021031?text=h%23ddfh%0A