import { Button, Text, View, Picker, Right, Icon, Header, Left, Body, Segment, Title, Item, Input } from "native-base";
import React from "react";
import Select from 'react-select';
import { s } from "../../../theme/screens/comp-selc";
import { s as i } from "../../../theme/screens/leadNew";
import { s as s3 } from "../../../theme/screens/comp-selc";
import { isWeb, normStr, ifWeb, sflat, blank, isAndroid, addKey, ci, isMob } from "../../../utils/utils";
import { StatusBar, StyleSheet } from "react-native";
import { common, header } from "../../../theme/pieces/shared";
import { FieldProps } from "./_shared_props";
import { RFUtil } from "../rf-util";

interface State {
  value: any
  onGoingSearch: boolean
  keyword: string
}

export class Fsel2 extends React.Component<FieldProps, State> {
  opts: any[]

  // Preface

  constructor(props: FieldProps){
    super(props)
    this.opts = this.brushOptions(props.field.opts)
    this.state = {
      value: props.iniVal,
      onGoingSearch: false,
      keyword: '',
    }
  }

  placeHolder = () => {
    return this.props.field.placeholder || 'Selecione...'
  }

  brushOptions = (opts) => {
    if (!opts)
      return []

    if (isAndroid && opts[0] && opts[0].id !== undefined)
      opts.unshift({
        id: undefined, 
        name: this.placeHolder()
      })

    return opts
      .filter(o => !o.hide)
      .map(o => ({
        ...{value: o.id, label: o.name}, // for web <Select>
        ...{id:    o.id, text:  o.name}, // For native ActionSheet
        ...{action: o} // hold the original option as received from props.options
      }))
  }

  visibleOpts = (keyword) => {
    return this.opts
      .filter(opt => {
        if (keyword)
          return normStr(opt.text).indexOf(normStr(keyword)) != -1
        else
          return true
      })
  }

  getSelected = () => {
    return this.opts.find(o => o.value == this.state.value) || null
  }

  reset = () => {
    this.onChange(undefined)
  }

  onChange = (v) => {
    this.setState(prevState => {
      const newState = {...prevState}
      newState.value = v
      return newState
    }, () => {
      this.props.onChange(v)
      if (isWeb)
        this.props.reLayout()
    })
  }

  render() {
    const { field } = this.props
    const { onGoingSearch, keyword } = this.state
    const help = RFUtil.help(field)

    return (
      <View style={sflat([field.ct_style])}>
        <View style={sflat([s.wrapper, field.wrapper_style])}>
          {/* Label */}
          <Text style={StyleSheet.flatten([s.label, {marginTop: ifWeb(1, -2)}, field.label_style])}>{field.label}</Text>
          
          {/* Select Web */}
          {isWeb &&
          <View style={s.webSelCt}>
            <Select
              value={this.getSelected()}
              onBlur={() => isMob && this.props.onBlur()}
              onFocus={() => isMob && this.props.onFocus()}
              placeholder={this.placeHolder()}
              menuPortalTarget={document.querySelector('body')}
              menuPlacement="auto"
              onChange={v => this.onChange(v.value)}
              options={this.opts}
              style={sflat([field.sel_style])}
              testID="selectOpt"/></View>

          ||
          // Select Native trigger
          <Picker
            renderHeader={backAction =>
              <PickerHeader
                showSearch={this.opts.length > 6}
                handleSearchInput={keyword => this.setState({keyword})}
                backAction={backAction}
              />
            }
            style={s2.picker}
            mode="dropdown"
            textStyle={this.state.value ? s3.mobSelTriggerTxt : s3.mobSelTriggerTxtAll}
            placeholder={this.placeHolder()}
            placeholderStyle={s2.placeHolder}
            itemStyle={s2.itemStyle}
            itemTextStyle={s2.itemTextStyle}
            // iosIcon={<Right><Icon name="ios-arrow-down-outline" style={s2.iosIcon}/></Right>}
            selectedValue={this.state.value}
            onValueChange={this.onChange}
            testID={field.name}
          >
            {(onGoingSearch && this.opts.length === 0) &&
              <View><Text>Nenhum item encontrado</Text></View>
            ||
              this.visibleOpts(keyword).map(opt =>
                <Picker.Item testID="select_opt" label={opt.text} value={opt.id} key={addKey(opt)} {...(opt.id === undefined ? {color: "#c1c1c1"} : null)} />
              )
            }
          </Picker>}

          {!blank(this.state.value) &&
            <Button transparent onPress={() => this.reset()} style={{height: isWeb ? 37 : 45, alignItems: 'center'}} testID={field.name + ".clear"}>
              <Icon name="close" type="SimpleLineIcons" 
                style={s2.closeIcon} />
            </Button>
            ||
            <Button transparent disabled={true} style={{height: 35}}>
              <Icon name="close" type="SimpleLineIcons" 
                style={{opacity: 0}} />
            </Button>}

        </View>

        {help && 
          <View style={i.helpCt}>
            <Text style={i.help}>{help}</Text></View>}
      </View>
    )
  }
}

const PickerHeader = props => (
  <View >
    <Header style={s2.h_header} hasSegment>
      <StatusBar backgroundColor="transparent" barStyle="light-content" />

      <Left style={s2.headerLeft}>
        <Button transparent onPress={props.backAction}>
          <Icon name="arrow-back" style={{color: "white", fontSize: 25}} />
        </Button>
      </Left>

      <Body style={s2.headerBody}>
        <Title style={s2.headerBodyTitle}>
          Selecione
        </Title>
      </Body>

      <Right style={s2.headerRight} />
    </Header>

    {props.showSearch &&
    <Segment style={s2.phSeg}>
      <Item style={s2.searchBox}>
        <Icon name="search" style={s2.searchBoxIcon} />
        <Input
          placeholder="Busca..."
          placeholderTextColor='#aaa'
          style={s2.searchBoxInput}
          autoCapitalize='none'
          onChangeText={props.handleSearchInput} />
      </Item>
    </Segment>}
  </View>
)

const s2 = StyleSheet.create({
  ...common,
  ...header, 
  
  header: {},
  headerLeft: {},
  headerRight: {},
  headerBody: {},
  headerBodyTitle: {color: 'white'},

  searchBox: {flex: 1},
  searchBoxIcon: {color: '#ccc', marginLeft: 10},
  searchBoxInput: {flex: 1, paddingBottom: 5},

  picker: {
    backgroundColor: "transparent",
    borderRadius: 25,
    // height: 40,
    marginBottom: isWeb ? 5 : 0,
    marginTop: isWeb ? 0 : -3,
  },
  placeHolder: {color: "#ccc", paddingLeft: 15, backgroundColor: 'transparent'},
  iosIcon: {
    color: "white",
    paddingRight: 15,
    fontSize: 20,
  },
  phSeg: {
    backgroundColor: "transparent",
    paddingTop: 5,
    marginBottom: 10,
  },

  itemStyle: {},
  itemTextStyle: {fontFamily: "Roboto", fontSize: 16, color: "#6A8287"},
  closeIcon: {fontSize: 22, color: '#bbb'},
})
