import { StyleSheet, TouchableOpacity } from "react-native";
import { View, Text } from "native-base";
import React from "react";
import { join } from "ramda";
import { isIos, isWeb } from "../utils/utils";
import pubSub from "../utils/pub-sub";

const warnEvent = 'account_warning'
const textSeparator = isWeb ? ' ' : "\n"

interface State {
  warning: any
}

export class AccountWarning extends React.Component<{navigation: any}, State> {

  constructor(props){
    super(props)
    this.state = {
      warning: null
    }
  }
  
  componentDidMount(){
    pubSub.subscribe(warnEvent, this.onAccountWarning)
  }

  componentWillUnmount(): void {
    pubSub.unsubscribe(warnEvent, this.onAccountWarning)
  }

  onAccountWarning = (warning) => {
    this.setState({warning: warning})
  }

  goToInvoice(){
    this.props.navigation.navigate('webviewScreen', { spec: { title: "Cobrança", load_path: "https://finan-app.leadfy.xyz", back_btn: true }})
  }

  render() {
    const warning = this.state.warning
    let style = warning ? accountStyles[warning.status] : null

    return (
      <>
        { warning && (
          <TouchableOpacity style={style.container} onPress={this.goToInvoice.bind(this)}>
            <Text style={style.text}>{ join(textSeparator, warning.text_nodes) }</Text>
          </TouchableOpacity>
        )}
      </>
    )
  }
}

const baseStyles = {
  container: {
    paddingTop: isWeb ? 15 : (isIos ? 65 : 10),
    height: isWeb ? 50 : (isIos ? 120 : 60),
    alignSelf: "center",
    maxWidth: "100%", 
    width: "100%",
    alignItems: "center"
  },
  text: {
    fontSize: isWeb ? 16 : 14,
    color: "white",
    textAlign: "center",
    fontWeight: "bold"
  }
}

const accountStyles = {
  late: StyleSheet.create({
    container: {
      ...baseStyles.container as any,
      backgroundColor: "#eb5f5f",
    },
    text: baseStyles.text as any,
  }),
  pending: StyleSheet.create({
    container: {
      ...baseStyles.container as any,
      backgroundColor: "#f5a623"
    },
    text: baseStyles.text as any,
  })
}
