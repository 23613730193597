import { ActionSheet, Button, FooterTab, Icon, Text } from "native-base";
import React from "react";
import { s } from "../theme/pieces/lead-actions-tab";
import conn from "../utils/conn";
import { LeadSession } from "../utils/lead-session-ct";
import { lastIf, loge, simpleCalert, isWeb, isMob, ci } from "../utils/utils";
import { PhoneCaller } from "../utils/phone-caller";

export interface LeadActionsTabProps {
  navigation: any
  msgTpls: any[]
  leadSession: LeadSession | null
  getCustomerContact?: Function
  reloadLead: Function
  forwardSpec: any
}

export class LeadActionsTab extends React.Component<LeadActionsTabProps, {}> {
  msgTpls: any[] = []
  phoneCaller: PhoneCaller

  constructor(props) {
    super(props);
    this.phoneCaller = new PhoneCaller(props.leadSession.lead.customer_contact, props.reloadLead)
  }

  get lead(): any {
    if (!this.props.leadSession) return {}
    return this.props.leadSession.lead
  }

  phoneCall = () => {
    this.phoneCaller.phoneContact.phone = this.props.leadSession.lead.customer.phone_number
    const defaultErr = "Não foi possível fazer a ligação: erro ao checar permissão."
    const self = this

    conn.fetch('check_perm', 'POST', {
      lead_id: self.lead.id,
      action_type: 'phone_call'

    }).then(resp => {
      if (resp.success)
        self.phoneCaller.phoneCall()
      else
        simpleCalert(resp.message || defaultErr)

    }).catch(e => {
      simpleCalert(defaultErr)
      loge(e)
    })
  }

  componentDidMount = () => {
    this.ensureMsgTpls()
  }

  ensureMsgTpls = () => {
    this.msgTpls = this.props.msgTpls || []
    if (!this.msgTpls || this.msgTpls.length == 0)
      conn.fetch('message_templates/list')
        .then(resp => this.msgTpls = resp.tpls)
        .catch(error => loge(error, 'msgTpls fetch'))
  }

  forward = () => {
    if (!this.props.leadSession)
      return
      
    this.props.navigation.navigate(
      "listScreen", 
      {spec: this.props.forwardSpec}
    )
  }

  navToChat = () => {
    if (!this.props.leadSession) 
      return

    this.props.navigation.navigate(
      "leadChatScreen",
      {
        leadSessionId: this.props.leadSession.id,
        msgTpls: this.props.msgTpls,
      }
    )
  }

  whatsapp = () => { }

  reply = () => {
    const opts = this.getReplyOpts()

    if (isWeb) {
      if (this.props.leadSession)
        this.props.navigation.navigate(
          "msgWriteScreen", {
          leadSessionId: this.props.leadSession.id,
          msgTplOpts: this.getReplyOpts()
        })
      return
    }

    ActionSheet.show({
      options: opts,
      cancelButtonIndex: opts.length - 1,
      destructiveButtonIndex: -1,
      title: "Padrão de resposta..."

    }, this.replySelected)
  }

  getReplyOpts = () => {
    const opts: any[] = []

    this.msgTpls.map(tlp =>
      opts.push({ id: tlp.id, text: tlp.name })
    )

    if (isMob)
      opts.push({ id: null, text: "Cancelar" })

    return opts
  }

  replySelected = (buttonIndex) => {
    const msgTplId = this.getReplyOpts()[buttonIndex].id

    if (!msgTplId || !this.props.leadSession)
      return

    this.props.navigation.navigate("msgWriteScreen",
      { msgTplId: msgTplId, leadSessionId: this.props.leadSession.id })
  }

  getTabView(){
    let items: any = [
      'Ligar',
      'Chat',
      'Responder',
      'Encaminhar',
    ]

    if (this.props.leadSession && this.props.leadSession.lead._tabs) {
      this.props.leadSession.lead._tabs.forEach((i, idx) => {
        if (i === 0)
          items[idx] = false
        else if (typeof i == 'string')
          items[idx] = i
      })
    }

    let tabView: any = {}

    const keys = ['phone', 'chat', 'reply', 'forward']
    keys.forEach((i, idx) => tabView[i] = items[idx])

    return tabView
  }

  render() {
    const t = this.getTabView()

    return (
      <FooterTab style={s.footerTab}>
        {/* phone call */}
        {(isMob && t.phone) &&
          <Button vertical onPress={this.phoneCall} testID='phoneCallBtn'>
            <Icon name="phone" type="SimpleLineIcons" style={lastIf([s.icon, s.tabBtnDisabled], !this.phoneCaller.phone())} />
            <Text style={lastIf([s.iconTxt, s.tabBtnDisabled], !this.phoneCaller.phone())}>{t.phone}</Text></Button>}

        {/* chat */}
        {(isMob && t.chat) &&
        <Button vertical onPress={this.navToChat}>
          <Icon type="SimpleLineIcons" name="bubbles" style={s.icon} />
          <Text style={s.iconTxt}>{t.chat}</Text></Button>}

        {/* reply */}
        {t.reply &&
        <Button vertical onPress={() => this.reply()} testID='replyBtn'>
          <Icon type="MaterialIcons" name="chat-bubble-outline" style={s.icon} />
          <Text style={s.iconTxt}>{t.reply}</Text></Button>}

        {/* forward */}
        {t.forward &&
        <Button vertical style={{minWidth: 20}} onPress={() => this.forward()} testID='forwardBtn'>
          {isWeb && <Icon type="FontAwesome" name="mail-forward" style={s.icon} />
                 || <Icon type="SimpleLineIcons" name="action-redo" style={s.icon} />}
          <Text style={s.iconTxt}>{t.forward}</Text></Button>}

      </FooterTab>
    )
  }
}
