import Sentry from '../../../plataform/sentry';
import { Button, Container, Content, Footer, List, ListItem, Spinner, Text, View, Icon } from 'native-base';
import React from "react";
import { Image, StyleSheet } from "react-native";
import { GlobalHeader } from '../../pieces/global-header';
import { LeadActionsTab } from '../../pieces/lead-actions-tab';
import { LeadHeaders } from '../../pieces/lead-headers';
import { LeadStar } from '../../pieces/lead-star';
import { i, s } from "../../theme/screens/leadView";
import conn from '../../utils/conn';
import { LeadSession, leadSessionCt } from '../../utils/lead-session-ct';
import { addKey, dateHour, isWeb, loge, threeDots, simpleCalert, isMob, blank, checkValidPhase, ci, ifWeb, ifIos, ifAndroid, calert, ifMob, logScreen } from '../../utils/utils';
import { LeadActivityWarning } from '../../pieces/lead-activity-warning';
import "../../../plataform/react-datepicker.css";
import { memStore } from '../../utils/mem-store';

interface Props {navigation: any}

interface State {
  lead: any,
  showRain: boolean,
  datePickerVisible: boolean,
  loading: boolean,
  loadingList: boolean,
  msgs: Array<any>,
  msgTpls: Array<any>|null,
  crmPhases: Array<any>|null,
  forwardSpec: any,
  leadBlockReason: string
}

export class LeadViewScreen extends React.Component<Props, State> {
  isPush: boolean = false
  leadSession: LeadSession|null = null
  firstFocus = true

  public readonly state: State = {
    lead: null,
    loading: false,
    showRain: false,
    datePickerVisible: false,
    loadingList: false,
    msgs: [],
    msgTpls: null,
    crmPhases: null,
    leadBlockReason: "",
    forwardSpec: {},
  }

  checkDirtyListener: any

  constructor(props) {
    super(props);
    this.checkDirtyListener = this.props.navigation.addListener('willFocus', () => {
      this.checkDirty()
    });
  }

  checkDirty(){
    if (this.firstFocus) {
      this.firstFocus = false

    } else {
      if (!this.leadSession) return

      if (this.leadSession){
        const { lead } = this.leadSession

        if (lead && lead.dirty) {
          if (lead.back_to_lead_list) {
            this.goBack()
            return

          } else if (lead.doFullReload) {
            this.fullReload()

          } else {
            this.setState({lead: lead})
          }
        }

        if (this.leadSession.reloadMsgs) {
          this.leadSession.reloadMsgs = false
          this.load()
        }
      }
    }
  }

  componentWillUnmount(){
    this.checkDirtyListener.remove();
  }

  navParams(): any{
    return this.props.navigation.state.params
  }

  componentDidMount(){
    Sentry.addBreadcrumb({message: `mounted LeadViewScreen`})
    this.handleParams()
    logScreen('leadView')
  }

  handleParams = () => {
    let { leadSessionId, isPush } = this.navParams()

    this.isPush = isPush
    this.leadSession = leadSessionCt.get(leadSessionId)
    this.loadLead()
  }

  loadLead = (lead = null) => {
    if (!this.leadSession) 
      return

    if (lead) 
      this.leadSession.lead = lead

    this.setState(
      {lead: this.leadSession.lead}, 
      this.load
    )  
  }

  handleBroadcast = (broadcast) => {
    calert(broadcast.alert, (accept) => this.onBroadcastDecision(accept))
  }

  onBroadcastDecision = (accept) => {
    if (!this.leadSession) return
    conn.fetch('contacts/broadcast_decision', 'POST', {
        lead_id: this.leadSession.lead.id,
        accept: accept
      })
      .then(this.onBroadcastDecisionSuccess)
      .catch(this.onBroadcastDecisionError)
  }

  onBroadcastDecisionSuccess = (resp) => {
    const msg = blank(resp.message) ? null : resp.message

    if (resp.success) {
      if (resp.won) {
        if (msg) calert({text: msg})
      } else {
        this.broadcastReturn(msg)
      }
    } else {
      this.broadcastReturn(msg)
    }
  }

  broadcastReturn = (msg) => {
    if (msg)
      calert({text: msg}, this.goBack)
    else
      this.goBack()
  }

  onBroadcastDecisionError = (error) => {
    loge(error)
    calert({text: "Erro ao enviar escolha."}, this.goBack)
  }

  goBack = () => {
    if (this.isPush) {
      conn.revertToStoredUserSession().then(() => {
        this.doGoBack()
      })
    } else {
      this.doGoBack()
    }
  }

  doGoBack = () => {
    this.props.navigation.goBack()
  }
  
  // load msg list

  load = () => {
    if (!this.state.lead) return
    this.setState({loadingList: true})
    conn.fetch('messages/list?lead_id='+String(this.state.lead.id), 'GET')
      .then(resp => this.onloadSuccess(resp))
      .catch(err => this.onloadError(err))
  }

  onloadSuccess = (resp: any) => {
    const msgs = resp.messages || []

    this.setState({
      loadingList: false,
      msgs: msgs,
      msgTpls: resp.special_filtered_templates,
      forwardSpec: resp.forward_spec,
      crmPhases: resp.crm_phases,
      leadBlockReason: resp.lead_block_reason
    }, () => this.afterLoad(resp))

    if (!this.leadSession) return
    this.leadSession.lead.dirty = true
  }

  afterLoad = (resp) => {
    if (resp.broadcast)
      this.handleBroadcast(resp.broadcast)
  }

  componentDidUpdate(){
    setTimeout(this.checkAvailability, 10)
  }

  checkAvailability = () => {
    if (!blank(this.state.leadBlockReason))
  		calert({title: 'Aviso', text: this.state.leadBlockReason, hideBackdrop: false}, this.goBack)
  }

  onloadError = (error: any) => {
    this.setState({loadingList: false})
    simpleCalert('Erro ao carregar mensagens')
    loge(error, "lead view onloadError")
  }

  msgViewScreen = (msg: any) => {
    if (!this.leadSession) return

    this.leadSession.msg = msg
    this.props.navigation.navigate("msgViewScreen", {
      leadSessionId: this.leadSession.id,
      msgTpls: this.state.msgTpls,
      forwardSpec: this.state.forwardSpec,
      crmPhases: this.state.crmPhases,
    })
  }

  fullReload = () => {
    if (!this.leadSession) return

    conn.fetch('contacts/get?id=' + this.leadSession.lead.id + '&a=1', 'GET')
      .then(resp => {
        if (!resp.success) return
        if (!this.leadSession) return
        this.loadLead(resp.lead)
      }).catch((err) => {
        loge(err, "Error fetching lead")
      })
  }

  // Sold

  hideDatePicker = () => this.setState({datePickerVisible: false})
  
  soldTapped = () => {
    if (!this.leadSession)
      return

    const callbackSessionId = memStore.create({
      onSave: (editingObjectTree) => {
        if (editingObjectTree) {
          memStore.destroy(callbackSessionId)
          this.fullReload()
        }
      }
    }).id
  
    this.props.navigation.navigate(
      "formScreen", 
      {
        spec: `contacts/won_form?object_id=${this.leadSession.lead.id}`,
        callbackSessionId: callbackSessionId,
      }
    )
  }

  lostTapped = () => {
    if (!this.leadSession) 
      return

    const callbackSessionId = memStore.create({
      onSave: (editingObjectTree) => {
        if (editingObjectTree) {
          memStore.destroy(callbackSessionId)
          this.fullReload()
        }
      }
    }).id
    
    this.props.navigation.navigate(
      "formScreen", 
      {
        spec: `contacts/archive_form?object_id=${this.leadSession.lead.id}`,
        callbackSessionId: callbackSessionId,
      }
    )
  }

  // Activities

  createActivity = () => {
    if (!this.leadSession) 
      return
    
    const callbackSessionId = memStore.create({
      onSave: (editingObjectTree) => {
        if (editingObjectTree) {
          memStore.destroy(callbackSessionId)
          this.fullReload()
        }
      }
    }).id

    this.props.navigation.navigate(
      "formScreen", 
      {
        spec: `task/task_form?object_id=${this.leadSession.lead.id}`,
        callbackSessionId: callbackSessionId,
      }
    )
  }

  activityEndTapped = (cod: string, note: string, en?: boolean) => {
    if (!this.leadSession) return
    const { lead } = this.leadSession
    conn.fetch('contact_tasks/mark', 'POST', {
      cod: cod,
      id: lead.id,
      note: note,
      en: en,
    }).then(resp => {
      resp.success ?
        this.activityEnded(resp) :
        this.activityEndedError(new Error(resp.errContent))
    }).catch(this.activityEndedError)
  }

  activityEndedError = (e) => {
    simpleCalert(e.message || 'Erro de comunicação com o servidor. Verifique a internet e tente novamente.')
  }
  
  activityEnded = (resp: any) => {
    if (!blank(resp.respBody))
      return simpleCalert(resp.respBody)

    if (!this.leadSession) return

    conn.fetch('contacts/get?id=' + this.leadSession.lead.id + '&a=1', 'GET')
      .then(resp => {
        if (!resp.success) return
        if (!this.leadSession) return
        this.loadLead(resp.lead)
      }).catch((err) => {
        loge(err, "Error fetching lead")
      })
  }

  showActivity = () => {
    if (!this.leadSession) return false
    const { lead } = this.leadSession
    return lead.activity_status == 0 && 
           !blank(lead.activity_name) && 
           checkValidPhase(lead)
  }

  editLead = () => {
    if (!this.leadSession) return

    const callbackSessionId = memStore.create({
      onSave: (editingObjectTree) => {
        if (editingObjectTree) {
          memStore.destroy(callbackSessionId)
          this.fullReload()
        }
      }
    }).id

    const objectSessionId = memStore.create(
      this.leadSession.lead
    ).id

    this.props.navigation.navigate(
      'formScreen', 
      {
        objectSessionId: objectSessionId, spec: 'contacts/spec',
        callbackSessionId: callbackSessionId
      }
    )
  }

  goToAddNote = () => {
    if (!this.leadSession) return

    const callbackSessionId = memStore.create({
      onSave: (editingObjectTree) => {
        if (editingObjectTree) {
          memStore.destroy(callbackSessionId)
          this.load()
        }
      }
    }).id

    this.props.navigation.navigate(
      'formScreen', 
      {
        spec: `contacts/add_note_spec?object_id=${this.leadSession.lead.id}`,
        callbackSessionId: callbackSessionId,
      }
    )
  }

  rightBtns(){
    let btns: any[] = []
    let spec = [1, 1, 1]

    if (this.leadSession && this.leadSession.lead._btns)
      spec = this.leadSession.lead._btns

    if (spec[0])
      btns.push(
        <Button transparent onPress={this.editLead} testID="editBtn">
          <Icon style={style.editBtn} type="FontAwesome" name="edit" />
        </Button>
      )

    if (spec[1])
      btns.push(
        <LeadStar leadSession={this.leadSession}/>
      )

    if (spec[2])
      btns.push(
        <Button transparent onPress={this.goToAddNote} testID="noteBtn">
          <Icon style={style.addNoteBtn} type="MaterialIcons" name="post-add" />
        </Button>
      )

    return btns
  }

  render () {
    const {lead} = this.state;

    if (!lead || !lead.customer || !lead.product)
      return <Container><Button onPress={this.goBack} style={{alignSelf: "center", marginTop: 100}}>
               <Text>Voltar</Text>
             </Button></Container>

    if (!this.leadSession) 
      return null
    
    return (
      <Container>
        <GlobalHeader 
          title={this.state.lead.customer.name}
          onClose={this.goBack}
          closeOrBack={this.isPush}
          right={this.rightBtns()} 
          navigation={this.props.navigation} />

        <Content style={s.content}>
          {this.state.loading && 
          <View style={s.overlay}><Spinner style={s.overlaySpinner} size='small' color='#6A8287' /></View>}

          {isWeb &&
          <LeadActionsTab 
            leadSession={this.leadSession} 
            msgTpls={this.state.msgTpls || []} 
            forwardSpec={this.state.forwardSpec}
            navigation={this.props.navigation}
            reloadLead={this.loadLead} />}

          <LeadHeaders 
            leadSession={this.leadSession} 
            navigation={this.props.navigation} 
            crmPhases={this.state.crmPhases}
            reloadLead={this.loadLead}
            obsOrFrom={true} />

          {/* buttons change status */}
          <View style={s.btns}>
              <Button block style={s.btn1} onPress={() => this.createActivity()} testID='createActivityBtn'>
								<Text style={s.btnTxt}>{"    Criar\nAtividade"}</Text></Button>
              <Button block style={s.btn2} onPress={() => this.soldTapped()} testID='soldBtn'>
								<Text style={s.btnTxt}>{"Negócio\nFechado"}</Text></Button>
              <Button block style={s.btn3} onPress={() => this.lostTapped()} testID='archiveBtn'>
								<Text style={s.btnTxtRed}>{"Arquivar\n   Lead"}</Text></Button></View>

          {this.showActivity() &&
            <LeadActivityWarning lead={lead} onClick={this.activityEndTapped} />}

          {/* messages */}
          <View style={s.msgsCt}>
            {this.state.loadingList &&
            <Spinner size="small" color="#6A8287"></Spinner>
            ||
            <List style={s.msgs} dataArray={this.state.msgs.map(addKey)}
            renderFooter={() => <View style={{height: 50}}/>}
            renderRow={data => {
              const item = data
              return <ListItem style={s.listItem} onPress={() => this.msgViewScreen(item)} key={item.key} testID='messagesList'>
                {item.thumb && 
                  <Image style={s.msgIco} source={{ uri: item.thumb}} />}
                {item.is_new && <Text style={s.notReadMsgBall}>●</Text>}
                <Text style={s.msgPreview} numberOfLines={1} testID={item.message}>{threeDots(item.message)}</Text>
                <Text style={s.msgDate}>{dateHour(item.date_created)}</Text></ListItem>}}/>}</View>

        </Content>

        {isMob &&
        <Footer style={s.footerSt}>
          <LeadActionsTab leadSession={this.leadSession} msgTpls={this.state.msgTpls || []}
                          navigation={this.props.navigation} reloadLead={this.loadLead} 
                          forwardSpec={this.state.forwardSpec} /></Footer>}

      {!blank(this.state.leadBlockReason) &&
      <View style={style.overlay}></View>}

      </Container>
    )
  }
}

const style = StyleSheet.create({
  editBtn: {
    color: 'white', 
    fontSize: ifWeb(22, 20), 
    marginTop: ifWeb(2, ifIos(0, 5)), 
    marginRight: ifAndroid(-14, -10)
  },
  addNoteBtn: {
    color: '#f1f1f1', 
    fontSize: ifWeb(22, 25), 
    marginTop: ifWeb(2, ifIos(-1, 1)), 
    marginRight: ifAndroid(-14, -10),
    marginLeft: -12
  },
  overlay: {
    position: 'absolute', 
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    zIndex: 10,
    backgroundColor: `rgba(0, 0, 0, ${ifWeb('0.2', '0.7')})`,
  }
})