import { StyleSheet, Dimensions } from "react-native";
import {common, header, i} from "../pieces/shared"
import filters from "../pieces/filters";
import noMessage from "../pieces/noMessage";
import { ifWeb } from "../../utils/utils";
const { width, height } = Dimensions.get('window');

const info = {
  marginTop: 5,
  fontFamily: "Roboto",
  fontSize: 11,
  color: "#6A8287",
}

const ball = {
  marginLeft: 0,
  marginRight: 0,
  fontSize: ifWeb(12, 10),
}

const statusBar = {
  flex: 0,
  width: 3, 
  height: "100%", 
}
const p:any = {
  ...common,
  ...header,
  ...filters,
  ...noMessage,

  altContent: {
    backgroundColor: ifWeb("#F2F4F7"), 
    flex: 1,
    paddingLeft: ifWeb(100),
    paddingRight: ifWeb(100),
  },

  // 
  // List
  // 

  list: {
    borderWidth: 0,
    backgroundColor: "white",
  },
  leadItem: {
    paddingTop: 0,
    paddingBottom: 0,
    marginLeft: 0,
    borderBottomColor: "lightgray", 
    borderBottomWidth: StyleSheet.hairlineWidth,
  },

  // statusBar
  // statusBarRed:    {...statusBar, backgroundColor: "red"},
  // statusBarGreen:  {...statusBar, backgroundColor: "green"},
  // statusBarBlue:   {...statusBar, backgroundColor: "blue"},
  // statusBarTransp: {...statusBar, backgroundColor: "transparent"},
  statusBar: {...statusBar},

  // balls
  balls: {
    flex: 0,
    paddingTop: 20,
    width: 20,
    alignSelf: "stretch",
    alignItems: "flex-end",
  },
  blue: {...ball, color: "blue"},
  red:  {...ball, color: "red"},
  star: {...ball, color: "gold", fontSize: 12, marginRight: -1.5},

  // main
  mainBody: {
    flex: 1,
    paddingTop: 7, 
    paddingBottom: 10,
  },

  title: {
    ...info,
    marginTop: 7,
    fontFamily: "Roboto-Medium",
    fontSize: 14,
  },
  desc: {
    ...info,
    fontSize: 13,
  },
  subTitleRed: { ...info, color: "#F73D3D" },
  subTitleGreen:  { ...info, color: "green" },
  subTitleBlue:   { ...info, color: "blue" },
  subTitleNormal: { ...info },

  // right
  price: {
    ...info,
    fontFamily: "Roboto-Medium",
  },
  info: {...info},
  infoOwner: {...info, fontStyle: 'italic', color: '#aaa'},
  infoBold: {
    ...info,
    fontFamily: "Roboto-Medium",
  },
  
  get footer() {
    return ifWeb({
      height: 100,
      paddingVertical: 20,
      marginTop: 20,
      marginBottom: 20,
    },{
      position: 'relative',
      width: width,
      height: height/2,
      paddingVertical: 20,
      marginTop: 10,
      marginBottom: 10,
      color: '#aaa', 
      fontSize: 12,
    })
  },  
}

const s = StyleSheet.create(p)
const i2 = i;
export {s, i2 as i}
