import { Button, Text, View, Icon, Input, Left, Body } from "native-base";
import React from "react";
import { rmsg } from "../../../utils/utils-view";
import { blank, calert, ci, sflat, simpleCalert } from "../../../utils/utils";
import conn from "../../../utils/conn";
import { TextIcons } from "../../../components/text-with-icons";
import { FieldProps } from "./_shared_props";
import { s } from "../../../theme/screens/leadNew";


interface State {
  inlineMsg: string
}

export class Fbutton extends React.Component<FieldProps, State> {
  constructor(props) {
    super(props)
    this.state = {
      inlineMsg: ""
    }
  }

  request = () => {
    this.setState({inlineMsg: ""})
    const { request } = this.props.field

    conn.fetch(request.path, request.verb)
    .then(this.saveSuccess)
    .catch(this.saveError)
  }

  saveSuccess = (resp:any) => {
    if (resp.on_delete) {
      this.props.onDelete(resp)
      this.props.navigate("goBack")
    } else {
      if (resp && resp.msg && resp.msg.type == 'inline')
        this.setState({inlineMsg: resp.msg.content.text})
      else
        rmsg(resp.msg)

      if (resp.navigate)
        this.props.navigate(resp.navigate)
    }
  }

  saveError = (resp:any) => {
    if (!resp.msg)
      resp.msg = defaultMsgError
      
    if (resp.msg.type == 'inline')
      this.setState({inlineMsg: resp.msg.content.text})
    else
      rmsg(resp.msg)
  }

  confirmPress = () => {
    const { confirmation } = this.props.field

    if (!confirmation) {
      this.request()
      return
    }

    calert({
      text: confirmation.text || `Tem certeza que deseja realizar essa ação?`, 
      okText: confirmation.ok_text || "Confirmar",
      showCancel: true,
    }, (ok) => {ok && this.request()})
  }

  render() {
    const { field } = this.props
    return (
      <View style={sflat([field.content.view_btn_style])}>
        <Button style={sflat([s.btn, field.style_btn])} onPress={() => this.confirmPress()} disabled={field.disabled}>
          <TextIcons text={field.content.text}
            iconLeft={field.content.icon_left} iconRight={field.content.icon_right}/>
        </Button>

        {!blank(this.state.inlineMsg) &&
          <Text>{this.state.inlineMsg}</Text>}
      </View>
    )
  }
}

const defaultMsgError = {
  type: "alert",
  content: {
    text: "Houve um erro ao tentar executar a ação"
  }
}