import Sentry from '../../../plataform/sentry';
import { Button, Container, Icon, View } from 'native-base';
import React from "react";
import { FullList } from '../../components/full-list';
import { Config, resetAddress } from '../../config';
import { GlobalHeader } from '../../pieces/global-header';
import { LeadListCell } from '../../pieces/lead-list-cell';
import { s } from "../../theme/screens/leadList";
import conn from "../../utils/conn";
import { DatasourceList } from '../../utils/datasource-list';
import { LeadSession, leadSessionCt } from '../../utils/lead-session-ct';
import { ifWeb, isWeb, simpleCalert, blank, loge, ci, calert, logScreen, getUriQuery } from '../../utils/utils';
import { memStore } from '../../utils/mem-store';
import globalVar from '../../utils/global-var';
import { menuHandler } from '../../utils/menu-handler';
import { openLink } from '../../utils/open-link';
import { crmPhases } from '../../utils/crm-phases';
import Select from 'react-select';

interface Props {navigation: any}

interface State {
  showMenu: boolean
}

export class LeadListScreen extends React.Component<Props, State> {
  leadSession: LeadSession|null = null
  checkDirtyListener: any
  menuOptions: any[]|null = null
  listDataSource: DatasourceList
  dsSessionId: any
  simpleCalertMsg: string

  static navigationOptions = {
    gesturesEnabled: false,
  }

  public readonly state: State = {
    showMenu: false,
  }

  constructor(props) {
    super(props)
    this.simpleCalertMsg = ''
    this.checkDirtyListener = this.props.navigation.addListener('willFocus', () => {
      this.checkDirty()
    })
    this.listDataSource = this.setupDs()
  }

  componentWillUnmount(){
    this.checkDirtyListener.remove();
  }

  componentDidMount = () => {
    Sentry.addBreadcrumb({message: `mounted LeadListScreen`})
    logScreen('leadList')
    this.checkLeadFromUri()
  }

  checkLeadFromUri = () => {
    if (isWeb) {
      const query = getUriQuery()
      if (query['l']) {
        resetAddress()
        conn.fetch('contacts/get?id=' + query['l'] + '&a=1', 'GET')
          .then(resp => {
            if (!resp.success) return
            this.leadTapped(resp.lead)
          }).catch((err) => {
            loge(err, "Error fetching lead")
          })
      }
    }
  }

  // list

  setupDs = () => {
    return new DatasourceList({
      baseUrl: 'contacts/list',
      perpage: ifWeb(20, 10),
      reloadSingleIdParam: 'only_lead_ids',
      initialFilterState: {[Config.initialActiveFilter]: 'on'},
      parseResp: (resp) => {
        return {
          newTotal: resp.total, 
          newItems: resp.list, 
          filter_options: resp.filter_options
        }
      },
      onExtraAlert: this.onExtraAlert,
      onExtraData: this.onExtraData
    })
  }

  onExtraData = (data: any) => {
    if (data.crm_phases)
      crmPhases.set(data.crm_phases)
  }

  onExtraAlert = (alert: any) => {
    if (alert)
      calert({
        text: alert.text, 
        okText: alert.ok_text,
        showCancel: alert.show_cancel,
      }, ok => {
        if (ok && alert.link)
          openLink(alert.link)
      })
  }

  leadTapped = (lead: any) => {
    this.leadSession = leadSessionCt.create(lead)

    this.props.navigation.navigate("leadViewScreen", 
      {leadSessionId: this.leadSession.id})
  }

  checkDirty(){
    if (globalVar.reloadLeads) {
      const now = (new Date()).getTime()
      if (now - globalVar.reloadLeads.getTime() < 5000) {
        this.listDataSource.refresh()
        if (!blank(globalVar.reloadMsg)) {
          this.simpleCalertMsg = globalVar.reloadMsg
        }
      }
      globalVar.reloadLeads = null
      globalVar.reloadMsg = ''
      return
    }

    if (!this.leadSession || !this.leadSession.lead)
      return

    const dirty = this.leadSession.lead.dirty
    const leadId = this.leadSession.lead.id
    
    leadSessionCt.destroy(this.leadSession.id)
    this.leadSession = null

    dirty && this.listDataSource.updateItem(leadId)
  }

  showCalertMsg = () => {
    if (!blank(this.simpleCalertMsg)) {
      const msg = this.simpleCalertMsg
      this.simpleCalertMsg = ''
      simpleCalert(msg)
    }
  }

  getCell = (data) => {
    return <LeadListCell key={data.id.toString()} data={data} leadTapped={this.leadTapped} />
  }

  // util

  left = () => {
    return isWeb
      ? []
      : [<Button transparent style={s.h_left} onPress={menuHandler.open} testID='menuBtn'>
           <Icon style={s.h_btn_hamb} name='menu' /></Button>]
  }

  bulkActionsMenu = () => {
    return (
      <View style={{width: 200}}>
        <Select
            value={null}
            placeholder='Ações...'
            menuPortalTarget={document.querySelector('body')}
            menuPlacement="auto"
            isSearchable={false}
            noOptionsMessage=''  
            onChange={v => this.openBulkActionsMenu(v.value)}
            options={[
              {value: 'forward', label: 'Encaminhar'},
              {value: 'delete', label: 'Deletar'},
              {value: 'export', label: 'Exportar'},
            ]} />
      </View>
    )
    // TODO for mobile:
    // <Button transparent style={s.h_left} onPress={this.openBulkActionsMenu}>
      // <Icon style={s.h_btn_filter} name='rocket-outline' type="MaterialCommunityIcons" /></Button>
  }

  openBulkActionsMenu = (action) => {
    this.props.navigation.push(
      'formScreen', 
      {spec: `bulk_actions/${action}_spec`, 
      filters: this.listDataSource.filters}
    )
  }

  right = () => {
    let list: any[] = []
    
    // if (isWeb)
      // list.push(this.bulkActionsMenu())

    list.push(<Button transparent style={s.h_left} onPress={this.openSearch} testID="search_btn">
        <Icon style={s.h_btn_filter} name='filter-outline' type="MaterialCommunityIcons" /></Button>)

    return list
  }

  openSearch = () => {
    this.dsSessionId = memStore.create(this.listDataSource).id
    this.props.navigation.push('formScreen', {spec: 'contacts/search_spec', listDsSessionId: this.dsSessionId})
  }

  navTitle(){
    let navTitle = 'Leads'
    if (conn.user && 
        conn.user.group_name && 
        conn.user.group_name == 'ClickNet') {
      navTitle = 'FAC'
    }
    return navTitle
  }

  render () {
    return (
      <Container>
        <GlobalHeader 
          title={this.navTitle()}
          navigation={this.props.navigation} 
          left={this.left()} 
          right={this.right()} />
        
        <View style={s.altContent} testID='leadListView'>
          <FullList dataSource={this.listDataSource} cell={this.getCell} 
                    initialActiveFilter={Config.initialActiveFilter} 
                    onLoadSuccess={this.showCalertMsg} />
        </View>
      </Container>
    )
  }
}
