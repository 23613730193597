import React from "react";
import { Container, View, Text, Button, Content, Textarea } from 'native-base';
import { GlobalHeader } from "../../pieces/global-header";
import { FlatList, StyleSheet, TextInput } from "react-native";
import { ci, isIos, isWeb, logScreen, simpleCalert, uuidv4 } from "../../utils/utils";
import conn from "../../utils/conn";
import { s } from "../../theme/screens/leadView";
import { Fsel2 } from "../../lib/remote-form/fields/fsel2";
import Sentry from "../../../plataform/sentry";
import { LeadSession, leadSessionCt } from "../../utils/lead-session-ct";
import { MemObj, memStore } from "../../utils/mem-store";

interface Props { navigation: any }

interface State {
  leadName: string,
  templateBody: string,
  templateVariables: string,
  templateList: string,
  templateSelected: any,
  templateText: string,
  templateValues: any,
  templateName: string,
  errorMsgs: Array<string>
}

export class MessageTemplate extends React.Component<Props, State>{
  leadSession: LeadSession | null = null
  isPush: boolean = false
  leadName: string = ''
  callbackSession: MemObj|null = null
  component: Content;


  constructor(props) {
    super(props)
    this.state = {
      leadName: '',
      templateBody: '',
      templateVariables: '',
      templateList: '',
      templateSelected: [],
      templateText: '',
      templateValues: [],
      templateName: '',
      errorMsgs: [],
    }
  }

  navParams(): any {
    return this.props.navigation.state.params
  }

  componentDidMount() {
    Sentry.addBreadcrumb({ message: `mounted MessageTemplate` })
    const {leadName, leadSessionId, callbackSessionId} = this.navParams()
    this.leadName = leadName
    this.leadSession = leadSessionId
    

    this.leadSession = leadSessionCt.get(leadSessionId)
    ci(22, this.leadSession)

    if (callbackSessionId) {
      this.callbackSession = memStore.get(callbackSessionId)
    }

    if (this.leadName) {
      this.setState({
        leadName: this.leadName,
      })
    }

    conn.fetch(`msg_tpls/chat_tpls`, 'GET')
    .then(resp => {
      if (!resp.success) {
        simpleCalert("Erro ao carregar templates.")
      } else {
        this.setState({templateList: resp.list})
      }
    }).catch(error => {
      simpleCalert("Erro ao carregar templates.")
    })
  }

  goBack = () => {
    if (this.isPush) {
      conn.revertToStoredUserSession().then(() => {
        this.doGoBack()
      })
    } else {
      this.doGoBack()
    }
  }

  doGoBack = () => {
    this.props.navigation.goBack()
  }

  templateSel = (tmpSelected) => {
    this.setState({errorMsgs: []})

    if (tmpSelected == undefined){
      this.setState({templateVariables: null, templateText: ''})
      return
    }

    conn.fetch(`msg_tpls/chat_tpl?id=${tmpSelected}`, 'GET')
    .then(resp => {
      if (!resp.success) {
        simpleCalert("Erro ao carregar template.")
      } else {
        this.setState({templateName: resp.item.name, templateSelected: resp.item, templateBody: resp.item.data.body})
        this.loadMsg(this.state.templateSelected)
      }
    }).catch(error => {
      simpleCalert("Erro ao carregar template.")
    })
  }

  loadMsg(tmpBody){
    const templateText = tmpBody.data.body
    const templateVarCount = tmpBody.data.var_count
    this.setState({templateText: templateText, templateVariables: templateVarCount})
  }

  changeBody(value, key){
    this.setState((prevState) => {
      let newState:any = {}
      newState.templateValues = {...prevState.templateValues}
      newState.templateValues[key] = value
      let b = prevState.templateBody
      for (let iKey in newState.templateValues){
        let val = newState.templateValues[iKey]
        if (val == '' || val === null)
          val = `{{${iKey}}}`
        b = b.replace(`{{${iKey}}}`, val)
      }
      newState.templateText = b
      return newState
    })
  }

  renderVariables(x){
    let var_number = [];
    let seq_var = [];

    for (let var_count = x; var_count > 0; var_count--) {
      var_number.unshift(var_count)
    }
    
    seq_var.push(var_number.map((key) => {
      return (
        <View style={{flexDirection: 'column', justifyContent: 'flex-start', paddingLeft: 15, alignItems: 'flex-start'}} key={key}>            
          <Textarea
            key={key}
            underline
            rowSpan={5}
            multiline={true}
            style={{backgroundColor: '#eeeeee', width: '95%', marginTop: 10, paddingBottom: 3, height: "auto", maxHeight: 150}}
            bordered={true}
            scrollEnabled={true}
            placeholder={`{{${key}}}`}
            onChangeText={(value) => this.changeBody(value, key)}/></View>
      )
    }))
    
    return seq_var
  }

  fselField(opts){
    let opt = opts

    opt.map((o) => {
      o.name = o.display_name
    })
    return {
      "label": "Modelos",
      "wrapper_style": {
        "marginTop": 11,
        "width": 300,
        'borderBottomWidth': 0
      },
      "label_style": {
        "width": 70,
        "color": '#12B2FE',
      },
      "opts": opt
    }
  }

  checkVariables(arr){
    let n = parseInt(this.state.templateVariables)

    if (n > 0){
      for (let a = 1; a <= n; a++){
        if (this.state.templateValues[a] == '' || this.state.templateValues[a] == null){
          arr.push(`{{${a}}}`) 
        }
      }
      this.setState({errorMsgs: arr})
    }
    else if (!this.state.templateText){
      arr.push('Selecione um modelo.')
      this.setState({errorMsgs: arr})
    }
    return arr
  }

  submitTemplate(){
    let arr = []

    this.checkVariables(arr)

    if (arr.length != 0 )
      return

    if (!this.callbackSession || !this.callbackSession.obj)
      return

    if (this.callbackSession.obj.onSelectTmp){
      this.callbackSession.obj.onSelectTmp(this.state.templateName, this.state.templateText, this.state.templateValues, this.leadSession.lead.seller.name)
    }
    this.goBack()
  }

  scrollToBottom = () => {
    const screenHeight = window.innerHeight
    isWeb ? 
      [this.component['_root'].scrollToPosition(0, screenHeight), window.scrollTo(0 , screenHeight)]
      : 
      this.component['_root'].scrollToEnd() 
  }

  rightBtns = () => {
    let opts: any[] = []

    opts.push(
      <Button transparent onPress={() => this.submitTemplate()}>
        <Text style={{fontSize: 14, color: 'white'}}>Enviar</Text></Button>
    )

    return opts
  }

  render() {
    if (!this.state.templateList)
      return null

    return (
      <Container>
        <GlobalHeader
          title={"Modelos"}
          onClose={this.goBack}
          closeOrBack={this.isPush}
          right={this.rightBtns()} 
          navigation={this.props.navigation} />
        <Content style={s.content} ref={c => (this.component = c)}>
          <View style={{paddingLeft: 15, alignSelf: 'flex-start', paddingBottom: 0, marginBottom: 0, height: 25}}>
            <Fsel2
              field={this.fselField(this.state.templateList)}
              onChange={filterSel => this.templateSel(filterSel)}
              />
          </View>
          <View style={s2.cell}>
            <View style={s.cellLineFirst}>
              <Text style={s.cellTitObs}>Para:</Text>
              <Text style={{marginTop: 15, fontFamily: "Roboto", fontSize: 14, color: "#6A8287"}}>{this.state.leadName}</Text>
            </View>
          </View>

          {!!this.state.templateText &&
            this.renderVariables(this.state.templateVariables)}

          {!!this.state.templateText &&
          <View style={{width: '90%', alignSelf: 'center'}}>
            <Text style={{marginTop: 15, fontFamily: "Roboto", fontSize: 15, color: "#6A8287"}} selectable>{this.state.templateText}</Text></View>}

          {!!this.state.templateText &&
            <Button style={{backgroundColor: 'rgb(18, 178, 254)', width: 113, justifyContent: 'center', alignSelf: 'center', marginTop: 25, borderRadius: 4}} onPress={() => this.submitTemplate()}>
              <Text style={{fontSize: 14, color: 'white'}}>Enviar</Text></Button>}

          {this.state.errorMsgs.length > 0 &&
            <View style={s2.errorBoxCt} testID="error_content">
              <FlatList
                onContentSizeChange={()=> this.scrollToBottom()}
                data={this.state.errorMsgs}
                renderItem={({item}) => <Text key={uuidv4()} style={s2.errorBoxItem} testID={item}>-  {item}</Text>}
                ListHeaderComponent={<Text style={{fontSize: 17}}>Preencha os seguintes campos:</Text>}
                keyExtractor={(item, index) => index.toString()}
              />
            </View>
          }
        </Content>
      </Container>
    )
  }
}

const s2 = StyleSheet.create({
  errorBoxCt: {
    alignSelf: 'center',
    padding: 15,
    width: "80%",
    borderRadius: 10,
    borderColor: '#ff7878',
    backgroundColor: "#ffdada",
    borderWidth: 3,
    marginBottom: 20,
    marginTop: 30,
  },
  errorBoxItem: {
    fontSize: 16,
    marginTop: 8,
    marginBottom: 2,
  },
  saveBtn: {
    ...s.submitCt,
    alignSelf: 'center',
  },
  resetBtn: {
    ...s.btn,
    marginLeft: 20,
    backgroundColor: "transparent",
    borderColor: "#999",
    elevation: 0,
    borderWidth: 2,
  },
  resetTxt: {
    color: "#999",
  },
  cell: {
    flex: 1, 
    flexDirection: 'column', 
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: '#ccc',
    paddingBottom: 20,
    paddingHorizontal: 15,
    marginTop: 11,
    marginBottom: 11
  },
})