import { StyleSheet } from "react-native";
import i from "../imgs"
import { ifWeb, ifSmall } from "../../utils/utils";

const logo:any = {
    alignSelf: "center",
    maxWidth: "100%", 
    marginVertical: 10,
    marginTop: ifSmall(60, 120),
    width: 70,
    height: 70,
}

const s = StyleSheet.create({
    // container
    cnt: {
        backgroundColor: "transparent",
        // height: "100%",
    },
    login: {
        flexDirection: "column",
        flex: 1,
    },
    bgImg: {
		flex: 1,
		backgroundColor:"#2C86A2"
	},

    // logo
    logoStyle: {
        alignSelf: "center",
        maxWidth: "100%", 
        marginVertical: 10,
        width: 100,
        height: 100
    },
    logo: {
        ...logo
    },
    logoSignup: {
        ...logo,
        marginTop: ifWeb(90, 70),
        width: ifWeb(60, 30),
        height: ifWeb(60, 30),
    },
    logoName: {
        color: "white", 
        fontSize: 23, 
        fontFamily: "Roboto-Medium",
        marginTop: 5
    },
    logoNameSignup: {
        color: "white", 
        fontSize: 23, 
        fontFamily: "Roboto-Medium",
        marginTop: 7
    },

    // form
    formSubmit: {
        paddingTop: 30
    },
    form: {
        flex: 1,
        marginTop: ifSmall(0, 100),
        paddingHorizontal: 35
    },
    formSignup: {
        flex: 1,
        marginTop: 20,
        paddingHorizontal: 35
    },
    formitem: {
        backgroundColor: 'rgb(232, 240, 254)',
        borderRadius: 5,
    },
    input: {
        backgroundColor: "transparent",
        color: "#4C5968",
        fontSize: ifWeb(16, 12),
        fontFamily: "Roboto",
        height: 40,
        borderTopWidth: 0,
    },
    loginForm: {
        alignSelf: "center",
        width: ifSmall(275, 325),        
    },
    inputLbl: {
        color: "white",
        fontSize: ifWeb(14, 12),
        fontFamily: "Roboto-Medium",
        marginTop: 13,
        marginBottom: 5
    },
    eye: {
        color: '#999', 
        paddingTop: 3
    },
    btn: {
        marginTop: 0,
        backgroundColor: "#12B2FE",
        width: ifSmall(275, 325),
        height: 50,
        borderRadius: 5,
        alignSelf: "center",
        shadowColor: "black",
        shadowOffset: {width: 0, height: 2},
        shadowOpacity: .3,
        shadowRadius: 2,
    },
    btnTxt: {
        fontSize: ifWeb(17, 14),
        fontFamily: "Roboto-Medium",
    },

    linkBtn: {
        alignSelf: "center"
    },
    linkTxt: {
        color: 'white', 
        opacity: 0.8, 
        fontSize: 14,
        fontFamily: 'Roboto'
    },

    // version
    versionCt: {
        height: 45, 
        alignItems: "center",
        justifyContent: 'center',
    },
    versionTx: {
        fontFamily: "Roboto",
        color: "#FFFFFF", 
        fontSize: 10,
        opacity: .8
    },
})

const i2 = i;
export {s, i2 as i}