import { StyleSheet } from "react-native";
import cells from "../pieces/cells";
import { ifWeb } from "../../utils/utils";

const p:any = {
  ...cells,

  wrapper: {width: "100%", flexDirection: "row", marginTop: 15, borderBottomWidth: 0.3, borderColor: "#D9D5DC",  paddingBottom: ifWeb ? 15 : 0},
  webSelCt: {width: 300, marginLeft: 10},
  mobSelTriggerBtn: {},
  mobSelTriggerTxt: {
    // marginTop: -3,
    fontFamily: "Roboto",
    fontSize: 16,
    color: "#6A8287",
  },
  mobSelTriggerTxtAll: {
    // marginTop: -3,
    fontFamily: "Roboto",
    fontSize: 16,
    color: "#ccc",
  },
  label: {
    flex: 0, 
    width: 100, 
    // paddingLeft: 15, 
    paddingTop: ifWeb(8, 13), 
    color: "#909090", 
    fontSize: 16,
  },
  listTitle: {},
  listBody: {},
}

const s = StyleSheet.create(p)
export {s}
