export default class Timer {
  start: Date
  end?: Date

  constructor(){
    this.start = new Date()
  }

  show(){
    const end = this.end || new Date()
    const duration = end.getTime() - this.start.getTime()
    return duration + 'ms'
  }

  stop(){
    this.end = new Date()
  }
}

