// (weirdly) Required by react-navigation
global['__DEV__'] = false;
window['__DEV__'] = false;

import { ScrollView, Image } from "react-native";
import React from "react";
import { createStackNavigator } from '@react-navigation/stack';
import { createCompatNavigatorFactory } from '@react-navigation/compat';
import { NavigationContainer, useNavigation } from '@react-navigation/native';
import { View, Button, Text, Icon, Title, Spinner } from "native-base";
import { fadeIn } from "../shared/utils/transitions";
import { routeListLogged, routeListNotLogged } from "../shared/navigation/route-list";
import conn from "../shared/utils/conn";
import { i } from "../shared/theme/pieces/shared";
import { sL } from "../shared/theme/pieces/navWeb";
import { s } from "../shared/theme/pieces/menu";
import { addKey, blank, ci, ifWeb, loge } from "../shared/utils/utils";
import { Config, isDev } from "../shared/config";
import { prependMenuItems } from "../shared/dev-helpers/prepend-menu";
import { menuHandler } from "../shared/utils/menu-handler";
import { rmsg } from "../shared/utils/utils-view";
import { menuNavigate } from "../shared/navigation/menu-navigate";
import { AccountWarning } from "../shared/components/accont-warning";

const defNavConf:any = {
  transitionConfig: () => fadeIn(),
  navigationOptions: {
    gesturesEnabled: false, 
  }
}

const LoggedNav = createCompatNavigatorFactory(createStackNavigator)(
  routeListLogged,  
  {...defNavConf, initialRouteName: 'dummyScreen'},
)

interface State {
  menuOptions: any[]
  loading: boolean
}

class LoggedNavWrapper extends React.Component<{navigation: any}, State> {
  // static router = LoggedNav
  
  constructor(props){
    super(props)
    this.state = {
      menuOptions: [],
      loading: false,
    }
  }
  
  componentDidMount(){
    this.updateMenu()
    ci(this.props.navigation.state)
  }

  updateMenu = () => {
    this.setState({loading: true})
    conn.fetch('users/menu?version=2', 'GET')
      .then(resp => {
        if (!resp || blank(resp.items)) {
          rmsg((resp && resp.msg) || "Erro ao carregar menu.")
          return
        }
        let items = resp.items
        if (isDev) prependMenuItems(items)
        this.setState({loading: false, menuOptions: items})
      })
      .catch(e => {
        loge(e)
        rmsg("Erro ao carregar menu.")
      })
  }

  exit = () => {
    conn.delUser()
      .then(() => {
        this.props.navigation.popToTop()
        this.props.navigation.goBack()
			})
  }

  render() {
    const { menuOptions, loading } = this.state
    return (
      <ScrollView>
      <AccountWarning navigation={this.props.navigation} />
      <View style={sL.container}>
        {/* menu */}
        <View style={s.m_cont_web}>

          {/* header */}
          <View style={sL.menuHeader}>
            <Image source={ifWeb({uri: '/static/img/logo.png'}, i.logo)} style={sL.logo}/>
						<Title style={sL.logoName}>Leadfy</Title>
          </View>

          {/* items */}
          <View style={sL.menuItemsCt}>
            {/* Loading */}
            {(loading || blank(menuOptions)) &&
            <View style={{width: 150}}>
              <Spinner color="#ccc" size="small"></Spinner>
            </View>
            
            // Show
            ||
            <>
              {menuOptions.map(addKey).map((item) => 
                <View key={item.key} style={[s.m_btnCt_web, item.bottom_space ? {marginBottom: 20} : {}]}>
                  <View style={{width: 23}}>
                    {item.icon_name && <Icon style={s.m_btnIco} name={item.icon_name} type={item.icon_type} />}
                  </View>
                  <Button transparent onPress={() => menuNavigate(this, item)} style={s.m_btn}>
                    <Text style={s.m_btnTxt}>{item.text}</Text>
                  </Button>
                </View>)}
            </>}
          </View>

        </View>

        {/* content */}
        <View style={sL.contentCt}>
          <LoggedNav />
        </View>

      </View>
      </ScrollView>
    )
  }
}

const NavWeb0 = createCompatNavigatorFactory(createStackNavigator)(
  {...routeListNotLogged, loggedNavWrapper: LoggedNavWrapper},
  {...defNavConf, initialRouteName: 'signinScreen'},
)

export const NavWeb = (props) => {
  return (
    <NavigationContainer
      documentTitle={{formatter: (options, currentRoute) => {
        // ci(options, currentRoute)
        return Config.documentTitle
      }}}
      ref={(nav) => menuHandler.nav = nav} 
      onStateChange={props.onNavigationStateChange} >
      <NavWeb0 />
    </NavigationContainer>
  );
}

