import { View, Text, Button } from "native-base";
import React from "react";
import { s } from "../../../theme/screens/leadNew";
import { TextInput, StyleSheet } from "react-native";
import { FieldProps } from "./_shared_props";
import { TextIcons } from "../../../components/text-with-icons";
import deepmerge from "deepmerge";
import { ci } from "../../../utils/utils";
import { RFUtil } from "../rf-util";

interface State {
  value: string
  isOpen: any
}

export class Ftextarea extends React.Component<FieldProps, State> {
  constructor(props){
    super(props)
    this.state = {
      isOpen: props.field.startOpen === undefined ? true : props.field.startOpen,
      value: props.iniVal
    }
  }

  onChange = (v) => {
    this.setState(prevState => {
      const newState = {...prevState}
      newState.value = v
      return newState
    }, () => {
      this.props.onChange(v)
    })
  }

  toggleVisibility = () => {
    this.setState(prevState => {
      const newState = {...prevState}
      newState.isOpen = !newState.isOpen
      return newState
    })
  }

  mkCloseable = (field) => {
    return field.closeable
      ? deepmerge(defaultCloseable, field.closeable)
      : null
  }

  render() {
    const { field } = this.props
    const { value, isOpen } = this.state
    const help = RFUtil.help(field)
    const closeable: any = this.mkCloseable(field)
    let open = (isOpen && closeable) ? 'open' : 'close'
    return (
      <View style={{marginBottom: 15}}>
        {closeable &&
          <Button transparent style={{alignSelf: 'center', marginBottom: -15}} onPress={() => this.toggleVisibility()} testID='textAreaBtn'>
            <TextIcons text={closeable[open].text} 
                       iconLeft={closeable[open].icon_left} 
                       iconRight={closeable[open].icon_right}/>
          </Button>
        }
        {open == 'open' &&
          <>
            <TextInput 
              multiline={true} autoCapitalize='sentences' autoCorrect={true} numberOfLines={field.number_of_lines || 10}
              placeholder={field.placeHolder} placeholderTextColor='#bbb' 
              ref={r => this.props.setRef && this.props.setRef(r)} editable={field.editable} testID={field.name}
              style={StyleSheet.flatten([s.textArea, field.style])} onChangeText={this.onChange} value={value} />

            {field.help_link && 
              <Button transparent style={s2.helpLinkCt} onPress={() => this.props.navigate(field.help_link)}>
                <Text style={s2.helpLinkTxt}>{field.help_link.text}</Text></Button>}

            {help && 
              <View style={s.helpCt}>
                <Text style={s.help}>{help}</Text></View>}
          </>
        }
        {!closeable &&
           <>
           <TextInput 
             multiline={true} autoCapitalize='sentences' autoCorrect={true} numberOfLines={field.number_of_lines || 10}
             placeholder={field.placeHolder} placeholderTextColor='#bbb' 
             ref={r => this.props.setRef && this.props.setRef(r)} editable={field.editable} testID={field.name}
             style={StyleSheet.flatten([s.textArea, field.style, {textAlignVertical: "top"}])} onChangeText={this.onChange} value={value} />

           {field.help_link && 
             <Button transparent style={s2.helpLinkCt} onPress={() => this.props.navigate(field.help_link)}>
               <Text style={s2.helpLinkTxt}>{field.help_link.text}</Text></Button>}

           {help && 
             <View style={s.helpCt}>
               <Text style={s.help}>{help}</Text></View>}
         </>
        }
      </View>
    )
  }
}

const s2 = StyleSheet.create({
  helpLinkCt: {justifyContent: 'center', width: '100%'},
  helpLinkTxt: {fontSize: 13},
})

// defaults


const defaultCloseable = {
  "close": {
    "text": {
      "content": "Fechar",
      style: {
        fontSize: 13,
      }
    },
    "icon_left": {
      "name": "plus",
      "type": "Feather",
      style: {
        fontSize: 13,
        alignSelf: 'center'
      }
    }
  },
  "open": {
    "text": {
      "content": "Abrir",
      style: {
        fontSize: 13,
      }
    },
    "icon_left": {
      "name": "minus",
      "type": "Feather",
      style: {
        fontSize: 13,
        alignSelf: 'center'
      }
    }
  }
}
