import { Input, Item, Label, View, Text, Icon } from "native-base";
import React from "react";
import { s } from "../../../theme/screens/leadNew";
import { isMob, ci, ifNullOrUndef, sflat, isWeb } from "../../../utils/utils";
import { StyleSheet, Keyboard } from "react-native";
import { FieldProps } from "./_shared_props";
import { RFUtil } from "../rf-util";

interface State {
  value: string
  showPass: boolean
}

export class Ftxt2 extends React.Component<FieldProps, State> {
  constructor(props){
    super(props)
    this.state = {
      value: props.iniVal,
      showPass: false,
    }
  }

  onChange = (v) => {
    this.setState(prevState => {
      const newState = {...prevState}
      newState.value = v
      return newState
    }, () => {
      this.props.onChange(v)
    })
  }

  showPassTapped = () => {
    this.setState({showPass: !this.state.showPass})
  }

  render() {
    const { value } = this.state
    const { field } = this.props
    const help = RFUtil.help(field)

    const itemStyle = StyleSheet.flatten([
      s.formItem, 
      {borderBottomWidth: help ? 0 : StyleSheet.hairlineWidth},
      field.underLineWidth
    ])

    return (
      <View style={sflat([field.ct_style])}>
        <View style={ field.closeable ? {flexDirection: "row"} : [field.ct_txt_stle, {flexDirection: "column"}] }>
          <Item floatingLabel={field.disable_floating_label ? false : isMob} 
            style={sflat([itemStyle, field.txtCt_style, {flex: 2}])}>

          <Label style={sflat([s.label, field.lab_style])}>{field.label}</Label>

          <Input
            onChangeText={this.onChange}
            selectTextOnFocus={true}
            placeholder={field.placeholder}
            placeholderTextColor='#bbb'
            autoCapitalize={field.auto_capitalize}
            secureTextEntry={field.closeable ? !this.state.showPass : this.state.showPass}
            // secureTextEntry={field.secure_text_entry}
            caretHidden={false}
            disabled={field.disabled}
            value={ifNullOrUndef(value, '')}
            style={sflat([field.input_style, {paddingRight: 20}])}
            multiline={isWeb ? false : true}
            testID={this.props.field.name}
          />
          </Item>
          <View>
            {field.closeable &&
              <View>
                {this.state.showPass &&
                  <Icon name='md-eye-off' onPress={this.showPassTapped} 
                    style={sflat([isMob? [s.pass_mob_btn_style, {color: "#40879f"}] : [s.pass_web_btn_style, {color: "#40879f"}]])}/>}
                {!this.state.showPass &&
                  <Icon name='md-eye' onPress={this.showPassTapped} 
                    style={sflat([isMob? [s.pass_mob_btn_style, {color: "#bbb"}] : [s.pass_web_btn_style, {color: "#bbb"}]])}/>}
              </View>}
          </View>
        </View>
        {help &&
          <View style={s.helpCt} testID={"help." + field.label}>
          <Text style={s.help}>{help}</Text></View>}
      </View>
    )
  }
}