import { Button, Text, View, Icon, Input, Left, Body, List, ListItem } from "native-base";
import { contains } from "ramda";
import React from "react";
import { s } from "../../../theme/screens/forward";
import { addKey, calert, ci, sflat, simpleCalert } from "../../../utils/utils";

interface Props {
  field: any
  onChange: Function
  iniVal?: any
  setRef?: Function
  onBlur?: Function
  onFocus?: Function
  navigate?: Function
  changeTitle?: Function
}

interface State {
  list: any
  idSelecteds: Array<any> 
}

export class Flistselect extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      list: [],
      idSelecteds: []
    }
  }

  componentDidMount = () => {
    if (!this.props.iniVal) {
      this.setState({list: this.props.field.list})
      return
    }

    const newList = this.props.field.list.map(item => {
      return {...item, selected: contains(item.id, this.props.iniVal)}
    })

    this.setState({
      list: newList,
      idSelecteds: this.props.iniVal
    })
  }

  cellSt = (item: any) => {
    return null
  }

  itemTapped = (itemId: any) => {
    this.setState((oldState) => {
      const newState = {...oldState}
      const item = newState.list.find(
        obj => obj.id == itemId)
      item.selected = !item.selected

      const itemIdIndex = newState.idSelecteds.findIndex(
        id => id == itemId)

      if (itemIdIndex == -1) 
        newState.idSelecteds.push(itemId)
      else 
        newState.idSelecteds.splice(itemIdIndex, 1)

      return newState
    }, () => this.props.onChange(this.state.idSelecteds))
  }

  getStyleIconCheck = (item: any) => item.selected ? {color: 'green'} : {color: '#ccc'}
  getIconCheck = (item: any) => item.selected ? "checkbox-marked-outline" : "checkbox-blank-outline"

  render() {
    const { field } = this.props
    return (
      <View>
        <List dataArray={this.state.list} 
          style={[s.flatList, {marginBottom: 60, marginTop: 10}, field.style_list]} 
          renderRow={item =>
            <ListItem onPress={() => this.itemTapped(item.id)} style={sflat(s.listCell, this.cellSt(item))} key={item.id} underlayColor="transparent" {...field.list_item_extra_props} testID={item.name}>
              <Icon type={"MaterialCommunityIcons"}
                name={this.getIconCheck(item)} style={sflat({fontSize: 25}, this.getStyleIconCheck(item))} testID='checkbox'/>              
              <Body style={s.listBody} >
                <Text numberOfLines={1} style={sflat(s.listTitle, field.style_name)}>{item.name}</Text></Body>
            </ListItem>}
        />
      </View>
    )
  }
}
