import conn from "./conn";
import { hash2params, ci } from './utils';

export class DatasourceList {
  reloadSingleId: number|null
  filters: any
  list: any[]
  total: number
  page: number
  conf: any
  funcMap: any

  constructor(conf:any = {}){
    this.reloadSingleId = null
    this.list = []
    this.total = -1
    this.page = 1
    this.conf = conf
    this.filters = conf.initialFilterState || {}
  }

  // public

  setOnEvents = (funcMap) => {
    this.funcMap = funcMap
  }
  
  unsetOnEvents = () => {
    for (let key in this.funcMap)
      delete this.funcMap[key]
  }

  reset = () => {
    this.filter(this.conf.initialFilterState)
  }

  setOnSingleEvent = (funcName: string, func: Function) => {
    this.funcMap[funcName] = func
  }

  unsetOnSingleEvent = (funcName: string) => {
    if (this.funcMap[funcName])
      delete this.funcMap[funcName]
  }

  hasMore = () => {
    return this.total > this.list.length
  }

  loadMore = () => {
    this.page = this.page + 1
    this.load()
  } 

  refresh = () => {
    this.page = 1
    this.load()
    if (this.funcMap.onRefresh)
      this.funcMap.onRefresh()
  }

  filter = (filters: any) => {
    this.page = 1
    this.filters = filters
    this.load()
    if (this.funcMap.onFilter)
      this.funcMap.onFilter()
  }

  updateItem = (itemId: number) => {
    this.reloadSingleId = itemId
    this.load()
  }

  load = () => {
    conn.fetch(this.url(), 'GET')
      .then(this.loadSuccess)
      .catch(this.loadError)
  }

  // private

  private loadSuccess = (resp) => {
    let { newTotal, newItems, filter_options } = this.conf.parseResp(resp)
    const list = this.list.map(i => {
      return {...i}
    })

    // reloading 1 single item
    if (this.reloadSingleId) {
      const itemIdx = list.findIndex(item => 
        item.id == this.reloadSingleId)

      const refreshedItemIdx = newItems.findIndex(item => 
        item.id == this.reloadSingleId)

      // update
      if (refreshedItemIdx != -1) { 
        list[itemIdx] = newItems[refreshedItemIdx]  
      // remove
      } else { 
        list.splice(itemIdx, 1)
        this.total -= 1
      }

    // reloading many items
    } else { 
      this.total = newTotal
      if(this.page == 1)
        list.splice(0);
      [].push.apply(list, newItems)
    }

    this.reloadSingleId = null
    this.list = list

    if (this.funcMap.onLoadSuccess)
      this.funcMap.onLoadSuccess(list, filter_options)
    if (this.funcMap.resultReport)
      this.funcMap.resultReport(newTotal)
    if (this.conf.onExtraAlert && resp.extra && resp.extra.alert)
      this.conf.onExtraAlert(resp.extra.alert)
    if (this.conf.onExtraData && resp.extra && resp.extra.data)
      this.conf.onExtraData(resp.extra.data)
  }

  private loadError = (error) => {
    this.reloadSingleId = null
    if (this.funcMap.onLoadError)
      this.funcMap.onLoadError(error)
    if (this.funcMap.resultReport)
      this.funcMap.resultReport(0)
  }

  // build url

  private params = () => {
    let p = {}

    p['leadfy_production'] = true
    p['filters_v2'] = true

    if (this.conf.perpage)
      p['perpage'] = this.conf.perpage
    
    if (this.reloadSingleId)
      p[this.conf.reloadSingleIdParam] = String(this.reloadSingleId)
    else
      p['page'] = this.page

    p = {...p, ...this.filters}
    return p
  }

  private url = () => {
    let url = this.conf.baseUrl

    let params = hash2params(this.params())
    if (params != "")
      url += '?' + params

    return url
  }
}
