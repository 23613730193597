class FormMemory {
  mem = {}

  get(key: string){
    return this.mem[key]
  }

  delete(key: string){
    if (this.mem[key])
      delete this.mem[key]
  }

  save(key: string, value: any){
    this.mem[key] = value
  }
}

export const formMemory = new FormMemory()
