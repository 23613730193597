import { Button, Text, View, Icon, List, ListItem, Item } from "native-base";
import React from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import { s } from "../../../theme/screens/comp-selc";
import s2 from "../../../theme/pieces/buttons";
import { sflat, fallbackArray, addKey, ci, blank, isWeb } from "../../../utils/utils";
import { memStore } from "../../../utils/mem-store";
import { Flistcell } from "../cells/flistcell";
import { FieldProps } from "./_shared_props";
import { TextInput } from "react-native-gesture-handler";
import DraggableFlatList, {RenderItemParams} from '../../../../plataform/draggable-flatlist';
import { getErrorMsg } from "../validations";
import { rmsg } from "../../../utils/utils-view";

interface State {
  showAll: boolean
  list: Array<any>
  text: any
  allowDrag: boolean
}

export class Flist extends React.Component<FieldProps, State> {
  constructor(props) {
    super(props)
    this.state = {
      text: '',
      list: [],
      showAll: false,
      allowDrag: false
    }
  }

  // todo: add key on each item that comes in this.props.iniVal
  componentDidMount = () => this.setState({list: this.props.iniVal || []})

  changeValue = (id: number, value: any, property: string) => {
    const newList = this.state.list.map((item) => {
      if (item.id == id)
        item[property] = value
      return {...item}
    })
    this.props.onChange([...newList])
  }

  onChange = (newList: any) => {
    this.props.onChange(newList)

    // changeTitle fires setState on formScreen, 
    // so we dont need to do it here

    // TODO refact state management for formScreen/remoteForm/fields:
    // the state should contain the form data and the fields only 
    // access it by props
    
    this.props.changeTitle({
      titleComplement: `(${newList.length})`
    })
  }

  removeItem = (item: any, btn: any) => {
    const indexItem = this.state.list.findIndex(
      i => i.id == item.id)

    if (indexItem != -1) {
      const list = [...this.state.list]
      list.splice(indexItem, 1)
      this.onChange(list)
    }
  }

  navigate = (item: any, btn?: any) => {
    this.props.navigate({
      ...item.navigate,
      key: item.navigate.to + '-' + Math.random() * 1000000
    })
  }

  toggleShowState = () => {
    this.setState((oldState) => {
      const newState = {...oldState}
      newState.showAll = !newState.showAll
      return newState
    })
  }

  goToPickup = () => {
    const list = [...this.state.list]

    const obj = {
      list: list,
      onSave: (newList) => this.onChange(newList)
    }

    this.props.navigate({
      to: 'listScreen',
      params: {
        listPickupSessionId: memStore.create(obj).id, 
        spec: this.props.field.add_btn.spec,
      },
      key: 'listScreen-' + Math.random() * 1000000,
    })
  }

  goToForm = () => {
    this.props.navigate({
      to: 'formScreen',
      params: {
        spec: this.props.field.add_btn.spec,
      },
      key: 'formScreen-' + Math.random() * 1000000,
    })
  }

  brushExtraBtnStyles = (btn) => {
    if (btn) {
      btn.style_ct = sflat([{marginTop: 30, marginLeft: 10}, btn.style_btn])
      btn.style_txt = sflat([s.btnTxtRed, {color: '#999'}, btn.style_txt])
    }
    return btn
  }

  buttons = (field) => {
    return (
      <View style={sflat(s.submitCt, {height: 80, paddingBottom: 20, flexDirection:'row', alignSelf: 'center'})}>
        {field.add_btn &&
          <Button style={sflat(s2.btn4, field.add_btn.style)} onPress={this.props.field.add_btn.goToForm ? this.goToForm : this.goToPickup} testID={field.add_btn.text}>
            <Text style={sflat(s2.btnTxtGreen)}>{field.add_btn.text || 'Adicionar'}</Text></Button>
        }
        {fallbackArray(field.extra_btns).map(this.brushExtraBtnStyles).map(btn =>
        <TouchableOpacity style={btn.style_ct} onPress={() => this.navigate(btn.action)}>
          <Text style={btn.style_txt}>{btn.text}</Text></TouchableOpacity>)}
      </View>
    )
  }

  renderInput = (field) => {
    return (
      <View style={{flexDirection: 'row', marginBottom: 30, alignSelf: 'center'}}>
        <TextInput style={{width: '70%', borderBottomWidth: .4, borderBottomColor: "#aaa"}} placeholder={field.inplace_placeholder} value={this.state.text} onChangeText={(text) => this.setState({text})}/>
        <Button transparent onPress={() => this.addItemList(this.state.text, field)}><Icon style={{color: "#999"}} name="check" type="Feather"/></Button>
      </View>
    )
  }

  addItemList = (text, field) => {
    if(field.is_black_list){
      if(this.isEmpty(text) == true){
        rmsg('Insira um Email ou Telefone.', {type: 'w'})
        return
      } 
      else if(this.phoneValidation(text) == true && this.emailValidation(text) == true){
        rmsg('Email ou Telefone inválido!', {type: 'e'})
        return
      }
    }

    const newState = this.state.list
    // todo: adjust id and key
    newState.push({
      "id": (Math.random() * 10000000),
      "name": `${text}`,
      "key": (Math.random() * 10000000),
    })
    this.setState({list: newState, text: ''})
  }

  isEmpty = (text) => {
    const emptyError = getErrorMsg({validation: {required: {msg_error: "empty"}}}, text)
    if(emptyError != null)
      return true
    return false
  }

  phoneValidation = (text) => {
    const phoneError = getErrorMsg({validation: {phone_number: {msg_error: "phone"}}}, text)
    if(phoneError != null)
      return true
    return false
  }

  emailValidation = (text) => {
    const emailError = getErrorMsg({validation: {email: {msg_error: "email"}}}, text)
    if(emailError != null)
      return true
    return false
  }

  updateList = () => {
    const newList = this.state.list
    this.props.onChange(newList)
  }

  showDrag = () => {
    if (this.state.list.length == 0) 
      return null

    return (
      this.state.allowDrag ?
        <Button style={style.changeDragBtn} onPress={() => this.setState({allowDrag: false})}><Icon style={style.allowDragIcon} name="swap-vertical" type="MaterialCommunityIcons"></Icon></Button>
        :
        <Button style={style.changeDragBtn} onPress={() => this.setState({allowDrag: true})}><Icon style={style.allowDragIcon} name="swap-vertical" type="MaterialCommunityIcons"></Icon></Button>
    )
  }

  render() {
    const { list, showAll } = this.state
    const { field } = this.props
    const max = (field.max_qtd_items || 10)
    const showNum = (showAll ? list.length : max)
    const buttons = this.buttons(field)
    const renderItem = ({ item, drag, isActive }: RenderItemParams<Item>) => {
      return (
        <TouchableOpacity
          onLongPress={() => this.state.allowDrag && drag()}
          disabled={isActive}
          style={style.listItemBtn}
          testID={item.name}
        >
          <Flistcell item={item} field={field} allowDrag={this.state.allowDrag}
            onPressBtn={(btn) => this[btn.action].call(this, item, btn)} 
            changeValue={(value, property) => this.changeValue(item.id, value, property)}
          />
        </TouchableOpacity>
      )
    }

    return (
      <>
        {buttons.props.children[0] != null &&
          buttons}

        <View style={style.headerContainer}>
          {(field.has_input && !blank(field.input_header) && !this.state.allowDrag) ?
            <View style={style.inputHeader}>
              <Text style={style.inputHeaderTxt}>{field.input_header}</Text></View>
            :
            <Text></Text>}

          {!isWeb && field.drag_list ? this.showDrag() : null}
        </View>
        
        {/* Itens */}
        {field.add_inplace &&
          this.renderInput(field)}
        <>
          {field.drag_list ?
            <DraggableFlatList
              data={this.state.list}
              onDragEnd={({ data }) => this.setState({list: data}, this.updateList)}
              keyExtractor={(item) => item.key}
              renderItem={renderItem}
              containerStyle={[s.flatList, {marginBottom: 0}]}
              testID="flist"
            />
            :
            <List
              dataArray={list.slice(0, showNum)}
              style={[s.flatList, {marginBottom: 0}]}
              testID="flist"
              renderRow={item =>
                <ListItem key={item.id.toString()} testID={item.name}>
                  <Flistcell item={item} field={field}
                    onPressBtn={(btn) => this[btn.action].call(this, item, btn)} 
                    changeValue={(value, property) => this.changeValue(item.id, value, property)} allowDrag={this.state.allowDrag}
                  />
                </ListItem>}
            />}

          {list.length > showNum &&
          <Button transparent onPress={() => this.toggleShowState()} testID='add_btn'>
            <Icon name={showAll ? "minus" : "plus"} type={"AntDesign"}
              style={{fontSize: 13, marginRight: 0, paddingRight: 4, color: "#999"}} />
            <Text style={{fontSize: 13, marginBottom: 4, paddingLeft: 0,color: "#999"}}>
              {showAll ? "ver menos" : "ver mais"} </Text></Button>}
        </>

        {(buttons.props.children[0] != null && showNum > max) 
          && buttons}
      </>
    )
  }
}

const style = StyleSheet.create({
  listItemBtn: {width: '96%', borderBottomWidth: 0.7, borderBottomColor: '#ccc', height: 70},
  allowDragIcon: {color: "#999", fontSize: 25},
  changeDragBtn: {backgroundColor: 'transparent', borderWidth: 0, elevation: 0, paddingRight: 15},
  headerContainer: {flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'},
  inputHeader: {marginLeft: 15, marginBottom: 5},
  inputHeaderTxt: {color: '#555'},
})