import { Body, Button, Header, Icon, Left, Right, Title, View } from "native-base";
import React from "react";
import { StatusBar, StyleSheet } from "react-native";
import { header } from "../theme/pieces/shared";
import { addKey, isMob, sflat } from "../utils/utils";
import { AccountWarning } from "../components/accont-warning";
const s = StyleSheet.create(header)

export interface GlobalHeaderProps {
  navigation: any
  // left:
  // null/false/undefined -> shows defaultLeft
  // [] -> shows nothing
  // [some,...] -> shows some,...
  left?: any[]|null|false 

  title?: string
  right?: any[]
  closeOrBack?: boolean
  onClose?: Function
}

interface State {}

export class GlobalHeader extends React.Component<GlobalHeaderProps, State> {
  closeOrBackTapped = () => {
    if (this.props.onClose) {
      this.props.onClose()
    } else {
      this.props.navigation.goBack()
    }
  }

  defaultLeft(){
    return (
      <Button transparent onPress={() => this.closeOrBackTapped()} style={s.h_left}>
        {this.props.closeOrBack &&
        <Icon name='md-close'      style={s.h_left_txt} /> ||
        <Icon name='md-arrow-back' style={s.h_left_txt} />}
      </Button>
    )
  }
    
  render() {
    const { left, title, right } = this.props
    return (
      <View>
        { isMob && <AccountWarning navigation={this.props.navigation} /> }
        <Header style={sflat(s.h_header, {paddingTop: 0, marginTop: 0})}>
          <StatusBar barStyle="light-content"/>
          <Left style={s.h_left_ct}>
            {left ? left.map(addKey) : this.defaultLeft()}</Left>
          <Body style={s.h_body_ct}>
            <Title style={s.h_title} testID={'Title ' + title}>{title}</Title></Body>
          <Right style={s.h_right_ct}>
            {right && right.map(addKey)}</Right>
        </Header>
      </View>
    )
  }
}

