import { Text, View } from "native-base";
import React from "react";
import { ScrollView, TouchableOpacity } from "react-native";
import { s } from "../theme/screens/leadList";
import { ci } from "../utils/utils";

export interface ListFilterProps {
  filterButtons: any[]
  filter: Function
  initialActiveFilter: string
}

interface State {
  activeFilter: string,
}

export class ListFilter extends React.Component<ListFilterProps, {}> {
  public readonly state: State = {
    activeFilter: '',
  }

  constructor(props){
    super(props)
    this.state.activeFilter = props.initialActiveFilter
  }

  filterTapped = (key) => {
    this.setState({activeFilter: key})
    
    let atts = {}
    const btn = this.props.filterButtons
      .find(i => i.key == key)
    // atts[btn.filters.section] = 'on'
    atts[btn.key] = 'on'

    this.props.filter(atts)
  }

  brushTab = (tab: any, idx: number) => {
    const af = this.state.activeFilter
    const active = (af === '' && idx === 0) || tab.key === af

    tab.style = active ? {
      btn: s.filterBtnActive,
      tit: s.filterTitleActive,
      sub: s.filterSubActive,
    }:{
      btn: s.filterBtn, 
      tit: s.filterTitle, 
      sub: s.filterSub,
    }

    return tab
  }

  render() {
    const items:any = (this.props.filterButtons || []).map((item, idx) => {
      return this.brushTab(item, idx)
    })

    return (
      <ScrollView horizontal style={s.filterCt} testID="list_filter_scroll">
        <View style={s.filterLeftPad} testID="list_filter"/>
          {items.map((item) =>
            <TouchableOpacity key={item.key} style={item.style.btn} delayPressIn={9999999999} delayLongPress={9999999999}
                              onPress={() => this.filterTapped(item.key)} testID={item.title}>
              <Text style={item.style.tit}>{item.title}</Text>
              <Text style={item.style.sub}>{item.total}</Text>
            </TouchableOpacity>)
          }
        <View style={s.filterRightPad} />
      </ScrollView>
    )
  }
}
