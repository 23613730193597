import { rmsg } from "../utils/utils-view";

export const menuNavigate = (caller: any, item: any) => {
  if (item.route.indexOf(':') == 0)
    handleFunc(caller, item)
  else
    handleNav(caller, item)
}

const handleFunc = (caller: any, item: any) => {
  const methodName = item.route.replace(/:/, '')
  if (caller && typeof caller[methodName] == 'function')
    caller[methodName].call()
  else
    rmsg("Falha ao executar ação.")
}

const handleNav = (caller: any, item: any) => {
  let nav = getNav(caller)

  if (!nav) {
    rmsg("Falha ao trocar de tela.")
    return
  }

  // https://reactnavigation.org/docs/navigation-actions/
  // https://reactnavigation.org/docs/stack-navigator#helpers
  nav.navigate('dummyScreen')
  nav.navigate(item.route, {spec: item.spec})
}

const getNav = (caller: any) => {
  if (caller && typeof caller.navigate == 'function')
    return caller

  else if (caller && 
           caller.props && 
           caller.props.navigation && 
           typeof caller.props.navigation.navigate == 'function')
    return caller.props.navigation
}
