import React, { FunctionComponent, ReactNode } from 'react';
import { Text, View } from 'native-base';
import { Image, TouchableOpacity, Linking, Dimensions } from 'react-native';
import { StyleSheet } from 'react-native';

import { i } from '../shared/theme/screens/login';
import { isAndroidBrowser, isIOSBrowser } from '../shared/utils/utils';

interface ILandingStores {
  children?: ReactNode
}

const fontFamily = {
  fontFamily: 'Arial',
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: 20,
    backgroundColor: '#4a9db1',
  },
  brandRow: {
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: '#4a9db1'
  },
  logo: {
    width: 40,
    height: 40,
  },
  brandName: {
    ...fontFamily,
    fontWeight: '500',
    color: '#fff',
    fontSize: 20,
    marginTop: 8,
    marginLeft: 10,
  },
  message: {
    ...fontFamily,
    fontSize: 16,
    fontWeight: '300',
    textAlign: 'center',
    color: 'white',
    paddingHorizontal: '8%',
    paddingTop: (Dimensions.get("window").width / 2) - 40,
  },

  badgeWrapper: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  googlePlayBadge: {
    marginTop: 10,
    width: 250,
    height: 100,
  },
  appleStoreBadge: {
    marginTop: 20,
    width: 220,
    height: 70,
  }
});

const GOOGLE_PLAY_URL = 'https://play.google.com/store/apps/details?id=app.leadfy.leadfy';
const APPLE_STORE_URL = 'https://apps.apple.com/us/app/leadfy/id1459063178';

const openURL:any = async (url:string) => {
  try {
    await Linking.canOpenURL(url);
    Linking.openURL(url);
  } catch (error) {
    throw new Error('Erro ao abrir link');
  }
}

const LandingStores:FunctionComponent = (props:ILandingStores) => {
  const openGooglePlay = () => openURL(GOOGLE_PLAY_URL);
  const openAppleStore = () => openURL(APPLE_STORE_URL);
  return (
    <View style={styles.container}>
      <View style={styles.brandRow}>
        <Image source={{ uri: '/static/img/logo.png' }} style={styles.logo} />
        <Text style={styles.brandName}>Leadfy</Text>
      </View>
      <Text style={styles.message}>Para acessar o Leadfy pelo celular, instale o applicativo clicando no link abaixo:</Text>
      <View style={styles.badgeWrapper}>
        {isAndroidBrowser ? (
          <TouchableOpacity onPress={openGooglePlay}>
            <Image source={{ uri: '/static/img/google-play-badge@2x.png' }} style={styles.googlePlayBadge} />
          </TouchableOpacity>
        ) : <></>}
        {isIOSBrowser ? (
          <TouchableOpacity onPress={openAppleStore}>
            <Image source={{ uri: '/static/img/app-store@2x.png' }} style={styles.appleStoreBadge} />
          </TouchableOpacity>
        ) : <></>}
      </View>
    </View>
  );
}

export default LandingStores;