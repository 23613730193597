import { StyleSheet } from "react-native";
import {i} from "./shared"
import { ifSmall, ifWeb } from "../../utils/utils";

const s = StyleSheet.create({
  footerTab: {
    backgroundColor: 'white',
    ...ifWeb({
      width: 400,
      maxHeight: 60,
      alignSelf: "center",
      marginBottom: 20,
      paddingTop: 20,
      paddingBottom: 20,
    }, {})
  },
  icon: {
    color: "#12B2FE"
  },
  tabBtnDisabled: {
    color: '#bbb',
  },
  iconTxt: {
    color: "#12B2FE",
    fontSize: ifWeb(13, ifSmall(9, 11)),
    fontFamily: 'Roboto-Medium',
  }
})

const i2 = i
export { s, i2 as i }

