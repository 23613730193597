import { Dimensions, StyleSheet } from "react-native";
var { width, height } = Dimensions.get('window');

const slAtts:any = {
  container: {
    flex: 1, 
    flexDirection: 'row', 
    paddingLeft: width >= 1440 ? 250 : 130, 
    paddingRight: width >= 1440 ? 250 : 130,
    minHeight: height
    
  }, 
  menuCt: {
    width: 200, 
    flex: 0
  },
  btnCt: {},
  btn: {},
  btnTxt: {},
  contentCt: {
    flex: 1,
    shadowRadius: 5,
    shadowColor: "#000",
    shadowOffset: {width: 0, height: 0},
    shadowOpacity: 0.3,
  },
  logo: {
    marginLeft: 15,
    width: 30,
    height: 30,        
  },
  logoName: {
    marginLeft: 15,
    color: "white", 
    fontSize: 18, 
    fontFamily: "Roboto-Medium",
  },
  menuHeader: {
    backgroundColor: '#2C86A2', 
    height: 56, 
    flexDirection: "row",
    alignItems: "center",
  },
  menuItemsCt: {
    padding: 20,
  },
}

const sL = StyleSheet.create(slAtts)

export { sL }

