import { ButterToast } from "../components/butter-toast"
import { calert, ci } from "./utils"

export const rmsg = (msg: any, opt: any = {}) => {
  if (!msg)
    return

  if (typeof opt == 'string') // rmsg('ops omg!', 'e')
    opt = {type: opt}

  if (typeof msg == 'object') { // rmsg({msg: 'ops omg!', type: 'e'})
    opt = msg
    msg = opt.msg
  }

  if (!opt.view) 
    opt.view = 'toast'

  switch (opt.type) {
    case 'e':
      opt.type = 'danger'
      break
    case 'w':
      opt.type = 'warning'
      break
    case 's':
      opt.type = 'success'
      break
    default:
      opt.type = ''
  }
    
  switch (opt.view) {
    case "alert":
      calert({text: msg, title: opt.title})
      break

    case "toast":
      if (opt.callback)
        console.warn("rmsg() of type 'toast' ignores opt.callback")
      new ButterToast().render(msg, opt)
  }
}

// simple call toast: {msg: 'asd', opt: {}}
// normal call toast: {msg: 'asd', opt: {type: 'w', view: 'toast'}}
// extreme call toast: {msg: 'asd', {opt: {type: 's', view: 'toast', duration: 7500, buttonText: 'Ok'}}}

// simple call alert: {msg: 'asd', opt: {view: 'alert'}}
// alert with title: {msg: 'asd', opt: {view: 'alert', title: 'qwe'}}