import moment from 'moment';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Config, isDev } from '../config';
import { loge, resp2h, isWeb, base64, base64Latin1, logMsg, isIos, ci } from './utils';
// import { reactotron } from '../../plataform/reactotron';
import Timer from './timer';
const CryptoJS = require("crypto-js");
import Sentry from '../../plataform/sentry';
import { getRemoteAnswer } from '../dev-helpers/remote-backend'
import { delay } from './delay';
import pubSub from './pub-sub';
// import { rmsg } from './utils-view';

class Conn {
  userStorageKey = 'user'
  pickupUsersStorageKey = 'pickup-users'
  user: any = null
  baseUrl: string | null = null

  constructor() {
  }

  configure(baseUrl: string) {
    this.baseUrl = baseUrl
  }

  // Net

  async fetch(path: string, verb = 'GET', params?: any, signal?: any) {
    const req: any = {}

    // if (isDev) {
      const answer = getRemoteAnswer(path, verb, params)
      if (answer) {
        await delay(500 + 500*Math.random())
        return answer
      }
    // }
    req.headers = await this.defineHeaders(verb, path)

    req.opt = { method: verb, headers: req.headers }
    if (signal) req.opt['signal'] = signal;

    if (params) {
      const isFormData = params instanceof FormData;
      req.opt['body'] = isFormData ? params : JSON.stringify(params)
      if (isFormData)
        req.opt.headers['Content-Type'] = 'multipart/form-data;'
    }
    // console.log("HEADERS", req.opt.headers['Content-Type'])

    req.url = this.baseUrl + path

    try {
      req.timer = new Timer()
      req.response = await fetch(req.url, req.opt)
      // const j = await req.response.text()
      // console.log(j)
      // JSON.parse(j)
      req.timer.stop()
      req.responseJson = await req.response.json()
      this.onFetch(req)

    } catch (error) {
      // Sentry.captureException(error)
      // logMsg(`conn.fetch: ${error.message}`)
      console.error(error)

      this.onFetch(req, error)
      throw error
    }

    return req.responseJson
  }

  onFetch(req, error?) {
    if (req.responseJson && !req.responseJson.success && req.responseJson.action == 'logout') {
      pubSub.trigger('logout', {msg: req.responseJson.msg}, true)
      return
    }

    if (req.responseJson && req.responseJson.account_warning) {
      let aw = req.responseJson.account_warning
      if (aw.status == 'ok')
        aw = null
      pubSub.trigger('account_warning', aw, true)
    }

    try {
      if (req.responseJson && req.responseJson.update_user_session) {
        this.setUser(req.responseJson.update_user_session)
      }
    } catch (error) {
      logMsg(`conn.onFetch setUser: ${error.message}`)
    }

    if (!isDev)
      return

    const h = {
      url: req.url,
      opt: req.opt,
      response: resp2h(req.response),
      responseJson: req.responseJson,
      duration: req.timer.show(),
    }

    if (error) {
      h['error'] = error
      console.error(h)
    }
    
    // reactotron.display({
    //   name: 'fetch',
    //   value: h,
    //   preview: h.url,
    //   important: false,
    // })
  }

  // Auth

  async defineHeaders(method, route) {
    var dateStr = moment().format("D/MM/Y HH:mm:ss");
    var randStr = Math.random().toString().substr(11);
    var payload = this.authPayload(method.toLocaleUpperCase(), route, dateStr, randStr);

    var headers = {
      'Access-Control-Allow-Origin': '*',
      'X-Frame-Options': 'SAMEORIGIN',

      'Cache-Control': 'max-age=0, private, must-revalidate, public',

      // 'X-Content-Type-Options': 'nosniff',
      'Accept': 'application/json; charset=utf-8',
      'Content-Type': 'application/json; charset=utf-8',

      'X-App-Version': Config.appVersion,
      'X-App-Env': isDev ? 'dev' : 'prod',
      'X-App-Device': isWeb ? 'browser' : (isIos ? 'ios' : 'android'),

      'X-Date': dateStr,
      'X-Rand': randStr,
      'X-App': Config.apiId + ':' + this.signature(payload, Config.apiKey),

      'X-SES-KS': this.sesKs(),
      'X-Payload': payload, // uncomment when debugging
    };

    const user = await this.getUser();
    if (user)
      headers['X-Auth'] = user.id + ':' + this.signature(payload, user.token);

    const basicAuth = this.getBasicAuth()
    if (basicAuth)
      headers[basicAuth[0]] = basicAuth[1]

    return headers
  }

  sesKs = () => {
    if (!this.user)
      return 'nu'
    try {
      return base64Latin1(
        Object.keys(this.user).join(',')
      )
    } catch (error) {
      loge(error)
      return 'er'
    }
  }

  getBasicAuth = () => {
    if (!Config.apiBasicAuth)
      return null
    return ['Authorization', 'Basic ' + base64Latin1(Config.apiBasicAuth)]
  }

  authPayload(method, route, dateStr, randStr) {
    return randStr +
      method +
      dateStr +
      "Z/" +
      Config.apiVersion +
      "/" +
      route.replace(/\?.*/, '');
  }

  signature(message, secret) {
    return base64(
      CryptoJS.HmacSHA256(message, secret)
    ).replace(/\s+/, "");
  }

  // Url auth

  async urlAuth() {
    const keys = [3638, 30947539450, 900]

    const user = await this.getUser();
    if (!user)
      return null

    const tokenStripped = user.token.replace(/^\s+|\s+$/g, '')

    let enc = CryptoJS.SHA1(
      String(user.id) +
      String(keys[1]) +
      tokenStripped
    )

    return enc + String(keys[2] + keys[0] * user.id)
  }

  // User

  safeUser = () => {
    try {
      if (this.user)
        return JSON.stringify(this.user)
      else
        return ''
    } catch (e) {
      loge(e)
      return ''
    }
  }

  async setUser(user: any, persist = true) {
    this.user = user
    this.onUserSet(user)
    let ok = true
    if (persist) {
      const ok = await this.setKeyValue(this.userStorageKey, user)
      if (ok) this.addPickupUser(user)
    }
    return ok
  }

  storConv(user: any) {
    if (!user)
      return user

    if (user.app_login_data) {
      user.sigmeta = user.app_login_data
      delete user.app_login_data
    }

    if (user.access_token) {
      user.token = user.access_token
      delete user.access_token
    }

    return user
  }

  async getUser(forceFromStore = false) {
    if (!this.user || forceFromStore) {
      const user = await this.getValueForKey(this.userStorageKey)
      if (user)
        this.setUser(this.storConv(user), false)
    }
    return this.user
  }

  async isLogged() {
    let logged = false
    await this.getUser().then(user => {
      logged = true
    })
    return logged
  }

  async delUser() {
    let ok = true
    this.signoutRemote()
    this.onUserUnset()
    this.user = null
    await AsyncStorage.removeItem(this.userStorageKey)
      .catch(err => {
        ok = false
        loge(err, "Error deleting user from storage")
      })
    return ok
  }
  
  async signoutRemote() {
    const deviceToken = await this.getValueForKey(Config.storeDeviceToken)
    this.fetch('signout', 'POST', { device_token: deviceToken })
      .then(resp => {
        if (!resp.success)
          logMsg(resp.errContent || 'Logout error')
      }).catch(error => {
        loge(error)
      })
  }

  onUserSet(user: any) {
    // Sentry
    Sentry.setUser({
      "id": user.id,
      "username": user.username,
      "email": user.email
    });
    Sentry.setTag("user_name", user.name);
    Sentry.setTag("is_admin", user.is_master);
    Sentry.setTag("company", user.group_name);
    Sentry.setTag("company_id", user.company_id);
    Sentry.setTag("company_name", user.company_name);
  }

  onUserUnset() {
    Sentry.setUser(null)
    Sentry.setTag("user_name", '');
    Sentry.setTag("is_admin", '');
    Sentry.setTag("company", '');
    Sentry.setTag("company_id", '');
    Sentry.setTag("company_name", '');
  }

  // PickupUsers

  async clearPickupUsers() {
    this.setKeyValue(this.pickupUsersStorageKey, [])
  }

  async addPickupUser(user: any) {
    let pickupUsers = await this.getPickupUsers() || []
    let newPickupUsers: any[] = []

    newPickupUsers.push(user)
    pickupUsers.forEach(savedUser => {
      if (savedUser.id != user.id)
        newPickupUsers.push(savedUser)
    });

    this.setKeyValue(this.pickupUsersStorageKey, newPickupUsers)
  }

  async getPickupUsers() {
    let pickupUsers = await this.getValueForKey(this.pickupUsersStorageKey)
    return pickupUsers || []
  }

  // Swtch user

  async switchUser(toSellerId) {
    return this.getPickupUsers()
      .then(sellers => {
        const seller = sellers.find(i => i.id == toSellerId)
        if (seller) {
          this.setUser(seller, false)
          return Promise.resolve(true)
        } else
          return Promise.resolve(false)
      })
  }

  async revertToStoredUserSession() {
    await this.getUser(true)
    return Promise.resolve()
  }

  // Util

  async setKeyValue(key: string, value: any) {
    let ok = true
    await AsyncStorage.setItem(key, JSON.stringify(value)).catch(err => {
      ok = false
      loge(err, `Error saving key '${key}' to storage`)
    })
    return ok
  }

  async getValueForKey(key: string) {
    let retValue: any

    await AsyncStorage.getItem(key)
      .then(value => {
        retValue = value ? JSON.parse(value) : null
      })
      .catch(err => {
        if (err.name != 'NotFoundError' && err.name != 'ExpiredError')
          loge(err, `Error fetching value for key '${key}' from storage`)
      })

    // TODO remove when no customer have old session anymore (around mid 2021)
    // Coaslesce old storage format, when it used rawData as top key
    if (retValue && 
        typeof(retValue) == 'object' &&
        retValue.rawData !== undefined) {
      retValue = retValue.rawData
    }

    return retValue
  }
}

export default new Conn()
