import React from "react";

export interface LeadChatProps {
  navigation: any,
}

interface State {
}

export class LeadChatScreen extends React.Component<LeadChatProps, State> {
}
