
class PubSub {
  private callbacks = {}
  private state = {}

  trigger(event: string, payload: any, statefull: boolean = false) {
    if (this.callbacks[event])
      this.callbacks[event].forEach(callback => {
        try {
          callback(payload)
        } catch (error) {
          console.error(`Error in callback for event "${event}":`, error);
        }
      })
    if (statefull)
      this.state[event] = payload
  }

  subscribe(event: string, callback: Function) {
    this.callbacks[event] = this.callbacks[event] || []
    this.callbacks[event].push(callback)
    if (this.state.hasOwnProperty(event)) {
      callback(this.state[event])
    }
  }

  unsubscribe(event: string, func: Function) {
    if (this.callbacks[event]) {
      this.callbacks[event] = this.callbacks[event].filter(callback => callback !== func)
    }
  }
}

export default new PubSub()
