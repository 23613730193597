import { Button, Text, View, Icon, Input, Left, Body } from "native-base";
import React from "react";
import { s } from "../../../theme/screens/comp-selc"
import { sflat } from "../../../utils/utils";
import { StyleSheet } from "react-native"
import { TextIcons } from "../../../components/text-with-icons"
import { FieldProps } from "./_shared_props";

export class Ftextview extends React.Component<FieldProps, {}> {
  render() {
    const { field } = this.props
    return (
      <View style={sflat([s2.view, field.view_style])}>
        <TextIcons text={field.text}
          iconLeft={field.icon_left} iconRight={field.icon_right} iconIn={field.iconIn} iconInCt={field.iconInCt} />
      </View>
    )
  }
}

const s2 = StyleSheet.create({
  view: {
    marginTop: 10,
    marginBottom: 0,
  },

})