import Sentry from '../../../plataform/sentry';
import { Button, Form, Icon, Input, Item, Label, Spinner, Text, Title, View, Container } from "native-base";
import React from "react";
import { Image, ImageBackground, Keyboard, Platform, ScrollView, StatusBar, StyleSheet } from "react-native";
// import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { i, s } from "../../theme/screens/login";
import { anyBlank, simpleCalert, isSmall, validateEmail, ifWeb, isWeb, logScreen } from "../../utils/utils";

interface Props {navigation: any}

interface State {
	email: string,
	phone: string,
	name: string,
	numBrokers: string,
	fetching: boolean,
	isAttempting: boolean,
}

export class SignupScreen extends React.Component<Props, State> {
	nameRef: any|null = null
	phoneRef: any|null = null
	emailRef: any|null = null
	numBrokersRef: any|null = null
	submitBtn: any|null = null
	goSigninLink: any|null = null
	backBtnRef: any|null = null

	constructor (props) {
		super(props)
		this.state = {
			name: '',
			email: '',
			phone: '',
			numBrokers: '',
			fetching: false,
			isAttempting: false,
		}
	}

	componentDidMount(){
		Sentry.addBreadcrumb({message: `mounted SignupScreen`})
		logScreen('signup')
		// this.setTabIndex()
		// if (ifWeb && this.usernameRef)
		// 	this.usernameRef._root.focus()
	}

	// setTabIndex() {
	// 	let tabindex = 2 // 2 because when using 1 it clashes with some existing default elements  
	// 	"emailRef phoneRef nameRef numBrokersRef submitBtn goSigninLink backBtnRef".split(/\s+/).forEach(ref => {
	// 		if (this[ref]) {
	// 			this[ref]._root.setNativeProps({tabIndex: tabindex})
	// 			tabindex += 1
	// 		}
	// 	}) 	
	// }

	validate(){
		const { name, phone, numBrokers, email } = this.state

		if (anyBlank(name, phone, numBrokers, email))
			return simpleCalert("Preencha todos os campos.")
		
		if (!validateEmail(email)) 
			return simpleCalert(`Email inválido.`)

		const phoneLen = phone.replace(/\D/, '').length
		if (!phone.match(/^[\d\+\.\(\)\s\-\/]+$/) || phoneLen < 10 || phoneLen > 15)
			return simpleCalert("Celular inválido. Preencha com seu celular, incluindo o DDD.")

		if (!numBrokers.match(/^\d+$/)) 
			return simpleCalert("O campo Número de Corretores deve conter apenas números.")

		return true
	}

	signup() {
		if (!this.validate()) 
			return
		
		this.setState({isAttempting: true})
		const { name, phone, numBrokers, email } = this.state

		let formData = new FormData()
		formData.append('name', name)
		formData.append('phone', phone)
		formData.append('email', email)
		formData.append('num_brokers', numBrokers)

		fetch("https://www.leadfy.com.br/cadastro/leadfy", {
			body: formData,
			method: "POST",
			mode: 'cors',
		})
		.then(resp => resp.json())
		.then(resp => {
			resp.success ?
				this.onSuccess(resp) :
				this.onError(new Error(resp.errContent || "tente novamente."))
		}).catch(this.onError)
	}

	onSuccess = (resp) => {
		const stateChanges = {
			isAttempting: false,
			name: '',
			phone: '',
			numBrokers: '',
			email: '',
		}
		this.setState(stateChanges, () => {
			setTimeout(() => {
				simpleCalert(resp.msg || "Obrigado! Seu cadastro foi realizado com sucesso. Entraremos em contato em até 24h para fazer a configuração inicial da sua conta.")
				this.props.navigation.goBack()
			}, 20)
		})
	}

	onError = (msg) => {
		this.setState({isAttempting: false }, () => {
			setTimeout(() => {
				simpleCalert("Erro: " + msg)
			}, 20)
		})
	}

	fieldChanged = (field, text) => {
		const atts = {}
 		atts[field] = text.trim()
 		// atts[field] = atts[field].replace(/\.\s*$/, '')
    this.setState(atts)
  }
	
	onLastFieldBlur = () => {
		Keyboard.dismiss()
		if (anyBlank(this.state.name, this.state.phone, this.state.numBrokers, this.state.email))
			return
		setTimeout(() => {
			this.signup()
		}, 500)
	}

	disableTabNav = (component) => {
		if (component)
			component._root.setNativeProps({tabIndex: -1})
	}

	render () {
		const editable = !this.state.fetching
		let inputOpt = {}
		const asdd = <Icon name="warning" type="Ionicons" style={{color: '#DFC853', fontSize: 25,}}/>
		
		if (Platform.OS != 'web')
			inputOpt = {
				...inputOpt, spellCheck: false, returnKeyType: 'next', autoCapitalize: 'none', 
				autoCorrect: false, blurOnSubmit: false} // selectTextOnFocus: true, 

		return (
			<ImageBackground source={i.loginBg} style={s.bgImg}>
			{/* <KeyboardAwareScrollView keyboardShouldPersistTaps={'handled'}> */}
			<StatusBar barStyle="light-content"/>
			<Container style={StyleSheet.flatten([s.cnt, ifWeb({marginBottom: 20})])} testID='signupView'>

				<ScrollView>
					<View style={{position: 'absolute', zIndex: 100, top: 50, left: 0, marginLeft: 10}}>
						<Button transparent onPress={() => this.props.navigation.goBack()} ref={c => this.backBtnRef = c}>
							<Icon name='md-arrow-back' style={{color: 'white'}} />
						</Button>
					</View>
				
					<View style={s.login}>

						{/* logo */}
						<View>
							<Image source={ifWeb({uri: '/static/img/logo.png'}, i.logo)} style={s.logoSignup}/>
							<Title style={s.logoNameSignup}>Cadastrar</Title></View>
						
						<View style={{marginTop: 30, alignSelf: 'center', justifyContent: 'center'}}>
							<Text style={{backgroundColor: '#F3E7B9', padding: 15, borderRadius: 15, borderWidth: 3, borderColor: '#DFC853', color: 'black', width: 280, fontSize: 14, textAlign: 'justify'}}>
								{asdd} Se você esqueceu sua senha, entre em contato com seu gerente para recupera-lá.								
							</Text>
						</View>

						{/* form */}
						<View style={s.formSignup}>
						
							<Form style={s.loginForm}>

								{/* email */}
								<Label style={s.inputLbl}>*Email</Label>
								<Item regular style={s.formitem} ref={this.disableTabNav}>
									<Input style={s.input} editable={editable} 
										ref={c => this.emailRef = c}
										textContentType="emailAddress"
										keyboardType="email-address"
										onSubmitEditing={() => this.phoneRef._root.focus()}
										onChangeText={v => this.fieldChanged('email', v)} 
										autoFocus={isWeb} {...inputOpt}
										testID="emailSignup" /></Item>
								
								{/* phone */}
								<Label style={s.inputLbl}>*Celular</Label>
								<Item regular style={s.formitem} ref={this.disableTabNav}>
									<Input style={s.input} editable={editable} 
										ref={c => this.phoneRef = c}
										textContentType="telephoneNumber"
										keyboardType="phone-pad"
										onSubmitEditing={() => this.nameRef._root.focus()}
										onChangeText={v => this.fieldChanged('phone', v)} {...inputOpt}
										testID="phoneSignup" /></Item>

								{/* name */}
								<Label style={s.inputLbl}>*Nome</Label>
								<Item regular style={s.formitem} ref={this.disableTabNav}>
									<Input style={s.input} editable={editable} 
										ref={c => this.nameRef = c}
										textContentType="name"
										keyboardType="default"
										onSubmitEditing={() => this.numBrokersRef._root.focus()}
										onChangeText={v => this.fieldChanged('name', v)} {...inputOpt} autoCapitalize='words'
										testID="nameSignup" /></Item>

								{/* num_brokers */}
								<Label style={s.inputLbl}>*Número de corretores</Label>
								<Item regular style={s.formitem} ref={this.disableTabNav}>
									<Input style={s.input} editable={editable} 
										ref={c => this.numBrokersRef = c}
										keyboardType="numeric"
										onSubmitEditing={() => this.submitBtn._root.focus()}
										onChangeText={v => this.fieldChanged('numBrokers', v)} {...inputOpt}
										testID="numBrokerSignup" />							
								</Item>
							</Form>

							{/* button */}
							<View style={s.formSubmit}>
								<Button block onPress={() => this.signup()} style={s.btn} disabled={this.state.isAttempting} activeOpacity={1}
										ref={c => this.submitBtn = c} testID="submitSignupBtn">
									<Text style={s.btnTxt}>Enviar</Text>
									{this.state.isAttempting &&
										<Spinner color='white' size="small" />}</Button></View>

							{/* signup */}
							<View style={{marginTop: 10}}>
								<Button style={s.linkBtn} transparent onPress={() => this.props.navigation.goBack()} ref={c => this.goSigninLink = c} testID='alreadyRegistered'>
									<Text style={s.linkTxt}>Já tem cadastro?</Text>
								</Button>
							</View>
						</View>

					</View>

					{isSmall && 
					<View style={{height: 100}}></View>}
				</ScrollView>

			</Container>
			{/* </KeyboardAwareScrollView> */}
			</ImageBackground>
    )
  }
}
