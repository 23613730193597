
export class PushNotifRouter {

  registerOpenner = (notifOpenner: Function) => {
  }

  unRegisterOpenner = () => {
  }

  onAction = (notification) => {
  }

}

export const pushNotifRouter = new PushNotifRouter()