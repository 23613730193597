import Sentry from '../../plataform/sentry';
import { Button, Container, Content, Form, Text, View, Icon, List } from 'native-base';
import React from "react";
import { ActivityIndicator, StyleSheet, TouchableOpacity, FlatList } from 'react-native';
import { GlobalHeader } from '../pieces/global-header';
import { s } from "../theme/screens/leadNew";
import { addKey, isWeb, blank, sflat, ci, ifWeb, parseSpec, uuidv4, logScreen } from '../utils/utils';
import { RemoteForm } from '../lib/remote-form/remote-form';
import { menuHandler } from '../utils/menu-handler';
import { StackActions } from '@react-navigation/native';
import { menuNavigate } from '../navigation/menu-navigate';
import { SectionDefault } from "../lib/remote-form/sections/section-default";
import Spinner from 'react-native-loading-spinner-overlay';
// import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'


interface Props {navigation: any}

interface State {
  loading: boolean
  editable: boolean
  spec: any|null
  errorMsgs: Array<string>
}

export class FormScreen extends React.Component<Props, State> {
  remoteForm: RemoteForm

  static navigationOptions = {
    gesturesEnabled: false,
  }
  component: Content;
  
  public readonly state: State = {
    errorMsgs: [],
    loading: false,
    editable: true,
    spec: null,
  }

  // Preface

  constructor(props){
    super(props)
    this.remoteForm = new RemoteForm(
      this.setStateFromDs, 
      this.goBackFromDs, 
      this.navFromDs,
      this.menuNavigateFromDs
    )
  }

  menuNavigateFromDs = (route) => {
    menuNavigate(this.props.navigation, route)
  }

  componentDidMount(){
    Sentry.addBreadcrumb({message: `mounted ObjectEditScreen`})
    this.handleParams()
  }

  navParams(): any{
    return this.props.navigation.state.params
  }

  handleParams = () => {
    const np = this.navParams()
    let { spec, objectSessionId, callbackSessionId, listDsSessionId } = np
    spec = parseSpec(spec, np)
    logScreen('form', spec)
    this.remoteForm.setup({spec, objectSessionId, callbackSessionId, listDsSessionId})
  }

  setStateFromDs = (newState, callback?) => this.setState(newState, callback)

  // navFromDs = (nav) => {
  //   this.props.navigation.navigate(nav.to, nav.params)
  // }

  navFromDs = (nav) => {
    if (nav === "goBack") {
      this.props.navigation.goBack()
      return
    }

    if (nav.navigation_pop) {
      const popAction = StackActions.pop(nav.navigation_pop)
      this.props.navigation.dispatch(popAction)
      return
    } 

    let opt: any = {
      routeName: nav.to,
      params: nav.params,
    }

    if (nav.key)
      opt.key = nav.key

    this.props.navigation.navigate(opt)
  }

  toggleState = (section: any, state: string) => {
    this.setState((oldState) => {
      const fsSection = oldState.spec.sections.find(
        s => s.name == section.name
      )

      if (fsSection)
        fsSection[state] = !fsSection[state]

      return oldState
    })
  }

  getZIndexSection = (section: any) => {
    return section.on_focus ? 2 : 1
  }

  goBackFromDs = () => this.goBack()
  goBack = () => this.props.navigation.goBack()

  left = () => {
    if (this.state.spec && this.state.spec.back_btn) {
      return null
    }

    if (isWeb) {
      return []
    } else {
      // if (this.remoteForm.editingObject)
      return [
        <Button transparent style={s.h_left} onPress={menuHandler.open} testID='menuBtn'>
          <Icon style={s.h_btn_hamb} name='menu' /></Button>
      ]
    }
  }

  right = () => {
    if (!this.state.spec || !this.state.spec.save_btn_header) 
      return null
    const ts = this.state.spec.save_btn_header
    return [
      <Button transparent style={ts.style_btn} onPress={this.remoteForm.save} testID="save_btn_header">
        <Text style={sflat([s.h_btnTxtBold, ts.style_txt])}>{ts.txt || "Salvar"}</Text></Button>
    ]
  }

  scrollToBottom = () => {
    const screenHeight = window.innerHeight
    isWeb ? 
      [this.component['_root'].scrollToPosition(0, screenHeight), window.scrollTo(0 , screenHeight)]
      : 
      this.component['_root'].scrollToEnd()
    
  }

  render () {
    const { spec } = this.state
    let sbb: any
    let cb: any
    return (
      <Container style={s.contBg}>
        {/* Header */}
        <GlobalHeader
          title={spec ? spec.title : '...'}
          navigation={this.props.navigation}
          left={this.left()}
          right={this.right()} />
        
        {/* <KeyboardAwareScrollView> */}
          {/* Form */}
          {spec &&
          <Content 
            style={s.content}
            enableResetScrollToCoords={false}
            scrollEnabled={true}
            ref={c => (this.component = c)}
            testID={spec.title}>
            <View style={spec.form_style}>
              <Spinner
                visible={this.state.loading}
                // size prop is buggy:
                // Really valid: small or large (not normal!)
                // https://github.com/joinspontaneous/react-native-loading-spinner-overlay/issues/58
                // 
                size={'small'} 
                {...this.state.spec.spinner_props || {}}
              />
              <Form style={{zIndex: 1}}> 
                <View style={s.spacer15}></View>
                {spec.sections.map(addKey).map(section => 
                  <View key={section.key} style={{zIndex: this.getZIndexSection(section)}}>
                    <SectionDefault section={section} getField={this.remoteForm.getField}
                              toggleSectionHelp={() => this.toggleState(section, "helpActive")}
                              toggleVisibility={() => this.toggleState(section, "is_visible")}
                    />
                  </View>
                )}
              </Form>

              {/* Errors */}

              {this.state.errorMsgs.length > 0 &&
                <View style={s2.errorBoxCt} testID="error_content">
                  <FlatList
                    onContentSizeChange={()=> this.scrollToBottom()}
                    data={this.state.errorMsgs}
                    renderItem={({item}) => <Text key={uuidv4()} style={s2.errorBoxItem} testID={item}>-  {item}</Text>}
                    ListHeaderComponent={<Text style={{fontSize: 17}}>Corrija os seguintes erros:</Text>}
                    keyExtractor={(item, index) => index.toString()}
                  />
                </View>
              }

              <View style={{flexDirection: 'row', width: '100%', justifyContent: 'center', marginTop: -20, marginBottom: 15}}>

              {/* Submit */}
              {(sbb = spec.save_btn_bottom) &&
                <>
                  <View style={sflat([s2.saveBtn, sbb.style_ct, spec.btns_ct])} testID={sbb.txt || "Salvar"}>
                    <Button style={[s.btn, sbb.style_btn]} onPress={this.remoteForm.save}>
                      {sbb.icon &&
                        <Icon name={sbb.icon.name} type={sbb.icon.type} style={sbb.icon.style}></Icon>}
                      <Text style={[s.h_btnTxtBold_style, sbb.style_txt]}>{sbb.txt || "Salvar"}</Text>
                    </Button>
                  </View>
                  <View style={s.spacer60}></View>
                </>
              }

              {/* Reset */}
              {(cb = spec.clear_btn) &&
                <>
                  <View style={sflat([s2.saveBtn, {marginLeft: 20}, cb.style_ct])}>
                    <Button style={[s.btn, cb.style_btn]} onPress={this.remoteForm.clearForm} testID={"Salvar"}>
                      {cb.icon &&
                        <Icon name={cb.icon.name} type={cb.icon.type} style={cb.icon.style}></Icon>}
                      <Text style={[s.h_btnTxtBold_style, cb.style_txt]}>{cb.txt || "Salvar"}</Text>
                    </Button>
                  </View>
                  <View style={s.spacer60}></View>
                </>
              }

              </View>

              {/* Notes */}
              {!spec.no_required_fields &&
                <View style={s.footNotesCt}>
                  <Text style={s.footNotesTxt}>
                    * Campos com asterisco são obrigatórios.</Text></View>
              }

            </View>
          </Content>
        ||
        // Spinner
        <View style={s.loadingCtBgGray}>
          <ActivityIndicator />
          <Text style={s.loading}>Carregando...</Text></View>
        }
        {/* </KeyboardAwareScrollView> */}
      </Container>
    )
  }
}

const s2 = StyleSheet.create({
  errorBoxCt: {
    alignSelf: 'center',
    padding: 15,
    width: "80%",
    borderRadius: 10,
    borderColor: '#ff7878',
    backgroundColor: "#ffdada",
    borderWidth: 3,
    marginBottom: 20,
  },
  errorBoxItem: {
    fontSize: 16,
    marginTop: 8,
    marginBottom: 2,
  },
  saveBtn: {
    ...s.submitCt,
    alignSelf: 'center',
  },
  resetBtn: {
    ...s.btn,
    marginLeft: 20,
    backgroundColor: "transparent",
    borderColor: "#999",
    elevation: 0,
    borderWidth: 2,
  },
  resetTxt: {
    color: "#999",
  }
})