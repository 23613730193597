import { Text, View } from "native-base";
import React from "react";
import { Linking, TouchableOpacity } from "react-native";
import { s } from "../theme/screens/leadView";
import { dateHour, fmtPhone, threeDots, lastIf, simpleCalert, loge, maxChar, ci, blank } from "../utils/utils";
import { LeadSession } from "../utils/lead-session-ct";
import { PhoneCaller } from "../utils/phone-caller";
import conn from "../utils/conn";
import { memStore } from "../utils/mem-store";
import { crmPhases } from "../utils/crm-phases";

export interface LeadHeadersProps {
  navigation: any,
  leadSession: LeadSession|null,
  reloadLead: Function
  crmPhases: any[]|null
  obsOrFrom: boolean
}

export class LeadHeaders extends React.Component<LeadHeadersProps, {}> {
  constructor(props){
    super(props);
  }

  get lead(): any {
    if (!this.props.leadSession) return
    return this.props.leadSession.lead}
  get msg(): any {
    if (!this.props.leadSession) return
    return this.props.leadSession.msg}

  phoneCall = () => {
    const defaultErr = "Não foi possível fazer a ligação: erro ao checar permissão."
    const self = this
    
    conn.fetch('check_perm', 'POST', {
      lead_id: self.lead.id, 
      action_type: 'phone_call'
    
    }).then(resp => {
      if (resp.success)
        new PhoneCaller(this.props.leadSession.lead.customer_contact, this.props.reloadLead).phoneCall()
      else
        simpleCalert(resp.message || defaultErr)
    
    }).catch(e => {
      simpleCalert(defaultErr)
      loge(e)
    })
  }

  detailScreen = () => {
    if (!this.props.leadSession) 
      return

    this.props.navigation.navigate(
      "tableScreen", 
      {
        spec: {
          load_path: `contacts/get_details?contact_id=${this.props.leadSession.lead.id}`,
          back_btn: true,
          title: 'Detalhes',
        },
      }
    )
  }

  displayCrmphase(crm_phase: string){
    try {
      return crmPhases.get()[crm_phase]
    } catch (e) {
      loge(e)
      return ''      
    }
  }

  crmphaseStyleName(crmphase: string){
    switch (crmphase) {
      case '#528c7d': return 'cellTxtStatusLost'
      case '#357c5c': return 'cellTxtStatusNew'
      case '#24bece': return 'cellTxtStatusNegoc'
      case '#5ed30d': return 'cellTxtStatusSold'
      default: return ''
    }
  }

  obsScreen = () => {
    if (!this.props.leadSession)
      return
     
    const callbackSessionId = memStore.create({
      onSave: (editingObjectTree) => {
        if (editingObjectTree) {
          this.props.leadSession.lead.dirty = true
          this.props.leadSession.lead.observation = editingObjectTree.note
          this.props.reloadLead()
          memStore.destroy(callbackSessionId)
        }
      }
    }).id

    this.props.navigation.navigate(
      "formScreen", 
      {
        spec: `contacts/obs_form?object_id=${this.props.leadSession.lead.id}`,
        callbackSessionId: callbackSessionId,
      }
    )
  }
  
  sendMail = () => {
    Linking.openURL(`mailto:${this.lead.customer.email}`)
  }

  buildPhone = (customer) => {
    if (blank(customer.phone_number))
      return ''

    let p = ''
    if (customer.phone_ddi && customer.phone_ddi != conn.user.default_ddi)
      p += `+${customer.phone_ddi} `
    p += fmtPhone(customer.phone_number)
    return p
  }

  render() {
    const { lead, msg } = this
    
    let showContacts = true
    if (this.lead && this.lead.hide_new_contacts && this.lead.status == 0)
      showContacts = false

    return (
      <View>
        
        {/* anúncio */}
        <TouchableOpacity onPress={() => this.detailScreen()} style={s.cell} testID="detailSection">
        <View style={s.cellLineFirst}>
          <Text style={s.cellTit}>Anúncio</Text>
          <Text style={s.cellSub}>{conn.user.is_admin ? lead.source_name : lead.source_name_display}</Text></View>
        <View style={s.cellLine}>
          <Text style={s.cellTxt}>{lead.product.description}</Text>
          <Text style={s.cellTxtRight}>{lead.product.price}</Text></View></TouchableOpacity>

        {/* contato */}
        <View style={s.cell} testID='contactSection'>
          <View style={s.cellLineContact}>
            <Text style={s.cellTitContact}>Contato</Text>
            <Text numberOfLines={1} style={s.cellSubContact} testID={lead.customer.name}>{lead.customer.name}</Text>
            <Text style={s[this.crmphaseStyleName(lead.crmphase)]}>{this.displayCrmphase(lead.crmphase)}</Text></View>
          {showContacts &&
          <View style={s.contactLinks}>
            <TouchableOpacity onPress={this.sendMail} style={s.contactEmailBtn}>
              <Text style={s.contactEmailTxt}>{lead.blur_contacts ? maxChar(lead.customer.email, 9) : lead.customer.email}</Text></TouchableOpacity>
            <TouchableOpacity onPress={this.phoneCall} style={s.contactPhoneBtn}>
              <Text style={s.contactPhoneTxt}>{lead.blur_contacts ? maxChar(this.buildPhone(lead.customer), 11) : this.buildPhone(lead.customer) }</Text></TouchableOpacity></View>}
        </View>

        {/* obs */}
        {this.props.obsOrFrom &&
        <TouchableOpacity onPress={() => this.obsScreen()} style={s.cell} testID="observationSection">
          <View style={s.cellLine}>
            <Text style={s.cellTitObs}>Observações</Text>
            <Text numberOfLines={1} style={lastIf([s.cellTxt, s.cellTxtLight], !lead.observation)}>
              {threeDots(lead.observation) || 'Toque para editar...'}</Text></View></TouchableOpacity>}

        {/* de */}
        {!this.props.obsOrFrom &&
        <View style={s.cell}>
          <View style={s.cellLine}>
            <Text style={s.cellTitObs}>De</Text>
            <Text style={s.cellTxt}>{msg.from}</Text>
            <Text style={s.cellTxtRight}>{dateHour(msg.date_created)}</Text></View></View> }

      </View>
    )
  }
}
