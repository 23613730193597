import { ifWeb } from "../../utils/utils";

const filterBtn = {
  flex: 1, 
  backgroundColor: 'white', 
  flexDirection: 'column', 
  justifyContent: 'center', 
  alignItems: 'center', 
  borderWidth: 0, 
  width: 90,
  paddingVertical: 7,
  marginHorizontal: 5,
  marginVertical: 8,    
  shadowRadius: 3,
  shadowColor: "black",
  shadowOffset: {width: 2, height: 1},
  shadowOpacity: .3,
  height: 50,
}

const filterTitle = {
  fontSize: 14, 
  fontFamily: "Roboto-Medium",
  color: "#2C86A2"
}

const filterSub = {
  fontSize: 12, 
  fontFamily: "Roboto-Medium",
  opacity: 0.6, 
  marginTop: 3
}

export default {
  filterCt: {
    flex: 1, 
    flexDirection: 'row', 
    backgroundColor: "#F2F4F7", 
    paddingVertical: 7,
    maxHeight: 80,
    alignSelf: ifWeb("center"),
    maxWidth: '100%',
  },

  filterBtn: {...filterBtn},
  filterTitle: {...filterTitle},
  filterSub: {...filterSub},

  filterBtnActive: {...filterBtn, backgroundColor: "#10B1E3"},
  filterTitleActive: {...filterTitle, color: "white"},
  filterSubActive: {...filterSub, color: "white", opacity: 0.9},

  filterLeftPad: {width: 10},
  filterRightPad: {width: 10},
}

