import { StyleSheet } from "react-native";
import { ifWeb } from "../../utils/utils";

const txt = {
  marginTop: 15,
  fontFamily: "Roboto",
  fontSize: 14,
  color: "#6A8287",
}

const tit = {
  fontFamily: "Roboto-Medium",
  fontSize: 13,
  color: "#12B2FE",
}

const cellLine = {
  flex: 1, 
  flexDirection: 'row',
}

export default {
  cell: {
    flex: 1, 
    flexDirection: 'column', 
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: '#ccc',
    paddingBottom: 15,
    paddingHorizontal: 15,
    // backgroundColor: 'yellow'
  },
  cellLineFirst: {
    paddingTop: 12,
    ...cellLine,
  },
  cellLineFirstActivity: {
    paddingTop: 12,
    ...cellLine,
    marginBottom: 20
  },
  webSelectCt: {
    width: 300,
    marginLeft: 10
  },
  cellLine: {
    ...cellLine
  },
  cellLineContact: {
    ...cellLine,
    // fix 1/2 for web: bottom of cellSubContact text is cut
    ...ifWeb({
      height: 50,
      marginBottom: 7,
    })
  },
  cellTit: {
    ...txt,
    ...tit,
    flexWrap: "nowrap",
  },
  cellTitObs: {
    ...txt,
    ...tit,
    marginRight: 10,
  },
  cellTitContact: {
    ...txt,
    ...tit,
    flex: 0,
    // backgroundColor: 'red'
  },
  cellSub: {
    ...txt,
    marginLeft: 10,
    color: "gray",
  },
  cellTxt: {
    ...txt,
    flex: 1,
  },
  cellTxtLight: {
    fontSize: 12,
    color: "#aaa",
    fontStyle: 'italic',
    marginTop: 17.5
  },
  cellTxtRight: {
    ...txt,
  },
  cellTxtStatusLost: {...txt, flex: 0, color: "red"},
  cellTxtStatusNew: {...txt, flex: 0, color: "blue"},
  cellTxtStatusNegoc: { ...txt, flex: 0, color: "#aaa"},//, backgroundColor: 'blue'},
  cellTxtStatusSold: {...txt, flex: 0, color: "green"},

  contactLinks: {
    flexWrap: "wrap", 
    alignItems: 'flex-start', 
    flexDirection:'row', 
    paddingTop: 5, 
    // backgroundColor: 'magenta'
  },
  contactEmailBtn: {
    paddingTop: 5,
    marginRight: 10,
  },
  contactEmailTxt: {
    fontSize: 13,
    color: 'blue',
  },
  contactPhoneBtn: {
    paddingTop: 5,
    marginRight: 10,
  },
  contactPhoneTxt: {
    fontSize: 13,
    color: 'blue',
  },
  cellSubContact: {
    ...txt,
    flex: 1,
    marginLeft: 10,
    color: "gray",
    // backgroundColor: 'green',
    // fix 2/2 for web: bottom of cellSubContact text is cut
    height: ifWeb(20)
  },

  // create activity

  cellAct: {
    flex: 1, 
    flexDirection: 'column', 
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: '#ccc',
    paddingBottom: 5,
    paddingHorizontal: 15,
  },

  cellTitAction: {
    ...txt,
    ...tit,
  },
  cellActionTypeBtn: {
  },
  cellActionTypeName: {
    ...txt,
    marginTop: 0,
  },
  cellTitDate: {
    ...txt,
    ...tit,
  },
  cellDateBtn: {
  },
  cellDateTxt: {
    ...txt,
    marginTop: 0,
  },
}

