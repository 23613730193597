import React from "react";
import { View } from 'native-base'
import { MemObj, memStore } from "../../utils/mem-store";
import { tree2flatMap, loge, flatMap2tree, blank, replaceProps, uuidv4, fallbackArray, copyObject, hash2params, onlyValuesPresent, ci, isMob } from "../../utils/utils";
import conn from "../../utils/conn";
import { Fsel2 } from "./fields/fsel2";
import { Ftxt2 } from "./fields/ftxt2";
import { Flist } from "./fields/flist";
import { Fdate2 } from "./fields/fdate2"
import { Fbutton } from "./fields/fbutton"
import { Fswitch } from "./fields/fswitch";
import { Ftextarea } from "./fields/ftextarea";
import { Ftextview } from "./fields/ftextview";
import { Flistselect } from "./fields/flistselect"
import { Fscreenlink } from "./fields/fscreenlink";
import { getErrorMsg, getErrorMsgGroup } from "./validations"
import { rmsg } from "../../utils/utils-view";
import { DatasourceList } from "../../utils/datasource-list";
import { formMemory } from "./form-memory";

export class RemoteForm {
  object: any
  onChange: Function|null = null
  spec: any
  rawSpec: any
  objectSession: MemObj|null = null
  callbackSession: MemObj|null = null
  sectionHelp: any = {}
  setStateFromDs: Function
  goBackFromDs: Function
  navFromDs: Function
  menuNavigateFromDs: Function
  fields: any = {}
  listDs: DatasourceList|null = null

  constructor(setStateFromDs: Function, goBackFromDs: Function, navFromDs: Function, menuNavigateFromDs?: Function) {
    this.setStateFromDs = setStateFromDs
    this.goBackFromDs = goBackFromDs
    this.navFromDs = navFromDs
    this.menuNavigateFromDs = menuNavigateFromDs
  }

  setState = (newState?: any) => {
    this.setStateFromDs(newState || {spec: {...this.spec}})
  }

  setup = (conf) => {
    if (!conf.spec)
      throw("spec is required")

    if (conf.objectSessionId)
      this.objectSession = memStore.get(conf.objectSessionId)

    if (conf.callbackSessionId) {
      this.callbackSession = memStore.get(conf.callbackSessionId)
    }

    if (conf.listDsSessionId)
      this.listDs = memStore.get(conf.listDsSessionId).obj

    if (conf.spec.load_path)
      this.load(this.brushUrl(conf.spec.load_path))
    else 
      this.onLoadSuccess({spec: conf.spec})
  }

  // Load

  brushUrl = (url) => {
    let add = {}

    if (!url.match(/user_id=/))
      add['user_id'] = `${conn.user.id}`

    if (!url.match(/object_id=/)) {
      const objectId = this.objectSession ? this.objectSession.obj.id : ''
      if (objectId)
        add['object_id'] = `${objectId}`
    }

    let sep = url.match(/\?/) ? '&' : '?'

    return `${url}${sep}${hash2params(add)}`
  }

  load(url){
    conn.fetch(url).then(resp => {
      resp.success 
        ? this.onLoadSuccess(resp)
        : this.onLoadError(resp.msg)
    }).catch((error) => {
      loge(error)
      this.onLoadError()
    })
  }

  onLoadSuccess = (resp) => {
    this.spec = copyObject(resp.spec)
    this.rawSpec = copyObject(resp.spec)

    this.spec.sections.forEach(s => 
      s.fields.forEach(f => {
        this.fields[f.name] = f
      })
    )

    let fromMemory: any = null
    if (!blank(this.spec.form_memory_key))
      fromMemory = formMemory.get(this.spec.form_memory_key)

    const o = fromMemory || resp.object || (this.objectSession && this.objectSession.obj)

    if (o) 
      this.object = tree2flatMap(o)
    else
      this.object = {}

    this.setState()
  }

  onLoadError = (msg?) => {
    rmsg(msg || 'Erro ao carregar formulário. Tente novamente e se o erro continuar, entre em contato com nosso suporte.')
    this.goBackFromDs()
  }

  // Save

  getErrors = () => {
    let errors:Array<string> = []

    Object.keys(this.fields).forEach(fieldName => {
      if (this.fields[fieldName].validation) {
        const errorMsg = getErrorMsg(this.fields[fieldName], this.object[fieldName])

        if (errorMsg) {
          errors.push(errorMsg)
          this.fields[fieldName].isValid = false
        }
        else
          this.fields[fieldName].isValid = true
      }
      else
        this.fields[fieldName].isValid = true
    })

    fallbackArray(this.spec.groups_validations).forEach(group => {
      let allAreValids = true
      let values = [], groupLabels = []

      group.fields.forEach(field => {
        values.push(this.object[field])
        groupLabels.push(this.fields[field].label)

        if (!this.fields[field].isValid)
          allAreValids = false
      })

      if (allAreValids) {
        const errorMsgGroup = getErrorMsgGroup(group, groupLabels, values)

        if (errorMsgGroup)
          errors.push(errorMsgGroup)
      }
    })

    return errors
  }

  clearForm = () => {
    if (!blank(this.spec.form_memory_key))
      formMemory.delete(this.spec.form_memory_key)

    this.onLoadSuccess({
      object: {},
      spec: {...this.rawSpec},
    })
  }

  save = () => {
    const errors = this.getErrors()
    this.setStateFromDs({loading: true, editable: false, errorMsgs: errors})

    if (errors.length > 0) {
      this.saveAlways()
      return
    }

    if (!blank(this.spec.form_memory_key))
      formMemory.save(this.spec.form_memory_key, flatMap2tree(this.object))

    if (this.listDs) {
      this.listDs.setOnSingleEvent('resultReport', this.dsResultReport) 
      this.listDs.filter(
        {...onlyValuesPresent(flatMap2tree(this.object)), ...this.spec.list_ds_filter_override} 
      )
      return
    }

    let verb = ''
    let append = ''
    let data = {}
    const saveKey = this.spec.save_key || 'object'

    data[saveKey] = flatMap2tree(this.object)

    if (this.object.id) {
      data[saveKey]['id'] = this.object.id
      append = `/${this.object.id}`
      verb = 'PATCH'
    } else {
      verb = 'POST'
    }

    conn.fetch(`${this.spec.submit_path}${append}`, verb, data)
      .then(resp => this.saveSuccess(resp, data[saveKey]))
      .catch(this.saveError)
  }

  dsResultReport = (numItems) => {
    if (!this.listDs)
      return

    if (numItems == 0) {
      this.setStateFromDs({loading: false})
      rmsg("Nenhum resultado encontrado", {type: 'w'})
    } else {
      this.listDs.unsetOnSingleEvent('resultReport')
      this.goBackFromDs()
    }
  }

  callbacksSuccess = (resp, editingObjectTree) => {
    if (!this.callbackSession || !this.callbackSession.obj)
      return

    if (resp.new_object) {
      if (this.callbackSession.obj.onChangeObject)
        this.callbackSession.obj.onChangeObject(resp.new_object, true)
    }
    else if (this.callbackSession.obj.onSave) 
      this.callbackSession.obj.onSave(editingObjectTree)
  }

  editObjSaveSuccess = (resp, editingObjectTree) => {
    editingObjectTree = {...editingObjectTree, ...resp.object}
    editingObjectTree.dirty = true

    replaceProps(this.objectSession.obj, editingObjectTree)

    if (this.objectSession && this.objectSession.obj.onSave) 
      this.objectSession.obj.onSave(true)

    if (this.objectSession && resp.destroy_mem_store)
      memStore.destroy(this.objectSession.id)
  }

  saveSuccess = (resp, editingObjectTree) => {
    if (!resp.success) {
      this.saveError(null, resp.msg)
      return
    }
    this.saveAlways()
 
    if (!blank(resp.msg))
      rmsg(resp.msg, resp.opt)

    this.onLoadSuccess({
      object: resp.object || this.object,
      spec: resp.spec || this.rawSpec
    })

    this.callbacksSuccess(resp, editingObjectTree)

    if (this.objectSession) {
      this.editObjSaveSuccess(resp, editingObjectTree)
    }

    if (resp.navigation_pop)
      this.navFromDs({navigation_pop: resp.navigation_pop})

    else if (resp.menu_navigate && this.menuNavigateFromDs)
      this.menuNavigateFromDs(resp.menu_navigate)

    else if (resp.go_back_after_save_success !== false)
      this.goBackFromDs()
  }

  saveError = (error, msg?) => {
    if (error)
      loge(error)
    this.saveAlways()
    rmsg(msg || "Erro ao salvar. Tente novamente e se o erro continuar entre em contato com nosso suporte.")
  }
  
  saveAlways(){
    this.setState({loading: false, editable: true})
  }

  delete = (resp) => {
    rmsg(resp.msg)
    this.callbacksSuccess(resp, {})
  }

  setRef = (field, ref) => {
    this.fields[field.name].ref = ref
  }

  changeFocus = (field: any, focus: boolean) => {
    const sectionObj = this.spec.sections.find(s =>
      s.fields.find(f => f.name == field.name) !== undefined
    )
    const fieldObj = sectionObj.fields.find(f => 
      f.name == field.name
    )

    fieldObj.on_focus = focus
    sectionObj.on_focus = focus

    // for (let i = 0; i < this.spec.sections.length; i++)
    //   this.spec.sections[i].on_focus = sectionObj === this.spec.sections[i]

    // for (let i = 0; i < this.spec.sections.length; i++)
    //   for (let j = 0; j < this.spec.sections[i].fields.length; j++)
    //     this.spec.sections[i].fields[j].on_focus = fieldObj === this.spec.sections[i].fields[j]

    this.setState()
  }

  getZIndexField = (field: any) => {
    return field.on_focus ? 2 : 1
  }

  changeTitle = (newTitle: any, field:  any) => {
    const sectionObj = this.spec.sections.find(s =>
      s.fields.find(f => f.name == field.name) !== undefined
    )

    if (newTitle.title !== undefined)
      sectionObj.title = newTitle.title
    
    if (newTitle.titleComplement !== undefined)
      sectionObj.title_complement = newTitle.titleComplement

    this.setState()
  }

  reLayout = () => {
    this.setState()
  }

  getField = (field: any) => {
    const props = {
      field: field,
      iniVal: this.object[field.name], 
      onChange: v => this.object[field.name] = v,
      setRef: r => this.setRef(field, r),
      onDelete: (obj) => this.delete(obj),
      onBlur: () => this.changeFocus(field, false),
      onFocus: () => this.changeFocus(field, true),
      navigate: (nav) => this.navFromDs(nav),
      changeTitle: (newTitle) => this.changeTitle(newTitle, field),
      reLayout: this.reLayout,
    }

    const dft = {
      style: {zIndex: this.getZIndexField(field)},
      key: uuidv4(),
    }

    switch (field.type) {
      case 'select':
        return <View {...dft}><Fsel2           {...props} /></View>

      case 'string':
        return <View {...dft}><Ftxt2           {...props} /></View>

      case 'switch':
        return <View {...dft}><Fswitch         {...props} /></View>

      case 'textarea':
        return <View {...dft}><Ftextarea       {...props} /></View>
        
      case 'date':
        return <View {...dft}><Fdate2          {...props} /></View>

      case 'list':
        return <View {...dft}><Flist           {...props} /></View>

      case 'textview':
        return <View {...dft}><Ftextview       {...props} /></View>

      case 'screenlink':
        return <View {...dft}><Fscreenlink     {...props} /></View>

      case 'button':
        return <View {...dft}><Fbutton         {...props} /></View>

      case 'list-select':
        return <View {...dft}><Flistselect     {...props} /></View>
    }

    return null
  }
}
