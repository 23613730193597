class CrmPhases {
  values = {}
  
  set(values) {
    if (values)
      this.values = values
  }

  get() {
    return this.values
  }
}

export const crmPhases = new CrmPhases()
