import React from 'react';
import { ci } from '../shared/utils/utils';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { webAlert } from './web-alert';

let resolve;

export interface WebAlertComponentProps {}

export interface WebAlertComponentState {
  text: string,
  title?: string,
  showCancel?: boolean,
  okText?: string,
  cancelText?: string,
  isOpen?: boolean,
  hideBackdrop?: boolean,
}

const defaultState = {
  isOpen: false,
  text: '',
  title: '',
  showCancel: false,
  okText: 'Ok',
  cancelText: 'Cancelar',
  hideBackdrop: true,
}

export class WebAlertComponent extends React.Component<WebAlertComponentState, WebAlertComponentState> {
  constructor(props) {
    super(props);

    this.state = defaultState

    this.handleCancel = this.handleCancel.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.show = this.show.bind(this);

    webAlert.setComponent(this)
  }

  handleCancel() {
    this.setState({isOpen: false});
    resolve(false);
  }

  handleConfirm() {
    this.setState({isOpen: false});
    resolve(true);
  }

  show(props = {}) {
    this.setState({...defaultState, isOpen: true, ...props});
    return new Promise((res) => {
      resolve = res;
    })
  }

  hide(){
    this.setState({isOpen: false})
  }

  render() {
    const {isOpen, text, title, cancelText, okText} = this.state;
    return (
      // https://v1.material-ui.com/api/modal/
      <Dialog
        open={!!isOpen}
        onClose={this.handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        hideBackdrop={this.state.hideBackdrop}
        disableScrollLock={true}
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{text}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {this.state.showCancel &&
          <Button onClick={this.handleCancel} color="primary" style={{color: '#257491'}}>
            {cancelText || 'Cancelar'}
          </Button>}
          <Button onClick={this.handleConfirm} color="primary" style={{color: '#257491'}} autoFocus>
            {okText || 'OK'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default WebAlertComponent;
