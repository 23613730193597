import { StyleSheet } from "react-native";
import { ifAndroid } from "../../utils/utils";

const m_cont:any = {
  backgroundColor: "white",
}

const m_btnCt:any = {
  flex: 1,
  flexDirection: 'row',
  alignItems: "center",
}

const s = StyleSheet.create({
  m_overlay: {
    position: 'absolute', 
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    zIndex: 10,
  },
  m_cont: {
    ...m_cont,
    position: 'absolute', 
    top: 110,
    left: 0,
    zIndex: 11,
    height: '100%',
    paddingHorizontal: 15,
    paddingVertical: 15,
    borderRadius: 5,
    shadowRadius: 3,
    shadowColor: "black",
    shadowOffset: {width: 2, height: 1},
    shadowOpacity: .3,
    minWidth: 250,
    ...ifAndroid({
      borderColor: '#ccc', 
      borderWidth: 1
    }),
  },
  m_cont_web: {
    ...m_cont,    
  },
  m_btnCt: {
    ...m_btnCt
  },
  m_btnCt_web: {
    ...m_btnCt,
    flex: 0,
  },
  m_btnIco: {
    fontSize: 20,
    color: "#2C86A2",
    flex: 0,
  },
  m_btn: {
    flex: 1,
    // borderBottomColor: "white", 
    // borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: "#F2F4F7", 
    borderBottomWidth: 1,
  },
  m_btnTxt: {
    fontFamily: "Roboto-Medium",
    fontSize: 14,
    color: "#6A8287", 
    width: "100%"
  },
})

export { s }
