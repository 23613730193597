import { simpleCalert, isWeb, blank, loge, ci, logMsg } from "./utils"
import { Linking } from "react-native"
import { checkOrAskContactPermission, saveContactToDevice } from "../../plataform/device-contacts"
import conn from "./conn"

export class PhoneCaller {
  phoneContact: any = {}
  reloadLead: Function

  constructor(phoneContact: any, reloadLead: Function){
    this.phoneContact = phoneContact
    this.reloadLead = reloadLead
  }

  phone = () => {
    let p = this.phoneContact && this.phoneContact.phone
    if (blank(p))
      p = null
    return p
  }

  phoneCall = () => {
    if (this.phone()) {
      this.doPhoneCall()
    } else {
      simpleCalert('Número de telefone não disponível.')
    }
  }

  doPhoneCall = async () => {
    const url = this.buildPhoneUrl()
    Linking.canOpenURL(url).then(async y => {
      if (!y) {
        logMsg("cannot open url: " + url)
        return simpleCalert("Não é possível fazer a chamada.")
      }

      if (!isWeb)
        if (await checkOrAskContactPermission())
          await saveContactToDevice(this.phoneContact)

      Linking.openURL(url)
      this.notifyCall()
    })
  }

  addDdi = () => {
    if (this.phoneContact.phone_ddi && this.phoneContact.phone_ddi != '55')
      return this.phoneContact.phone_ddi
    else
      return ''
  }

  buildPhoneUrl = () => {
    let callUrl = this.phoneContact && this.phoneContact.call_url
    return blank(callUrl) ? `tel:${isWeb ? '//' : ''}${this.addDdi()}${this.phone()}` : callUrl
  }

  notifyCall = () => {
    conn.fetch('contact_actions/action', 'POST', {
      contacted_by: 'phone',
      lead_id: this.phoneContact.lead_id,

    }).then(resp => {
      if (!resp.success)
        simpleCalert(resp.message)
      else
        this.reloadLead(resp.lead)

    }).catch(error => {
      loge(error)
      simpleCalert("Erro ao registrar ligação.")
    })
  }
}