import { Dimensions } from 'react-native';
import { ifWeb } from '../../utils/utils';
var { width, height } = Dimensions.get('window');

const overlay = {
  flex: 1,
  position: 'absolute',
  left: ifWeb(-150, 0), // fix overlay bg displaced to the right
  top: 0,
  opacity: 1,
  backgroundColor: 'rgba(52, 52, 52, 0.3)',
  width: width,
  height: height,
  justifyContent: 'center',
  zIndex: 10,
}

const overlaySpinner = {
  left: ifWeb(-153, 0)  // fix overlay spinner displaced to the right
}

export default {
  content: {
    backgroundColor: "#F2F4F7",
    flex: 1,
    paddingLeft: ifWeb(70),
    paddingRight: ifWeb(70),
  },
  overlay:  {
    ...overlay
  },
  overlayUnderView: {
    ...overlay,
    ...ifWeb({
      width: '100%',
      left: 0,
    })
  },
  overlaySpinner: {
    ...overlaySpinner,
  },
  overlaySpinnerUnderView: {
    ...overlaySpinner,
    left: 0,
  },
  overlaySpinnerPad: {
    ...overlaySpinner,
    paddingBottom: 300
  },
  loadingCt: {
    backgroundColor: ifWeb("white", "transparent"), 
    width: ifWeb('100%'),
    flex: 1,
    justifyContent: "center",
    alignSelf: "center",
  },
  loadingCtBgGray: {
    backgroundColor: '#F2F4F7', 
    width: ifWeb('100%'),
    flex: 1,
    justifyContent: "center",
    alignSelf: "center",
  },
  loading: {
    alignSelf: 'center', 
    color: '#aaa', 
    fontSize: 12
  },
  fullloader: ifWeb({
      // backgroundColor: "white", 
      flex: 1,
      marginTop: height/2,
    },{
      // backgroundColor: "red", 
      flex: 1,
      // marginTop: '50%',
      paddingTop: '40%',
      height: 100,
      width: width,
      justifyContent: "center",
      alignSelf: "center",
  }),
  graphLoadingCt: ifWeb({
      // backgroundColor: "white", 
      flex: 1,
      marginTop: height/2,
    },{
      // backgroundColor: "transparent", 
      flex: 1,
      marginTop: 100,
      height: height, 
      width: width,
      justifyContent: "center",
      alignSelf: "center",
  }),
  graphLoading: {
    alignSelf: 'center', 
    color: '#aaa', 
    fontSize: 12, 
    marginTop: 10,
    // backgroundColor: 'green',
  },
  footerSt: {
    backgroundColor: 'white'
  },
  spacer15: {height: 15},
  spacer45: {height: 45},
  spacer60: {height: 60},
}
