
export class PushNotif {
  static setup() {
  }

  static _setup() {
  }
  
  static requestPermissions() {
  }

  pushStart(){
    return true
  }
}