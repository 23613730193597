import { StyleSheet } from "react-native";
import {common, header, i} from "../pieces/shared"
import cells from "../pieces/cells";
import { ifWeb } from "../../utils/utils";

const bodyCt = {
  paddingTop: 15,
  paddingHorizontal: 10,
}

const textAreaWeb = ifWeb({
  // outline: 'none',
  borderWidth: 1,
  borderColor: '#ccc',
}, {});

const s = StyleSheet.create({
  ...common,
  ...header, 
  ...cells,

  bodyCt: {
    ...bodyCt,
  },

  bodySpinnerCt: {
    ...bodyCt,
    flex: 1, 
  },

  textArea: {
    ...textAreaWeb,
    height: 400, 
    paddingTop: 15,
    paddingHorizontal: 15,
    marginTop: 15,
    // marginHorizontal: 15,
    fontFamily: "Roboto",
    fontSize: 14,
    color: "#6A8287",
    textAlignVertical: 'top',
  },
  rightHeaderIcons: {
    fontSize: 22,
    color: '#fff'
  },
  rightHeaderZapIcon: {
    fontSize: 22,
    color: '#fff',
    marginTop: -33,
  },

  submitControls: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingTop: 10,
  },
  submitControlsCenter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingTop: 10,
    marginTop: 30,
  },
  saveButton: {
    marginLeft: 10,
    backgroundColor: '#12b2fe',
    borderRadius: 4,
  },
  sendByWhatsappButton: {
    backgroundColor: '#00bfa5',
    marginLeft: 10,
    borderRadius: 4,
  },
  sendButtonText: {
    color: 'white',
  },
  msg: {
    marginTop: 30, 
    paddingHorizontal: 14,
    fontSize: 13,
    fontFamily: "Roboto",
    color: "#6A8287",
    flex: 0,
    // alignSelf: 'center',
  },
  iconInfo: {
    fontSize: 18,
    color: '#6A8287',
  }
})

const i2 = i;
export {s, i2 as i}