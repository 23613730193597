import { makeid } from "./utils";

export class LeadSession {
  id: string
  lead: any
  msg: any
  reloadMsgs: boolean
  constructor(lead: any = null, msg: any = null){
    this.id = makeid()
    this.lead = lead
    this.msg = msg
    this.reloadMsgs = false
  }
}

class LeadSessionCt {
  containers = {}

  create(lead: any = null, msg: any = null){
    const leadSession = new LeadSession(lead, msg)
    this.containers[leadSession.id] = leadSession
    return leadSession
  }

  destroy(id){
    if (this.containers[id])
      delete this.containers[id]
  }

  get(id){
    return this.containers[id]
  }
}

export const leadSessionCt = new LeadSessionCt()
