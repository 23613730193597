import { Text, View, Icon } from "native-base";
import { isWeb, sflat } from "../utils/utils"
import React from "react";
import { StyleSheet } from "react-native";

interface Props {
  text?: any
  styleCt?: any
  iconLeft?: any
  iconRight?: any
  iconIn?: boolean
  iconInCt?: any
}

export class TextIcons extends React.Component<Props, {}> {
  renderIcon = (icon: any) => {
    return (
      <Icon style={sflat(s2.icon, icon.style)}
        name={icon.name} type={icon.type}/>
    )
  }

  renderText = (text: any) => {
    const w_space = '  '
    return (
      <Text style={sflat(s2.icon, text.style)}>{this.props.iconIn ? w_space : null}{text.content}</Text>
    )
  }

  render() {
    const { iconLeft, iconRight, text, iconIn, iconInCt } = this.props
    return (
      <View style={sflat([{flexDirection: 'row', alignSelf: 'center'}, this.props.styleCt])}>
        {iconIn ?
          <Text style={iconInCt}>
            {iconLeft && this.renderIcon(iconLeft)}
            {text && this.renderText(text)}
          </Text>
          :
          <>
            {iconLeft && this.renderIcon(iconLeft)}
            {text && this.renderText(text)}        
          </>
        }
        {iconRight && this.renderIcon(iconRight)}
      </View>
    )
  }
}

const s2 = StyleSheet.create({
  text: {
    fontSize: 17, 
    fontFamily: "Roboto", 
    color: "#6A8287"
  },
  icon: {
    fontSize: 18, 
    marginRight: 10,
    color: "#6A8287",
    textAlign: 'auto'
  }
})