// global['__DEV__'] = true;
// window['__DEV__'] = true;

import { StyleProvider, View, Icon, Text, Button } from "native-base";
import * as React from "react";
import { AppRegistry, StyleSheet } from "react-native";
import getTheme from "./shared/theme/native-base-theme/components";
import variables from "./shared/theme/native-base-theme/variables/commonColor";
import { Config, isDev } from "./shared/config";
import { NavWeb } from "./plataform/nav-web";
import conn from "./shared/utils/conn";
// import { reactotron } from './plataform/reactotron';
// import { trackGlobalErrors } from 'reactotron-react-js';
import { isMobileBrowser, ci } from "./shared/utils/utils";
import LandingStores from "./screens/LandingStores";
import 'typeface-roboto';
import Sentry from './plataform/sentry';
import { Integrations } from './plataform/sentry';
import { ToastContainer } from "./plataform/react-toastify";
import { WebAlertComponent } from "./plataform/web-alert-component";
import './plataform/ReactToastify.css';
import { deviceType } from "react-device-detect";

// App

export default class AppWeb extends React.Component<{}, {}> {
  constructor(props) {
    super(props)

    conn.configure(Config.baseUrl)

    Sentry.init({
      dsn: Config.sentryDSNWeb,
      environment: isDev ? 'development' : 'production',
      debug: isDev,
      integrations: [new Integrations.BrowserTracing()],
    
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });    
    Sentry.setTag("app_version", Config.appVersion);
  }
  
  onNavigationStateChange = (state) => {
  }

  // onNavigationStateChange = (state) => {
  //   try {
  //     this.routeLen = state.routes.length
  //     nemo.setCurrent(state.routes[state.routes.length - 1].name)        
  //     // ci('rdb:', state.index, state.routes.map(d => d.name), nemo.currentRouteName, this.routeLen)
  //   } catch (e) {
  //     loge(e)
  //     // ci('rdb:', e)
  //   }
  // }

  render() {
    return (
      <>
        <StyleProvider style={getTheme(variables)}>
          <>
          <ToastContainer hideProgressBar={true} newestOnTop={true} pauseOnHover={false} />
          {isMobileBrowser && deviceType != 'tablet' ? 
            <LandingStores /> : 
            <NavWeb onNavigationStateChange={this.onNavigationStateChange} />              
          }
          <WebAlertComponent text='' />
          </>
        </StyleProvider>
      </>
    )
  }
}

// Register/Run

AppRegistry.registerComponent(Config.appName, () => AppWeb)

AppRegistry.runApplication(Config.appName, {
  rootTag: document.getElementById("root")
})
