import { Button, Text, View, Icon } from "native-base";
import React from "react";
import { s } from "../../../theme/screens/comp-selc"
import { isWeb, isMob, ifMob, ifWeb, ci, sflat } from "../../../utils/utils";
import { Keyboard, StyleSheet } from "react-native";
import WebDatepickerInput from '../../../components/web-datepicker-input';
import WebDatePicker from "react-datepicker";
import moment from "moment";
import "../../../../plataform/react-datepicker.css";
import DateTimePicker from "react-native-modal-datetime-picker";
import { FieldProps } from "./_shared_props";

interface State {
  savedDate: any
  datePickerVisible: boolean,
}

export class Fdate2 extends React.Component<FieldProps, State> {
  webDatePickerRef: any

  constructor(props){
    super(props)
    this.state = {
      savedDate: (props.iniVal != undefined) ? new Date(props.iniVal) : null,
      datePickerVisible: false,
    }
  }

  showDatePicker = () => this.setState({datePickerVisible: true})
  hideDatePicker = () => this.setState({datePickerVisible: false})

  changeDate = (newDate) => {
    this.setState({savedDate: newDate})
    this.props.onChange( newDate )
  }

  onDatePicked = (newDate) => {
    this.hideDatePicker()
    if (newDate) {
      this.changeDate(newDate)
      Keyboard.dismiss()
    }
  }

  clearDate = () => this.changeDate(null)

  getDateFormat = () => {
    if(!this.props.field.show_time) return 'D/M/Y'
    return 'D/M/Y HH:mm'
  }

  display = (val) => {
    if (val)
      return moment(val).format(this.getDateFormat())
    else
      return this.props.field.placeholder || 'Selecione...'
  }

  render() {
    const { field } = this.props
    const date = this.state.savedDate
    return (
      <View style={sflat([s.wrapper, field.labelCt_style])}>
        {/* Label */}
        <Text style={sflat([s.label, field.label_style])}>{field.label}</Text>

        {/* date */}
        {isWeb &&
        <View style={[s.webSelectCt, ifWeb({marginTop: -5, flexDirection: 'row', flex: 1})]}>
          <WebDatePicker
            customInput={<WebDatepickerInput customValue={this.display(date)}/>}
            selected={date}
            showTimeSelect={field.show_time}
            dateFormat={field.show_time ? 'd/M/Y HH:mm' : 'd/M/Y'}
            onChange={this.onDatePicked} 
            onCalendarOpen={() => isMob && this.props.onFocus()}
            onCalendarClose={() => this.props.onBlur()}
            popperPlacement={field.popper_placement || 'top'}
            calendarStartDay={1}
            ref={r => this.webDatePickerRef = r}
            portalId="root-portal"
            />
          {field.has_clear_button &&
            <>
              {date &&
                <Button transparent onPress={this.clearDate}>
                  <Icon name="close" type="SimpleLineIcons" style={s2.closeIcon} />
                </Button> 
              ||
                <Button transparent onPress={() => 
                  this.webDatePickerRef && this.webDatePickerRef.setOpen(true)}>
                  <Icon name="calendar" type="SimpleLineIcons" style={s2.calendarIcon} />
                </Button>
              }   
            </>
          } 
        </View>}
        
        {isMob && <>
          <Button transparent style={s.cellDateBtn} onPress={this.showDatePicker} testID={this.props.field.name}>
            <Text style={sflat([dateDisplay(date), this.props.field.txt_style])}>
              {this.display(date)}
            </Text>
          </Button>
          {field.has_clear_button &&
            <>
              {date &&
                <Button transparent onPress={this.clearDate}>
                  <Icon name="close" type="SimpleLineIcons" 
                    style={s2.closeIcon} />
                </Button> 
              ||
                <Button transparent onPress={this.showDatePicker}>
                  <Icon name="calendar" type="SimpleLineIcons" style={s2.calendarIcon} />
                </Button>
              }
            </>
          }
          <DateTimePicker
            cancelTextIOS={field.cancel_text_mob || 'Cancelar'}
            confirmTextIOS={field.confirm_text_mob || 'Selecionar'} 
            headerTextIOS={field.header_text_mob || 'Escolha uma data'}
            isVisible={this.state.datePickerVisible}
            locale='pt_BR'
            modalPropsIOS={ifMob({onBackdropPress: this.hideDatePicker})}
            mode={field.show_time ? 'datetime' : 'date'}
            onCancel={this.hideDatePicker}
            onConfirm={this.onDatePicked}
            textColor={field.text_color_mob || 'black'}
            />
        </>}

      </View>
    )
  }
}

const dateDisplay = (val) => {
  return val ? s2.dateDisplay : s2.dateDisplayInactive
}

const s2 = StyleSheet.create({
  dateDisplayInactive: {fontFamily: "Roboto", fontSize: 14, color: "#ccc"},
  dateDisplay: {fontFamily: "Roboto", fontSize: 14, color: "#888"},
  closeIcon: {fontSize: 22, color: '#bbb'},
  calendarIcon: {fontSize: 15, color: '#bbb'},
})