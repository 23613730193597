import { Button, Text, View, Icon, Input, Left, Body } from "native-base";
import React from "react";
import { TouchableOpacity, StyleSheet } from "react-native"
import { s } from "../../../theme/screens/comp-selc"
import { ci, sflat } from "../../../utils/utils";
import { FieldProps } from "./_shared_props";

export class Fscreenlink extends React.Component<FieldProps, {}> {

  navigate = () => {
    const { navigate } = this.props.field
    this.props.navigate({
      ...navigate,
      key: navigate.to + '-' + Math.random() * 1000000
    })
  }

  render() {
    const { field } = this.props

    if (field.has_right_icon)
      field.icon_right = (field.icon_right || defaultIconRight)

    return (
      <TouchableOpacity style={sflat([s2.view, field.style_view])} onPress={() => this.navigate()}>
        {field.icon_left &&
          <Icon name={field.icon_left.name} type={field.icon_left.type} style={sflat([s2.icon, field.icon_left.style])}/>}

        <Text style={sflat([s2.text, field.text_style])}>{field.text}</Text>

        {field.icon_right &&
          <View style={{alignSelf: 'flex-end'}}>
            <Icon name={field.icon_right.name} type={field.icon_right.type} style={sflat([s2.icon, field.icon_right.style])}/>
          </View>
        }
      </TouchableOpacity>
    )
  }
}

const defaultIconRight = {
  name: "right",
  type: "AntDesign"
}

const s2 = StyleSheet.create({
  view: {
    borderWidth: 0,
    borderBottomWidth: 1,
    borderRadius: 6,
    borderColor: "#cccccc",
    backgroundColor: "#e5e5e5",
    paddingLeft: 15,
    padding: 20,
    marginVertical: 10,
    paddingHorizontal: 35,
    marginRight: 15
  },
  text: {
    fontSize: 16,
    fontFamily: 'Roboto',
    textAlignVertical: 'center',
    color: "#888888",
  },
  icon: {
    fontSize: 19,
    marginTop: -20,
    color: "#888888"
  }
})