class Nemo {
  currentRouteName = ''
  stack: string[] = []
  
  setCurrent(routeName: string, stack?: string[]){
    this.currentRouteName = routeName
    if (stack)
      this.stack = stack
  }
}

export default new Nemo()
