import { makeid } from "./utils";

export class MemObj {
  id: string
  obj: any
  constructor(obj: any = null){
    this.id = makeid()
    this.obj = obj
  }
}

class MemStore {
  containers = {}

  create(obj: any = null){
    const memObj = new MemObj(obj)
    this.containers[memObj.id] = memObj
    return memObj
  }

  destroy(id){
    if (this.containers[id])
      delete this.containers[id]
  }

  get(id){
    return this.containers[id]
  }
}

export const memStore = new MemStore()
