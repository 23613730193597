import { Button, Text, View, Icon, Input, Left, Body } from "native-base";
import React from "react";
import { TouchableOpacity, StyleSheet } from "react-native"
import { s } from "../../../theme/screens/leadNew"
import { ci, sflat, blank, addKey } from "../../../utils/utils";

interface Props {
  section: any
  getField: Function
  toggleVisibility: Function
  toggleSectionHelp: Function
}

export class SectionDefault extends React.Component<Props, {}> {
  renderHeaderContent = () => {
    const { section } = this.props
    return (
      <>
        {/* Visibility */}
        {!blank(section.closeable) &&
        <TouchableOpacity> 
          <Icon type="Ionicons" name={"eye-off"}
            style={{color: "transparent"}}></Icon>
        </TouchableOpacity>}

        {/* Help */}
        {!blank(section.help) &&
        <TouchableOpacity> 
          <Icon name="help-circle-outline" type="MaterialCommunityIcons" 
            style={{color: "transparent"}}></Icon>
        </TouchableOpacity>}
        
        <Text style={StyleSheet.flatten([s.sectionTxt, {flex: 1}])} testID={section.title}>{section.title} {section.title_complement}</Text>

        {/* Visibility */}
        {!blank(section.closeable) &&
        <TouchableOpacity activeOpacity={1} onPress={() => this.props.toggleVisibility()}> 
          <Icon type="MaterialIcons" name={section.is_visible ? "keyboard-arrow-down" : "keyboard-arrow-right"}
            style={section.is_visible ? s.sectionHelpActive : s.sectionHelp}></Icon>
        </TouchableOpacity>}

        {/* Help */}
        {!blank(section.help) &&
        <TouchableOpacity activeOpacity={1} onPress={() => this.props.toggleSectionHelp()} testID={"helpIcon." + section.title}> 
          <Icon name="help-circle-outline" type="MaterialCommunityIcons" 
            style={section.helpActive ? s.sectionHelpActive : s.sectionHelp}></Icon>
        </TouchableOpacity>}
      </>
    )
  }

  renderHeader = () => {
    if (this.props.section.closeable)
      return <Button style={StyleSheet.flatten(s2.section)} onPress={() => this.props.toggleVisibility()} testID={this.props.section.name}>
      {this.renderHeaderContent()}</Button>

    return <View style={s2.section}>{this.renderHeaderContent()}</View>
  }

  renderHeader2 = () => {
    return <View style={s2.help_without_sec_style}>{this.renderHeaderContent()}</View>
  }

  render() {
    const { section } = this.props
    return (
      <View style={sflat([section.section_style])}>
        {/* Section Header */}
        {!blank(section.title) && this.renderHeader()}

        {!blank(section.help_without_section) && this.renderHeader2()}

        {/* Fields */}
        {(blank(section.closeable) || section.is_visible) &&
          <View style={sflat([s.formItems, section.formItems_style])}>
            {section.fields.map(addKey).map(field => {
              // Field
              field.helpActive = section.helpActive && field.help
              return this.props.getField(field)
            })}
          </View>
        }
      </View>
    )
  }
}

const s2 = StyleSheet.create({
  section: StyleSheet.flatten([s.section, {flexDirection: 'row', flex: 1, alignItems: 'center'}]),
  help_without_sec_style: StyleSheet.flatten([s.section, 
    {flexDirection: 'row', flex: 1, backgroundColor: "transparent", paddingBottom: 0, marginBottom: 0}]),
})