import { Button, Text, View, Icon, Input, Left, Body, Item, Right } from "native-base";
import React from "react";
import { StyleSheet } from "react-native";
import { ci, fallbackArray, sflat } from "../../../utils/utils";

interface Props {
  item: any
  field: any
  onPressBtn?: any
  changeValue?: Function
  allowDrag: boolean
}

interface State {
  inputValue: any
}

export class Flistcell extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      inputValue: this.props.item[this.props.field.input_field]
    }
  }

  onInputValueChange(v){
    this.setState({inputValue: v})
    this.props.changeValue(v, this.props.field.input_field)
  }

  render() {
    const { field, item } = this.props
    return (
      <View key={item.key} style={style.container}>
        {(field.has_input && !this.props.allowDrag) &&
          <View style={style.inputCt}>
            <Input style={style.input}
              value={this.state.inputValue.toString()} onChangeText={v => this.onInputValueChange(v)} placeholder={field.input_placeholder}></Input></View>}

        {this.props.allowDrag &&
          <View style={style.inputCt}>
            <Icon style={style.dragIcon} name="reorder-two-outline" type="Ionicons"/></View>}

        <View style={{flex: 1}}>
          <Text numberOfLines={1} style={{paddingLeft: field.add_inplace ? 10 : null}}>{item.name}</Text></View>
          
        {this.props.allowDrag ? 
          null 
          :
          <View style={style.btnCtn}>
            {fallbackArray(field.right_btns).map(btn =>
              <Button transparent onPress={() => this.props.onPressBtn(btn)} style={sflat(style.cellBtnDefault, btn.style)}>
                {btn.icon && <Icon name={btn.icon.name} type={btn.icon.type} style={sflat(style.cellBtnIcon, btn.icon.style)}/>}
                {btn.text && <Text style={sflat([{}, btn.text.style])}>{btn.text.content}</Text>}
              </Button>
            )}
          </View>}
      </View>
    )
  }
}

const style = StyleSheet.create({
  dragIcon: {color: "#999", fontSize: 20, width: 40, marginLeft: 25},
  container: {flexDirection: 'row', flex: 1, height: 40, alignItems: 'center'},
  btnCtn: {flex: 0, flexDirection: 'row'},
  cellBtnDefault: {height: 30, width: 50},
  cellBtnIcon: {fontSize: 18},
  inputCt: {flex: 0, height: 50, justifyContent: 'center'},
  input: {backgroundColor: '#eee', width: 70, textAlign: 'center', marginHorizontal: 10, fontSize: 13},
})
