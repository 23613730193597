import React from 'react';
import { StyleSheet, TextInput } from 'react-native'
import { View, Text, Button, Icon, Input } from 'native-base';
import moment from 'moment';
import { default as s } from "../theme/pieces/lead-activity-warning";
import { ci, sflat } from '../utils/utils';

interface Props {
  lead: any
  onClick: Function
}

interface State {
  note: string
  helpIsOpen: boolean
  noteBoxIsOpen: boolean
}

export class LeadActivityWarning extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state={
      note: "",
      helpIsOpen: false,
      noteBoxIsOpen: false
    }
  }

  switchNoteBoxState = () => {
    this.setState( (oldState) => {
      return {noteBoxIsOpen: !oldState.noteBoxIsOpen}
    })
  }

  switchHelpState = () => {
    this.setState( (oldState) => {
      return {helpIsOpen: !oldState.helpIsOpen}
    })
  }

  onChangeNote = (currentNote: string) => this.setState({note: currentNote})

  render () {
    const { lead } = this.props
    return (
      <View style={lead.activity_late ? s.viewContainerLate : s.viewContainer} testID='activityWarning'>
        <View style={s.header}>
          <Text style={s.textName}>{`Atividade${lead.activity_late ? ' atrasada' : ''}:`}</Text>
        </View>
        <View style={s.activityDesc}>
          <Text style={s.textName} testID={lead.activity_name}>{lead.activity_name}</Text>
          <Text style={s.textDate}>{moment(lead.activity_date).format(' D/M/YY HH:mm')}</Text>
        </View>
        <View>
          <View style={{flexDirection: 'row', alignSelf: 'center'}}>
            <Button transparent onPress={() => this.switchNoteBoxState()} style={{alignSelf: 'center'}}>
              <Icon 
                name={this.state.noteBoxIsOpen ? "minus" : "plus"} 
                type="FontAwesome" 
                style={StyleSheet.flatten([s.txtNote, {marginRight: -8}])} testID='activityObservationBtn'/>
              <Text style={s.txtNote}>Adicionar observação</Text>
            </Button>
            <Button transparent onPress={() => this.switchHelpState()}>
              <Icon name="help-circle-outline" type="MaterialCommunityIcons" 
                style={StyleSheet.flatten([s.helpIcon, {color: this.state.helpIsOpen ? '#F6B12D' : '#6A8287'}])}/>
            </Button>
          </View>
          {this.state.helpIsOpen && 
            <View style={s.helpCt}>
              <Text style={s.help}>Digite aqui suas observações ao concluir ou cancelar a atividade</Text></View>}
          { this.state.noteBoxIsOpen && 
            <View style={{width: "90%", alignSelf: 'center', height: 100}}>
              <Input
                multiline={true}
                autoFocus={true}
                numberOfLines={6}
                value={this.state.note}
                onChangeText={this.onChangeNote}
                placeholder="Digite sua observação aqui..."
                style={StyleSheet.flatten([s.txtInputNotes, {backgroundColor: lead.activity_late ? '#ffebeb' : '#dbf5ff', textAlignVertical: 'top'}])}
                testID='activityObservationInput'
              />
            </View>
          }
        </View>
        <View style={s.buttons}>
          <Button style={sflat(s.btnDone, {elevation: 0})}   onPress={() => this.props.onClick('je8d63', this.state.note)} testID='concludeActivityBtn'><Text style={s.btnDoneTxt}>Concluir</Text></Button>
          <Button style={sflat(s.btnCancel, {elevation: 0})} onPress={() => this.props.onClick('dd94bd', this.state.note)} testID='cancelActivityBtn'><Text style={s.btnCancelTxt}>Cancelar</Text></Button>
        </View>
      </View>  
    )
  }
}