import Sentry from '../../../plataform/sentry';
import { Button, Container, Content, Footer, Text, View } from 'native-base';
import React from "react";
import { Clipboard, Linking, StyleSheet } from "react-native";
import { GlobalHeader } from '../../pieces/global-header';
import { LeadActionsTab } from '../../pieces/lead-actions-tab';
import { LeadHeaders } from '../../pieces/lead-headers';
import { s } from "../../theme/screens/leadView";
import { LeadSession, leadSessionCt } from '../../utils/lead-session-ct';
import { isWeb, isMob, loge, blank, simpleCalert, ifWeb, calert, ci, logScreen } from '../../utils/utils';
import conn from '../../utils/conn';
import { SelectableText } from "../../../plataform/selectable";
import { rmsg } from '../../utils/utils-view';

interface Props {navigation: any}

interface State {
  lead: any,
  loading: boolean,
  msg: any,
  msgTpls: Array<any>,
  leadBlockReason: string,
  forwardSpec: any,
  crmPhases: Array<any>,
}

export class MsgViewScreen extends React.Component<Props, State> {
  isPush: boolean = false
  leadSession: LeadSession|null = null

  constructor(props) {
    super(props);
    this.state = {
      lead: null,
      loading: false,
      msg: {},
      msgTpls: [],
      leadBlockReason: '',
      forwardSpec: {},
      crmPhases: []
    };
  }

  navParams(): any{
    return this.props.navigation.state.params
  }

  componentDidMount(){
    Sentry.addBreadcrumb({message: `mounted MsgViewScreen`})
    this.handleParams()
    logScreen('msgView')
  }

  componentDidUpdate(){
    this.checkAvailability()
  }

  onFirstStateSet = () => {
    conn.getUser().then(loggedUser => {
      const msg = this.state.msg
      if (msg &&
          msg.is_new &&
          msg.rcpt == `1.${loggedUser.id}` &&
          blank(this.state.leadBlockReason))
        this.markRead()
    })
  }

  checkAvailability = () => {
    if (!blank(this.state.leadBlockReason))
      calert({text: this.state.leadBlockReason}, this.goBack)
  }

  markRead = () => {
    if (!this.leadSession) return

    const msg = this.leadSession.msg
    if (!msg) return
    
    conn.fetch('messages/just_read', 'POST', {
      id: msg.id
    })
    .then(this.markReadSuccess)
    .catch(loge);
  }

  markReadSuccess = (resp) => {
    if (resp.success) {
      if (!this.leadSession) return
      this.leadSession.msg.is_new = resp.is_new
      this.leadSession.lead.dirty = true
      this.leadSession.reloadMsgs = true
    }
  }

  handleParams = () => {
    let { leadSessionId, msgTpls, forwardSpec, crmPhases, isPush } = this.navParams()
    this.isPush = isPush
    this.leadSession = leadSessionCt.get(leadSessionId)
    
    if (!this.leadSession) return

    this.setState({
      lead: this.leadSession.lead, 
      msg: this.leadSession.msg, 
      msgTpls: msgTpls,
      forwardSpec: forwardSpec,
      leadBlockReason: this.leadSession.lead.lead_block_reason,
      crmPhases: crmPhases,
    }, this.onFirstStateSet)
  }
 
  goBack = () => {
    if (this.isPush) 
      conn.revertToStoredUserSession().then(() => {
        this.doGoBack()
      })
    else
      this.doGoBack()
  }

  doGoBack = () => {
    this.props.navigation.goBack()
  }

  detailScreen = () => {
    if (!this.leadSession) return
    this.props.navigation.navigate("detailScreen", {leadSessionId: this.leadSession.id})
  }

  // displayStatus(status: string){
  //   switch (status) {
  //     case 'lost': return 'Perdido'
  //     case 'new': return 'NOVO'
  //     case 'negoc': return 'EM NEGOCICÃO'
  //     case 'sold': return 'Vendido'
  //     default: return ''
  //   }
  // }

  // statusStyleName(status: string){
  //   switch (status) {
  //     case 'lost': return 'cellTxtStatusLost'
  //     case 'new': return 'cellTxtStatusNew'
  //     case 'negoc': return 'cellTxtStatusNegoc'
  //     case 'sold': return 'cellTxtStatusSold'
  //     default: return ''
  //   }
  // }

  sendMail(){
    () => Linking.openURL(`mailto:${this.state.lead.customer.email}`)
  }

  reloadLead = (lead) => {
    if (!this.leadSession) 
      return

    if (lead) 
      this.leadSession.lead = lead

    this.leadSession.lead.dirty = true
    this.leadSession.reloadMsgs = true

    this.setState({lead: this.leadSession.lead})  
  }

  onTextSelection = (selection) => {
    if (selection.eventType === 'Copiar') {
      Clipboard.setString(selection.content)
    }
  }
  
  openLink = (id) => {
    const link = this.state.msg.uri_map.find(t => t.id == id)
    const toastError = {type: "toast", msg: "Não foi possível abrir este link, tente novamente ou entre em contato com nosso suporte."}
    if (link && !blank(link.url))
      Linking.canOpenURL(link.url)
        .then((yes) => {
          if (yes)
            Linking.openURL(link.url)
          else
            rmsg(toastError)
        }          
        )
        .catch(e => {
          rmsg(toastError)
          loge(e)
        })
    else
      rmsg(toastError)
  }
  
  render () {
    const {lead} = this.state

    if (!lead || !lead.customer || !lead.product)
      return <Container><Button onPress={this.goBack} style={{alignSelf: "center", marginTop: 100}}>
               <Text>back</Text>
             </Button></Container>
             
    return (
      <Container>
        <GlobalHeader 
          title={'Mensagem'}
          closeOrBack={this.isPush}
          onClose={this.goBack}
          navigation={this.props.navigation} />

        <Content style={s.content}>

          {isWeb &&
          <LeadActionsTab 
            leadSession={this.leadSession} 
            msgTpls={this.state.msgTpls || []} 
            navigation={this.props.navigation} 
            reloadLead={this.reloadLead} 
            forwardSpec={this.state.forwardSpec}
            />}

          <LeadHeaders 
            leadSession={this.leadSession} 
            navigation={this.props.navigation}
            reloadLead={this.reloadLead}
            crmPhases={this.state.crmPhases} 
            obsOrFrom={false} />

          <View style={s.msgBodyCt}>
            {isWeb && 
            <View>
            <Text style={s.msgBody}>{this.state.msg.message}</Text></View>}
            
            {isMob &&
            <SelectableText
              style={s.msgBody} 
              menuItems={["Copiar"]}
              value={this.state.msg.message}
              onSelection={this.onTextSelection} 
              highlights={this.state.msg.uri_map}
              onHighlightPress={this.openLink}
              highlightFontColor="blue">
            </SelectableText>
            }
          </View>

          <View style={{height: 50}}/>

        </Content>

        {isMob &&
        <Footer style={{backgroundColor: 'trasparent'}}>
          <LeadActionsTab leadSession={this.leadSession} msgTpls={this.state.msgTpls} 
                          navigation={this.props.navigation} reloadLead={this.reloadLead} 
                          forwardSpec={this.state.forwardSpec} /></Footer>}

      {!blank(this.state.leadBlockReason) &&
      <View style={style.overlay}></View>}
      
      </Container>
    )
  }
}

const style = StyleSheet.create({
  overlay: {
    position: 'absolute', 
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    zIndex: 10,
    backgroundColor: `rgba(0, 0, 0, ${ifWeb('0.2', '0.7')})`,
  }
})
