export default {
  flatList: {
  },
  listCell: {
    // paddingTop: 15, 
    // paddingBottom: 15,
    height: 50,
  },
  listBody: {
    flex: 1,
  },
  listTitle: {
    fontFamily: "Roboto-Medium",
    fontSize: 14,
    color: "#6A8287",
  },
  listDate: {
    flex: 0,
    fontFamily: "Roboto",
    fontSize: 13,
    color: "#6A8287",
    opacity: .75,
  },
  listInteg: {
    flex: 0,
    fontFamily: "Roboto",
    fontSize: 12,
    color: "#6A8287",
    opacity: .75,
    marginTop: 6,
    marginRight: 8,
  }
}
