import { LeadListScreen } from "../screens/lead/lead-list-screen";
import { LeadViewScreen } from "../screens/lead/lead-view-screen";
import { SigninScreen } from "../screens/public/signin-screen";
import { MsgViewScreen } from "../screens/lead/msg-view-screen";
import { MsgWriteScreen } from "../screens/lead/msg-write-screen";
import { SignupScreen } from "../screens/public/signup-screen";
import { FormScreen } from "../screens/form-screen";
import { WebviewScreen } from "../screens/webview-screen";
import { ListScreen } from "../screens/list-screen";
import { TableScreen } from "../screens/table-screen";
import { DummyScreen } from "../screens/dummy-screen";
import { BasicViewScreen } from "../screens/basic-view-screen";
import { LeadChatScreen } from "../../plataform/screens/lead-chat-screen";
import { MessageTemplate } from "../screens/lead/lead-message-template";

// Routes

export const routeListLogged = {
  basicViewScreen: { screen: BasicViewScreen },
  leadListScreen: { screen: LeadListScreen },
  messageTemplate: { screen: MessageTemplate },
  leadViewScreen: { screen: LeadViewScreen },
  msgViewScreen: { screen: MsgViewScreen },
  msgWriteScreen: { screen: MsgWriteScreen },
  formScreen: { screen: FormScreen },
  webviewScreen: { screen: WebviewScreen },
  listScreen: { screen: ListScreen },
  tableScreen: { screen: TableScreen },
  dummyScreen: { screen: DummyScreen },
  leadChatScreen: { screen: LeadChatScreen },
}

export const routeListNotLogged = {
  signinScreen: { screen: SigninScreen },
  signupScreen: { screen: SignupScreen },
}

const routeListAll = {
  ...routeListNotLogged,
  ...routeListLogged
}
export default routeListAll
