import { blank, ci, ifNullOrUndef } from "../../utils/utils"

const isInteger = (number: any) => !isNaN(number)

// TODO validate number taking into account the ddi
// +238 Cabo Verde accepts 7 digits, +55 Brazil accepts 8 or 9 digits plus DDD
const phoneIsValid = (phone: any) => {
  return phone.replace(/\D/g, '').length >= 7
}

const emailIsValid = (email: any) => {
  if (blank(email))
    return false

  email = email.trim()

  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.toLowerCase());
}

const isEmpty = (value: any) => {
  if (typeof value == "string") 
    value = value.trim()
  return blank(value)
}

const areEqual = (value1: any, value2: any) => (value1 === value2)

export const getErrorMsg = (field: any, value: any) => {
  const { validation, label } = field

  if (validation.required && isEmpty(value))
    return ifNullOrUndef(validation.required.msg_error, `O campo ${label} é obrigatório`)

  if (validation.integer && !isEmpty(value) && !isInteger(value))
    return ifNullOrUndef(validation.integer.msg_error, `O campo ${label} deve ser um número`)

  if (validation.email && !isEmpty(value) && !emailIsValid(value))
    return ifNullOrUndef(validation.email.msg_error, `O email digitado no campo ${label} é inválido`)

  if (validation.phone_number && !isEmpty(value) && !phoneIsValid(value))
    return ifNullOrUndef(validation.phone_number.msg_error, `O telefone digitado no campo ${label} é inválido`)

  return null
}

export const getErrorMsgGroup = (validation: any, groupLabels: Array<any>, values: Array<any>) => {
  let isValid = false

  if (validation.type == "non_empty")
    values.forEach(v => isValid = !isEmpty(v) || isValid)

  if (validation.type == "all_equal") {
    isValid = true
    values.forEach(v => isValid = isValid && areEqual(values[0], v))
  }

  if (isValid)
    return null

  if (validation.msg_error)
    return validation.msg_error

  let error = defaultMsgError[validation.type]

  groupLabels.forEach((label, index) => {
    error = error + label

    if (index != groupLabels.length - 1) 
      error += ", "
  })

  return error
} 

const defaultMsgError = {
  "non_empty": "Pelo menos um dos campos a seguir devem ser preenchidos: ",
  "all_equal": "Os campos a seguir devem ser iguais: "
}