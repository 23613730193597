import { ifWeb } from "../../utils/utils";

const header:any = {
    get h_left_ct() {
      return ifWeb({
        flex: 1,
      })
    },
    get h_body_ct() {
      return ifWeb({
        alignItems: "center",
        flex: 1,
      })
    },
    get h_right_ct() {
      return ifWeb({
        flex: 1,
      })
    },
    h_header: {
        backgroundColor: "#2C86A2",
    },
    h_left: {
        marginLeft: 10,
    },
    h_left_txt: {
        color: 'white',
        fontSize: 23,
    },
    h_btn: {
        color: "white",
        fontSize: 23,
    },
    h_btn_hamb: {
        color: "white",
        fontSize: 28,
    },
    h_btn_filter: {
      color: "white",
      fontSize: 26,
    },
    h_btn_gold: {
        color: "gold",
    },
    h_title: {
        color: "white", 
        fontFamily: "Roboto-Medium", 
        fontSize: 18,
    },
    h_btnTxt: {
        color: "white",
        fontSize: ifWeb(18, 16),
    },
    h_btnTxtBold: {
        fontFamily: "Roboto-Medium", 
        color: "white",
        fontSize: ifWeb(16, 16),
    }
}

export default header