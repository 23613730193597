import Sentry from '../../plataform/sentry';
import { Button, Container, Content, Form, Text, View, Icon, List, Item } from 'native-base';
import React, { Fragment } from "react";
import { ActivityIndicator, StyleSheet, TouchableOpacity, FlatList, Dimensions } from 'react-native';
import { GlobalHeader } from '../pieces/global-header';
import { s } from "../theme/screens/leadNew";
import { addKey, isWeb, blank, sflat, ci, fallbackArray, parseSpec, logScreen } from '../utils/utils';
import { menuHandler } from '../utils/menu-handler';
import conn from '../utils/conn';
import { rmsg } from '../utils/utils-view';
import { TextIcons } from '../components/text-with-icons';

interface Props {navigation: any}

interface State {
  spec: any
}

export class BasicViewScreen extends React.Component<Props, State> {
  isPush: boolean = false
  
  constructor(props) {
    super(props)
    this.state = {
      spec: {},
    }
  }

  navParams(): any{
    return this.props.navigation.state.params
  }

  componentDidMount = () => {
    Sentry.addBreadcrumb({message: `mounted BasicViewScreen`})
    this.handleParams()
  }

  handleParams = () => {
    const np = this.navParams()
    
    this.isPush = np.isPush
    const spec = parseSpec(np.spec, np)

    if (spec.load_path)
      this.fetchSpec(spec.load_path)
        .then(resp => {
          if (resp && resp.success && resp.spec) {
            logScreen('basicView', resp.spec)
            this.setState({spec: resp.spec})
          } else {
            const defaultErr = {msg: 'Erro ao abrir mensagem', type: 'e', view: 'toast'}
            rmsg((resp && resp.msg) || defaultErr)
          }
        })
    else {
      logScreen('basicView', spec)
      this.setState({spec})
    }
  }

  fetchSpec = (url) => {
    return conn.fetch(url, 'GET')
      .catch(this.onLoadError)
  }

  onLoadError = (resp) => rmsg(resp.msg)

  renderLine = (line) => {
    return (
      fallbackArray(line).map(addKey).map(item =>
        <TextIcons key={item.key} text={item.text}
          iconLeft={item.icon_left} iconRight={item.icon_right}/>
    ))
  }

  leftBtns = () => {
    if (this.state.spec.back_btn) {
      return null
    } else {
      return isWeb
        ? []
        : [<Button transparent style={s.h_left} onPress={menuHandler.open} testID='menuBtn'>
            <Icon style={s.h_btn_hamb} name='menu' /></Button>]
    }
  }

  goBack = () => {
    if (this.isPush) {
      conn.revertToStoredUserSession().then(() => {
        this.doGoBack()
      })
    } else {
      this.doGoBack()
    }
  }

  doGoBack = () => {
    this.props.navigation.goBack()
  }

  render () {
    const { spec } = this.state
    return (
      <Container style={s.contBg}>

        {/* Header */}
        <GlobalHeader
          title={spec ? spec.title : '...'}
          navigation={this.props.navigation}
          onClose={this.goBack}
          closeOrBack={this.isPush}
          left={this.leftBtns()}
          />

        {!blank(spec) &&
          <Content style={s.content} testID={spec.title}>
            {(spec.list_lines || []).map(addKey).map(line => 
              <Fragment key={line.key}>
                <View style={s2.row_style}>
                  <View style={{flexDirection: "row", flex: 1, paddingRight: 25% - Dimensions.get('window').width}}>
                    {this.renderLine(line.items_start)}</View>
                  <View>
                    {this.renderLine(line.items_end)}</View>
                </View>
                {line.down_divisor &&
                  <View style={{height: 1, backgroundColor: '#cccccc'}}></View>
                }
              </Fragment>
            )}
          </Content>
          || 
          <View style={s.loadingCtBgGray}>
            <ActivityIndicator />
            <Text style={s.loading}>Carregando...</Text></View>
        }

      </Container>
    )
  }
}

const s2 = StyleSheet.create({
  row_style: {flexDirection: 'row', marginVertical: 15, 
              justifyContent: 'space-between', flex: 1}
})
