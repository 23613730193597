// import conn from "../utils/conn";
// import { ci } from "../utils/utils";
// import axios from "axios";

export const getRemoteAnswer = (path: string, verb: string, params: string) => {

//   if (!conn.user)
//     return false

//   ci(path, params)

//   if (path == 'chatbot/fields?user_id=3220'){  
//     return axios.get('http://192.168.50.47:8080/create_step')
//     .then(response => {return response.data})
//     .catch(error => {ci("Erro no request roda")});
//   }

//   if (path == 'chatbot/spec?user_id=3220'){
//     return axios.get('http://192.168.50.47:8080/create_bot')
//     .then(response => {return response.data})
//     .catch(error => {ci("Erro no request roda")});
//   }

//   if (path == 'chatbot/edit_step?bot_id=1&id=2&user_id=3220'){  
//     const i = path.replace('chatbot/edit_step', '')

//     return axios.get(`http://192.168.50.47:8080/edit_step${i}`)
//     .then(response => {return response.data})
//     .catch(error => {ci("Erro no request roda")});
//   }

//   if (path == 'chatbot/spec?user_id=3220&object_id=1'){
//     return axios.get('http://192.168.50.47:8080/edit_chat_bot')
//     .then(response => {return response.data})
//     .catch(error => {ci("Erro no request roda")});
//   }

//   if (path == 'chatbot/list'){
//     return axios.get('http://192.168.50.47:8080/chatbot_list')
//     .then(response => {return response.data})
//     .catch(error => {ci("Erro no request roda")});
//   }

//   if (path == 'step/save'){
//     return axios.post('http://192.168.50.47:8080/step_save', {
//         item: JSON.stringify(params)
//     })
//     .then(response => {return response.data})
//     .catch(error => {ci("Erro no request roda")});
//   }
  // ci(path, params, 1123)

  // if (path.includes('chatbot/fields')){
  //   const i = path.replace("chatbot/fields", '')

  //   return axios.get(`http://192.168.50.46:8080/create_step${i}`)
  //   .then(response => {return response.data})
  //   .catch(error => {ci("Erro no request roda")})
  // }

  // if (path == 'chatbot/spec?user_id=3220'){
  //   return axios.get('http://192.168.50.46:8080/create_bot')
  //   .then(response => {return response.data})
  //   .catch(error => {ci("Erro no request roda")})
  // }

  // if (path.includes('chatbot/edit_step')){  
  //   const i = path.replace('chatbot/edit_step', '')

  //   return axios.get(`http://192.168.50.46:8080/edit_step${i}`)
  //   .then(response => {return response.data})
  //   .catch(error => {ci("Erro no request roda")})
  // }

  // if (path.includes("chatbot/spec") == true){
  //   const i = path.replace("chatbot/spec", '')

  //   return axios.get(`http://192.168.50.46:8080/edit_chat_bot${i}`)
  //   .then(response => {return response.data})
  //   .catch(error => {ci("Erro no request roda")})
  // }

  // if (path == 'chatbot/list'){
  //   return axios.get('http://192.168.50.46:8080/chatbot_list')
  //   .then(response => {return response.data})
  //   .catch(error => {ci("Erro no request roda")})
  // }

  // if (path == 'bot/save'){
  //   return axios.post('http://192.168.50.46:8080/bot_save',{
  //       item: JSON.stringify(params)
  //   })
  //   .then(response => {return response.data})
  //   .catch(error => {ci("Erro no request roda")})
  // }

  // if (path.includes('step/save')){
  //   const i = path.replace("step/save", '')

  //   return axios.post(`http://192.168.50.46:8080/step_save${i}`,{
  //       item: JSON.stringify(params)
  //   })
  //   .then(response => {return response.data})
  //   .catch(error => {ci("Erro no request roda")})
  // }

  // if (path.includes('chatbot/delete')){
  //   const i = path.replace("chatbot/delete", '')

  //   return axios.delete(`http://192.168.50.46:8080/delete_bot${i}`)
  //   .then(response => {return response.data})
  //   .catch(error => {ci("Erro no request roda")})
  // }

    // if (path == 'contacts/spec?user_id=3220')
    //   return {
    //     "success": true,
    //     "spec": {
    //       "submit_path": "groups/save",
    //       "save_key": "item",
    //       "title": "Editar Grupo",
    //       "save_btn_bottom": true,
    //       "save_btn_header": true,
    //       "back_btn": true,
    //       "sections": [
    //         {
    //           "title": "Usuários",
    //           "title_complement": "(11)",
    //           "name": "usuarios",
    //           "closeable": true,
    //           "is_visible": false,
    //           "section_style": {
    //             "paddingBottom": 15
    //           },
    //           "fields": [
    //           {
    //               "type": "list",
    //               "name": "user_list",
    //               "add_inplace": true,
    //               "inplace_placeholder": 'Insira o text',
    //               "right_btns": [
    //                 {
    //                   "action": "removeItem",
    //                   "icon": {
    //                     "type": "FontAwesome",
    //                     "name": "trash-o",
    //                     "style": {
    //                       "color": "#ea7777",
    //                       "fontSize": 24
    //                     }
    //                   }
    //                 }
    //               ],
    //             }
    //           ]
    //         },
    //       ]
    //     },
    //   }

    // if (path.includes(`contacts/search_spec`) == true)
    //   return {
    //     "success": true,
    //     "spec": {
    //       "title": "Busca",
    //       "no_required_fields": true,
    //       "submit_path": "dummy_never_called",
    //       "go_back_after_save_success": false,
    //       "list_ds_filter_override": {
    //           "fast_search": true
    //       },
    //       "form_memory_key": "lead_search-15990",
    //       "form_style": {
    //           "alignItems": "center"
    //       },
    //       "back_btn": true,
    //       "sections": [
    //           {
    //               "fields": [
    //                   {
    //                       "type": "string",
    //                       "label": "Digite os dados do cliente ou imóvel...",
    //                       "placeholder": "Digite os dados do cliente ou imóvel...",
    //                       "name": "keyword",
    //                       "ct_txt_stle": {width: '80%'},
    //                       "ct_style": {alignItems: 'center'}
    //                   },
    //                   {
    //                       "type": "switch",
    //                       "label": "Incluir arquivados no resultado",
    //                       "name": "show_lost",
    //                       "ct_style": {
    //                           "marginLeft": 10,
    //                           "width": 320,
    //                           "alignSelf": "center",
    //                           "marginBottom": 16
    //                       },
    //                       "disable_underline": true
    //                   },
    //                   {
    //                       "type": "select",
    //                       "label": "Status",
    //                       "placeholder": "Todos",
    //                       "opts": [
    //                           {
    //                               "name": "Novo",
    //                               "id": 0
    //                           },
    //                           {
    //                               "name": "Em Negociação",
    //                               "id": 1
    //                           },
    //                           {
    //                               "name": "Negócio fechado",
    //                               "id": 2
    //                           },
    //                           {
    //                               "name": "Arquivado",
    //                               "id": 3
    //                           }
    //                       ],
    //                       "name": "status"
    //                   },
    //                   {
    //                       "type": "select",
    //                       "placeholder": "Todos",
    //                       "label": "Canal",
    //                       "opts": [
    //                           {
    //                               "name": "Internet",
    //                               "id": 1
    //                           },
    //                           {
    //                               "name": "Showroom",
    //                               "id": 2
    //                           },
    //                           {
    //                               "name": "Telefone",
    //                               "id": 3
    //                           },
    //                           {
    //                               "name": "Rede Social",
    //                               "id": 4
    //                           },
    //                           {
    //                               "name": "Importado da planilha",
    //                               "id": 5
    //                           },
    //                           {
    //                               "name": "Internet (manual)",
    //                               "id": 6
    //                           },
    //                           {
    //                               "name": "Whatsapp",
    //                               "id": 7
    //                           }
    //                       ],
    //                       "name": "channel_id"
    //                   },
    //                   {
    //                       "type": "select",
    //                       "label": "Fonte",
    //                       "opts": [
    //                           {
    //                               "name": "Edit Font Detox - 371579043",
    //                               "id": 12109
    //                           },
    //                           {
    //                               "name": "Font Detox - 100951230",
    //                               "id": 12110
    //                           },
    //                           {
    //                               "name": "Font Detox - 472587859",
    //                               "id": 12107
    //                           }
    //                       ],
    //                       "placeholder": "Todos",
    //                       "name": "lead_source_id",
    //                       "ct_style": {}
    //                   },
    //                   {
    //                       "type": "select",
    //                       "placeholder": "Todos",
    //                       "label": "Corretor",
    //                       "opts": [
    //                           {
    //                               "id": -5408,
    //                               "name": "[grupo] Group Detox - 324647079"
    //                           },
    //                           {
    //                               "id": 15990,
    //                               "name": "Mr.Detox2"
    //                           },
    //                           {
    //                               "id": 15995,
    //                               "name": "Teste Username 761184311 - Editado"
    //                           }
    //                       ],
    //                       "name": "seller_id",
    //                       "ct_style": {}
    //                   },
    //                   {
    //                       "type": "select",
    //                       "label": "Tipo",
    //                       "placeholder": "Todos",
    //                       "opts": [
    //                           {
    //                               "name": "Compra",
    //                               "id": 0
    //                           },
    //                           {
    //                               "name": "Aluguel",
    //                               "id": 1
    //                           },
    //                           {
    //                               "name": "Lançamento",
    //                               "id": 3
    //                           },
    //                           {
    //                               "name": "Captação",
    //                               "id": 4
    //                           },
    //                           {
    //                               "name": "Indefinido",
    //                               "id": 2
    //                           }
    //                       ],
    //                       "name": "type_negotiation",
    //                       "ct_style": {}
    //                   },
    //                   {
    //                       "type": "string",
    //                       "label": "Preço de",
    //                       "name": "price_from",
    //                       "ct_style": {}
    //                   },
    //                   {
    //                       "type": "string",
    //                       "label": "Preço até",
    //                       "name": "price_to",
    //                       "ct_style": {}
    //                   },
    //                   {
    //                       "type": "date",
    //                       "has_clear_button": true,
    //                       "label": "A partir de",
    //                       "name": "date_from",
    //                       "label_style": {
    //                           "marginLeft": 0
    //                       },
    //                       "txt_style": {
    //                           "fontSize": 15,
    //                           "paddingLeft": 7
    //                       }
    //                   },
    //                   {
    //                       "type": "date",
    //                       "has_clear_button": true,
    //                       "label": "Até",
    //                       "name": "date_to",
    //                       "label_style": {
    //                           "marginLeft": 0
    //                       },
    //                       "txt_style": {
    //                           "fontSize": 15,
    //                           "paddingLeft": 7
    //                       }
    //                   },
    //                   {
    //                       "type": "date",
    //                       "has_clear_button": true,
    //                       "label": "Tarefa em",
    //                       "name": "activity_for",
    //                       "label_style": {
    //                           "marginLeft": 0
    //                       },
    //                       "txt_style": {
    //                           "fontSize": 15,
    //                           "paddingLeft": 7
    //                       }
    //                   }
    //               ]
    //           }
    //       ],
    //       "groups_validations": [
    //         {
    //           "type": "non_empty",
    //           "fields": [
    //             "keyword",
    //             "status",
    //             "channel_id",
    //             "lead_source_id",
    //             "seller_id",
    //             "type_negotiation",
    //             "date_from",
    //             "date_to",
    //             "activity_for",
    //             "price_from",
    //             "price_to"
    //           ]
    //         }
    //       ],
    //       "save_btn_header": {
    //         "txt": "Pesquisar"
    //       },
    //       "save_btn_bottom": {
    //         "txt": "Pesquisar",
    //         "style_txt": {
    //           "color": "white",
    //           "fontSize": 15,
    //           "textAlign": "center"
    //         },
    //         "style_btn": {
    //           "backgroundColor": "#10B1E3",
    //           "width": 101,
    //           "elevation": 0
    //         }
    //       },
    //       "clear_btn": {
    //         "txt": "Limpar",
    //         "style_txt": {
    //           "color": "#999",
    //           "fontSize": 15,
    //           "textAlign": "center"
    //         },
    //         "style_btn": {
    //           "backgroundColor": "transparent",
    //           "borderColor": "#999",
    //           "elevation": 0,
    //           "borderWidth": 2,
    //           "marginLeft": 20,
    //           "width": 90,
    //           "paddingLeft": 5
    //         },
    //         "style_ct": {
    //           "marginTop": -50,
    //           "marginLeft": 0
    //         }
    //       },
    //       "reset_btn": {
    //         "txt": "Limpar"
    //       },
    //       "btns_ct": {
    //         "paddingTop": 20,
    //         "paddingBottom": 70,
    //         "flexDirection": "row"
    //       }
    //     },
    //     "object": {},
    //     "companies": [
    //       {
    //         "id": 2889,
    //         "name": "Detox 2"
    //       }
    //     ]
    // }

    return null
    }

