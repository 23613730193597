import { StyleSheet } from "react-native";
import { ifWeb } from "../../utils/utils";

export default {
  section: {
    backgroundColor: "#e5e5e5",
    padding: 10,
    marginBottom: ifWeb(15, 0),
    width: "100%",
    textAlign: "center",
  },
  sectionTxt: {
    fontSize: 18,
    color: "#2c86a1",
    textAlign: "center",   
  },
  label: {color: '#909090', fontSize: 16,},
  textArea: {
    height: 150, 
    paddingTop: 15,
    paddingHorizontal: 15,
    marginTop: 35,
    marginRight: 15,
    fontFamily: "Roboto",
    fontSize: 14,
    color: "#6A8287",
    borderWidth: 2*StyleSheet.hairlineWidth,
    borderColor: '#ccc',
  },
  submitCt: {marginTop: 0, flex: 0, alignSelf: "center"},
  formItem: {borderBottomWidth: StyleSheet.hairlineWidth, marginTop: ifWeb ? 10 : 15, paddingBottom: ifWeb ? 10 : 5},
  formItems: {paddingLeft: 15, marginBottom: 45},
  footNotesCt: {marginBottom: 40, flex: 1},
  footNotesTxt: {alignSelf: 'center', fontSize: 12, color: '#bbb'},
  sectionHelp: {fontSize: 24, color: '#bbb', padding: 2},
  sectionHelpActive: {fontSize: 24, color: '#40879f', padding: 2},
  help: {backgroundColor: '#ffedca', borderColor: '#F6B12D', borderWidth: StyleSheet.hairlineWidth, 
      borderRadius: 5, padding: 10, fontSize: 13, color: '#888', marginRight: 15},
  helpCt: {borderBottomWidth: StyleSheet.hairlineWidth, borderColor: '#ccc', paddingBottom: 10},
  pass_mob_btn_style: {fontSize: 22, paddingTop: 35, paddingRight: 15},
  pass_web_btn_style: {fontSize: 22, paddingTop: 28, paddingLeft: 10}
}

