import { Button, Container, Icon, Text, View } from 'native-base';
import React from "react";
import { ActivityIndicator, Linking } from "react-native";
import { WebView } from 'react-native-webview';
import { GlobalHeader } from '../pieces/global-header';
import { s } from "../theme/screens/leadView";
import conn from '../utils/conn';
import { isVis, isWeb, loge, appendUrlParam, hash2params, isMob, parseSpec, logScreen, ci } from '../utils/utils';
import Sentry from '../../plataform/sentry';
import { menuHandler } from '../utils/menu-handler';
import { Config, isDev } from '../config';
import { rmsg } from '../utils/utils-view';

interface Props {navigation: any}

interface State {
  firstLoading: boolean,
  spec: any,
}

export class WebviewScreen extends React.Component<Props, State> {
  urlAuth: string | null = null
  headers = {}

  constructor(props) {
    super(props)

    this.state = {
      firstLoading: true,
      spec: {}
    }

    this.setCustomHeaders()
  }

  static navigationOptions = {
    gesturesEnabled: false,
  }
  
  setCustomHeaders(){
    const basicAuth = conn.getBasicAuth()
    if (basicAuth)
      this.headers[basicAuth[0]] = basicAuth[1]
  }

  componentDidMount(){
    Sentry.addBreadcrumb({message: `mounted WebviewScreen`})

    const np = this.navParams()
    const spec = parseSpec(np.spec, np)
    logScreen('webview', spec)

    this.brushUrl(spec.load_path, spec.extra_params)
      .then(url => {
        spec.load_path = url
        this.setState({spec: spec})
      })
  }

  brushUrl = (url, extraParams) => {
    if (!/^http/i.test(url))
      url = `${Config.baseUrl}${url}`
      
    return conn.urlAuth()
      .then(urlAuth => {
        let params = {...extraParams}

        if (!this.state.spec.no_ua)
          params.ua = urlAuth

        return appendUrlParam(url, hash2params(params))
      })
  }

  navParams(): any{
    return this.props.navigation.state.params
  }

  goBack = () => this.props.navigation.goBack()

  onError = (e) => {
    loge(e)
  }

  onLoadStart = (_) => {}
  onLoadEnd = (_) => {}

  onLoad = (_) => {
    if (this.state.firstLoading)
      this.setState({firstLoading: false})
  }

  onMessage = async (event) => {
    try {
      const data = JSON.parse(event.nativeEvent.data)
      if (!data)
        return

      switch (data.cmd) {
        case 'open_link':
          this.openLink(data.link)
          break;
      
        default:
          break;
      }
    } catch (e) {
      loge(e)
      if (isDev)
        rmsg("Erro ao processar evento")
    }
  }

  openLink = async (link) => {
    if (!link) 
      return

    try {
      if (await Linking.canOpenURL(link))
        Linking.openURL(link)
      else
        rmsg("Não é possível abrir o link. Verifique se ele é válido e se você tem o programa apropriado para abrí-lo.")

    } catch (e) {
      loge(e)
      rmsg("Erro ao abrir link.")
    }
  }

  left = () => {
    if (this.state.spec.back_btn) {
      return null
    } else {
      return isWeb
        ? []
        : [<Button transparent style={s.h_left} onPress={menuHandler.open} testID='menuBtn'>
            <Icon style={s.h_btn_hamb} name='menu' /></Button>]
    }
  }
  
  render () {
    let webviewExtraProps: any = {}
    
    if (isMob && this.state.spec.inject_js)
      webviewExtraProps.injectedJavaScript = this.state.spec.inject_js

    return (
      <Container>
        <GlobalHeader 
          title={this.state.spec.title || '...'} 
          navigation={this.props.navigation} 
          left={this.left()} />

        <View style={s.content}>
          {this.state.firstLoading &&
            <View style={s.graphLoadingCt}>
              <ActivityIndicator />
              <Text style={s.graphLoading}>Carregando...</Text></View>}

          {this.state.spec.load_path && (
            isWeb ?
              <iframe 
                frameBorder={0}
                style={{width: '100%', height: '100%', visibility: isVis(this.state.firstLoading)}} 
                src={this.state.spec.load_path} 
                onLoad={this.onLoad} 
                onLoadStart={this.onLoadStart}
                onError={this.onError} />
            :
              // https://blog.logrocket.com/the-complete-guide-to-react-native-webview/
              <WebView
                source={{
                  uri: this.state.spec.load_path,
                  headers: {...this.headers}
                }}
                onMessage={this.onMessage}
                style={{backgroundColor: 'transparent', opacity: this.state.firstLoading ? 0 : 1}}
                onError={this.onError}
                onLoad={this.onLoad}
                onLoadEnd={this.onLoadEnd}
                onLoadStart={this.onLoadStart}
                {...webviewExtraProps}
                ></WebView>)}
            
        </View>

      </Container>
    )
  }
}
