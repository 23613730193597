import { Container, Content, Text, View } from 'native-base';
import React from "react";
import { ActivityIndicator, BackHandler } from 'react-native';
import { GlobalHeader } from '../pieces/global-header';
import { s } from "../theme/screens/obs";
import Sentry from '../../plataform/sentry';
import nemo from '../utils/nemo';
import { menuHandler } from '../utils/menu-handler';

interface Props {navigation: any}

interface State {
}

export class DummyScreen extends React.Component<Props, State> {
  backHandler: any

  public readonly state: State = {
  }

  navParams(): any{
    return this.props.navigation.state.params
  }

  componentDidMount(){
    Sentry.addBreadcrumb({message: `mounted DummyScreen`})
    this.props.navigation.navigate('leadListScreen')
    this.backHandler = BackHandler.addEventListener(
      "hardwareBackPress",
      this.onHardwareBackPress
    );
  }

  componentWillUnmount() {
    if (this.backHandler)
      this.backHandler.remove()
  }

  onHardwareBackPress = () => {
    const preventGoBack = this.shouldPreventGoBack()

    if (preventGoBack)
      menuHandler.open()

    return preventGoBack
  }

  shouldPreventGoBack = () => {
    if (
      nemo && 
      nemo.stack && 
      Array.isArray(nemo.stack) && 
      nemo.stack.length >= 3
    ) {
      return nemo.stack[nemo.stack.length - 2] == 'dummyScreen' // = going to dummyScreen
    }

    return false
  }

  render () {
    return (
      <Container>
        <GlobalHeader 
          title={''}
          navigation={this.props.navigation} 
          left={[]}
          right={[]} />

        <Content style={s.content}>
          <View style={s.fullloader}>
            <ActivityIndicator />
            <Text style={s.graphLoading}>Carregando...</Text></View>
        </Content>
      </Container>
    )
  }
}
