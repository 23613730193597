import { Body, Container, List, ListItem, Text, View, Button, Input, Item, Icon, Right, Spinner } from 'native-base';
import React from "react";
import { ActivityIndicator, Linking, StyleSheet } from "react-native";
import { GlobalHeader } from '../pieces/global-header';
import { s } from "../theme/screens/forward";
import conn from '../utils/conn';
import { Fsel2 } from "../lib/remote-form/fields/fsel2"
import { rmsg } from "../utils/utils-view"
import { addKey, applyTpl, blank, ci, fallbackArray, ifAndroid, ifIos, ifWeb, isWeb, loge, normStr, objInList, sflat, parseSpec, uuidv4, calert, logScreen, hash2params } from '../utils/utils';
import Sentry from '../../plataform/sentry';
import { memStore } from '../utils/mem-store';
import { menuHandler } from '../utils/menu-handler';
import { StackActions } from '@react-navigation/native';
// import { Player } from '@react-native-community/audio-toolkit';

interface Props {navigation: any}

interface State {
  loading: boolean
  list: Array<any>
  search: string
  selectSearch: any
  spec: any
  textRequestLoading: boolean,
  customActionsBtnStates: any,
  remoteFilter: any,
}

export class ListScreen extends React.Component<Props, State> {
  listPickup: any|null = null
  listPickupSessionId: any;
  objectSessionId: any;
  callbackSessionId: any;
  willFocusListener: any
  firstFocus = true
  customActionsBtnStates = {}

  static navigationOptions = {
    gesturesEnabled: false,
  }

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      list: [],
      search: '',
      spec: {},
      selectSearch: null,
      textRequestLoading: false,
      customActionsBtnStates: {},
      remoteFilter: {},
    }

    // this.willFocusListener = this.props.navigation.addListener('willFocus', this.onWillFocus)
  }

  componentDidMount(){
    Sentry.addBreadcrumb({message: `mounted ListScreen`})
    this.handleParams()
  }

  componentWillUnmount(){
    // this.willFocusListener.remove();

    if (this.listPickupSessionId)
      memStore.destroy(this.listPickupSessionId)

    if (this.callbackSessionId)
      memStore.destroy(this.callbackSessionId)

    if (this.objectSessionId)
      memStore.destroy(this.objectSessionId)
  }

  // onWillFocus = () => {
  //   if (this.firstFocus) {
  //     this.firstFocus = false
  //   } else {
  //     if (this.listPickupSessionId){
  //       let sessionObj = memStore.get(this.listPickupSessionId).obj
  //       if (sessionObj.doFullReload)
  //         this.load()
  //       memStore.destroy(this.listPickupSessionId)
  //       this.listPickupSessionId = null
  //     }
  //   }
  // }

  navParams(): any{
    return this.props.navigation.state.params
  }

  handleParams = () => {
    const { spec, listPickupSessionId } = this.navParams()
    if (listPickupSessionId)
      this.listPickup = memStore.get(listPickupSessionId).obj

    const _spec = parseSpec(spec)
    logScreen('list', _spec)
    this.setState({spec: _spec}, this.load)
  }
  
  // load
  
  load = (showLoader = true) => {
    if (showLoader)
      this.setState({loading: true})

    let path = this.state.spec.load_path

    if (!blank(this.state.remoteFilter))
      path = `${path}?${hash2params(this.state.remoteFilter)}`

    conn.fetch(path, 'GET')
      .then(this.onloadSuccess)
      .catch(this.onloadError)
  }

  onloadSuccess = (resp: any) => {
    let list: any[] = []
    resp.list.forEach(x => {
      if (this.listPickup)
        if (this.listPickup.list.find(i => i.id == x.id))
          x.selected = true
      list.push(x)
    })

    this.setState({
      loading: false, 
      list: list.map(addKey),
      ...(resp.spec || {})
    })
  }

  onloadError = (error: any) => {
    this.setState({loading: false})
    rmsg('Erro ao carregar lista', {type: 'e'})
    loge(error, "list onloadError")
  }

  // util

  goBack = () => this.props.navigation.goBack()

  updateListPickup = (item) => {
    const tappedIdx = this.listPickup.list.findIndex(listPickupItem => 
      listPickupItem.id == item.id
    )
    if (tappedIdx == -1)
      this.listPickup.list.push(item)
    else
      this.listPickup.list.splice(tappedIdx, 1)
  }

  getUpdatedList = () => {
    return this.state.list.map(item => {
      const newItem = {...item}
      newItem.selected = objInList(newItem, this.listPickup.list)
      return newItem
    })
  }

  requestSuccess = (resp: any) => {
    if (resp.msg)
      rmsg(resp.msg)

    if (resp.navigation_pop) {
      const popAction = StackActions.pop(resp.navigation_pop)
      this.props.navigation.dispatch(popAction)
    
    } else if (resp.update_list || resp.update_select) {
      this.setState((oldState) => {
        let update: any = {}
        
        if (resp.update_list)
          update.list = resp.update_list

        if (resp.update_select)
          update.spec = {...oldState.spec, select: resp.update_select}

        return update
      })
      this.forceUpdate()
    }
  }

  makeRequest = (request: any) => {
    this.setState({textRequestLoading: true})
    conn.fetch(request.path, request.verb, {...request.params}
    ).then(this.requestSuccess
    ).catch(e => loge(e)
    ).finally(() => this.setState({textRequestLoading: false}))
  }

  tapConfirmation = (btn, item) => {
    const { confirmation } = btn

    if (!confirmation) {
      this.makeRequest(item.request)
      return
    }

    calert({
      text: this.makeConfirmText(confirmation, item), 
      okText: confirmation.ok_text || "Confirmar",
      showCancel: true,
    }, (ok) => {ok && this.makeRequest(item.request)})
  }

  makeConfirmText = (confirmation: any, item: any) => {
    if (blank(confirmation.text))
      return 'Tem certeza que deseja realizar essa ação?'
    else
      return applyTpl(confirmation.text, item)
  }

  navigate = (btn: any, item?: any) => {
    const navigate = item.tap_navigation || btn.tap_navigation
    this.props.navigation.navigate({
      routeName: navigate.route_name,
      params: navigate.params,
      key: navigate.route_name + '-' + Math.random() * 1000000
    })
  }

  itemTapped = (item?: any) => {
    if (!item) return
    if (this.listPickup) {
      this.updateListPickup(item)
      const list = this.getUpdatedList()
      this.setState({list: list})
    }

    if (!this.listPickup) {
      const btn = item.cell_tap_btn || this.state.spec.cell_tap_btn
      if (btn)
        this[btn.action].call(this, btn, item)
    }
  }

  itemLongTapped = (item?: any) => {
    if (!item) 
      return

    const btn = item.cell_longtap_btn || this.state.spec.cell_longtap_btn

    if (btn)
      this[btn.action].call(this, btn, item)
  }

  _playSong = (btn: any, item?: any) => {
    this.playSong(btn.audio_url)
  }

  // FIXME audio toolkit was commented out for web
  playSong = (url) => {
    // const player = new Player(url, {
    //   autoDestroy: true
    // })
    // player.play()
  }

  doSave = (callback) => {
    const defaultError = 'Erro ao salvar. Tente novamente, e se o problema continuar, entre em contato com nosso suporte.'

    const selectedIds = this.state.list
      .filter(i => i.selected)
      .map(i => i.id)

    conn.fetch(this.state.spec.submit_path, 'POST', {
      selectedIds: selectedIds

    }).then(resp => {
      if (resp.success) {
        callback && callback(resp)
      } else {
        rmsg(resp.msg || defaultError)
      }
    }).catch((error) => {
      loge(error)
      rmsg(defaultError, {type: 'e'})
    })
  }

  cellSt = (item) => {
    const color = item.selected && this.state.spec.selected_bg_color
    return {backgroundColor: color || 'transparent'}
  }

  saveTapped = (btn) => {
    if (!this.listPickup) 
      return
      
    if (this.listPickup.onSave) {
      this.listPickup.onSave(this.listPickup.list)
      this.goBack()
    } else if (this.state.spec.lazySave) {
      this.listPickup.dirty = true
      this.goBack()
    } else {
      this.doSave(resp => {
        this.listPickup.doFullReload = true
        this.listPickup.onUpdate()
        rmsg(resp.msg || 'Lista salva com sucesso')
        this.goBack()
      })
    }
  }

  goToPickup = (btn) => {
    const list = this.state.list.map(x => {
      return {id: x.id}
    })

    this.listPickupSessionId = memStore.create({
      list: list,
      onUpdate: this.load
    }).id

    // https://github.com/react-navigation/react-navigation/issues/2882#issuecomment-392258586
    this.props.navigation.navigate({
      routeName: 'listScreen',
      params: {
        listPickupSessionId: this.listPickupSessionId, 
        spec: btn.spec
      },
      key: 'listScreen-' + Math.random() * 1000000,
    })
  }
  
  goToEdit = (btn) => {
    if (this.callbackSessionId) memStore.destroy(this.callbackSessionId)

    this.callbackSessionId = memStore.create({
      onSave: (success) => {
        if (success)
          this.load()
      },
      onChangeObject: (newList, success) => {
        if (success)
          this.setState({list: newList})
      }
    }).id

    this.props.navigation.navigate({
      routeName: btn.route,
      params: {
        spec: btn.spec, 
        callbackSessionId: this.callbackSessionId,
      },
      key: btn.route + Math.random() * 1000000
    })
  }

  goToEditItem = (btn, item) => {
    if (this.objectSessionId) memStore.destroy(this.objectSessionId)
    if (this.callbackSessionId) memStore.destroy(this.callbackSessionId)

    this.objectSessionId = memStore.create({
      ...item,
      onSave: (success) => {
        if (success)
          this.load()
      }
    }).id

    this.callbackSessionId = memStore.create({
      onSave: (success) => {
        if (success)
          this.load()
      },
      onChangeObject: (newList, success) => {
        if (success)
          this.setState({list: newList})
      }
    }).id

    this.props.navigation.navigate({
      routeName: btn.route,
      params: {
        spec: btn.spec, 
        objectSessionId: this.objectSessionId,
        callbackSessionId: this.callbackSessionId,
      },
      key: btn.route + Math.random() * 1000000
    })
  }

  getStyleIconCheck = (item: any) => ({color: item.selected ? 'green' : '#ccc', fontSize: 25})
  getIconCheck = (item: any) => (item.selected ? "checkbox-marked-outline" : "checkbox-blank-outline")

  reloadWithParams(btn: any) {
    this.setState((prevState) => {
      let cabs = prevState.customActionsBtnStates
      cabs[btn.id] = !cabs[btn.id]
      let newState: any = {
        customActionsBtnStates: cabs, 
        remoteFilter: cabs[btn.id] ? btn.params : {}
      }
      return newState
    }, this.load)
  }

  rightBtnsIcon(btn){
    const hasActiveVariant = btn.active_icon
    if (!hasActiveVariant)
      return btn.icon
    const isActive = this.state.customActionsBtnStates[btn.id]
    return isActive ? btn.active_icon : btn.icon
  }

  buildRightBtn(btn){
    const icon = this.rightBtnsIcon(btn)
    return <Icon style={sflat(s2.iconRightBtn, icon.style)} type={icon.type} name={icon.name} />
  }

  rightBtns() {
    return (this.state.spec.right_btns || []).map(btn =>
      <Button transparent style={StyleSheet.flatten([s2.rightBtn, btn.style])} onPress={() => this[btn.action].call(this, btn)} testID={btn.action}>
        {btn.icon &&
          this.buildRightBtn(btn)}
        {btn.txt &&
          <Text style={btn.txt.style}>{btn.txt.text}</Text>}
      </Button>
    )
  }

  leftBtns = () => {
    if (this.state.spec.back_btn) {
      return null
    } else {
      return isWeb
        ? []
        : [<Button transparent style={s.h_left} onPress={menuHandler.open} testID='menuBtn'>
            <Icon style={s.h_btn_hamb} name='menu' /></Button>]
    }
  }

  keywordFilter = (item) => {
    if (!this.state.search)
      return true
    return normStr(item.name).indexOf(normStr(this.state.search)) != -1 
  }

  selectFilter = (item) => {
    if (this.state.selectSearch === null || this.state.selectSearch === undefined)
      return true

    const select = this.state.spec.select
    if (!select) return true

    const key = select.filter_key
    if (!key) return true

    if (this.state.selectSearch == ':show_all')
      return true
      
    return item[key] == this.state.selectSearch
  }

  showRight = (data) => {
    return !blank(data.__pills) || !blank(this.state.spec.item_btns) || !blank(data.item_blocks) || !blank(data.item_icons)
  }

  openLink = (_, item) => {
    Linking.canOpenURL(item.url).then(async y => {
      if (y)
        Linking.openURL(item.url)
      else
        rmsg("Não é possível abrir o Link. Tente novamente e se o problema continuar entre em contato com o suporte.", {type: 'e'})
    })
  }

  reLayout = () => {
    this.setState((oldState) => {
      const newState = {...oldState}
      return newState
    })
  }

  render () {
    const { spec } = this.state
    const listItems = this.state.list.filter(this.keywordFilter).filter(this.selectFilter)
    return (
      <Container style={{backgroundColor: 'rgb(242, 244, 247)'}} testID={spec.title}>
        <GlobalHeader 
          title={spec.title || '...'} 
          navigation={this.props.navigation} 
          right={this.rightBtns()}
          left={this.leftBtns()}
          />

        {/* loaded */}
        {!this.state.loading && this.state.list.length > 0 &&
          <>

          {/* top text */}
          {!blank(spec.top_text) &&
            <View style={s2.topTextCt}>
              <Text style={s2.topText} testID={spec.top_text}>{this.state.spec.top_text}</Text>
            </View>
          }

          {/* text request */}
          {spec.text_request &&
            <View style={{height: 30, alignSelf: 'center'}}>
              <Button transparent style={sflat(s2.txtRequestCt, spec.text_request.style_ct)} onPress={() => this.makeRequest(spec.text_request.request)}>
                <Text style={sflat(s2.txtRequestTxt, spec.text_request.style_txt)}>{spec.text_request.text}</Text>
                {this.state.textRequestLoading && <Spinner color="blue" size={15}/>}</Button>
            </View>
          }

          {/* search */}
          {(this.listPickup || this.state.spec.show_search) && // && listItems.length > 10
            <>
            <View style={s2.searchCt} testID='search_field'>
              <View style={{minWidth: 300}}>
                <Item>
                  <Icon name='filter-outline' type="MaterialCommunityIcons" style={{color: '#999', marginTop: 4}} />
                  <Input placeholderTextColor='#ccc' selectTextOnFocus={false} placeholder="Busca..." testID="list_search"
                        value={this.state.search} onChangeText={(search) => this.setState({search})} />
                  {!!this.state.search &&
                  <Icon name="ios-close" style={ifWeb({marginLeft: 12}, {})} onPress={() => this.setState({search: ''})} testID="clear_list_search"/>}
                </Item>
              </View>

            </View>
            </>
          }

          {/* select */}
          {spec.select &&
            <View style={{height: 50, zIndex: 2, alignSelf: 'center'}}>
              <Fsel2 
                field={spec.select} 
                onChange={filterSel => this.setState({selectSearch: filterSel})}
                onFocus={() => null} 
                onBlur={() => null}
                reLayout={this.reLayout}/>
            </View>
          }

          {/* list */}
          <View style={s.content} testID="list">

            <List dataArray={listItems}
              style={[s.flatList, {marginBottom: 40, marginTop: 10}, this.state.spec.style_list]} 
              renderRow={data =>
                <ListItem onLongPress={() => this.itemLongTapped(data)} onPress={() => this.itemTapped(data)} style={sflat(s.listCell, this.cellSt(data))} key={data.key} underlayColor="transparent" testID={data.name} {...spec.list_item_extra_props} >
                  {this.listPickup &&
                    <Icon type={"MaterialCommunityIcons"}
                      name={this.getIconCheck(data)} style={this.getStyleIconCheck(data)} testID="checkbox"/>}              
                  <Body style={s.listBody} >
                    <Text numberOfLines={1} style={sflat(s.listTitle, data.style_name)}>{data.name}</Text></Body>
                  {this.showRight(data) &&
                  <Right style={s2.cellRight} testID="cellListEditBtn">
                    <>
                    {fallbackArray(data.item_icons).map(icon =>
                      <Icon key={uuidv4()} style={sflat([{paddingTop: 5, paddingBottom: 5}, icon.style])} name={icon.name} type={icon.type} testID='listForwardBtn'/>)}
                      
                    {fallbackArray(data.item_blocks).map(block =>
                      <View key={uuidv4()} style={sflat(s2.blockCt, block.style_ct, {alignSelf: 'center'})}>  
                        <Text style={sflat(s2.blockTxt, block.style_text)}>
                          {block.text}</Text></View>)}

                    {fallbackArray(data.__pills).map(pill =>
                      <View key={uuidv4()} style={sflat(s2.pillCt, pill.style_ct)} testID={data.name + "." + pill.text}>  
                        <Text style={sflat(s2.pillTxt, pill.style_text)}>
                          {pill.text}</Text></View>)}

                    {fallbackArray(this.state.spec.item_btns).map(btn =>
                      <Button key={uuidv4()} transparent style={sflat(s2.itemBtn, btn.style)} onPress={() => this[btn.action].call(this, btn, data)}>
                        <Text style={sflat(s2.itemBtnTxt, btn.style_text)}>{btn.text}</Text></Button>)}</>
                  </Right>}
                </ListItem>}
            />
          </View>
          </>}

          {/* empty list */}
          {!this.state.loading && this.state.list.length == 0 &&
            <View style={s.noMessageCt}>
              {/* <Icon name="message-bulleted-off" type="MaterialCommunityIcons" style={s.noMessageIco} /> */}
              <Text style={s.noMessage}>Lista vazia</Text>
            </View>
          }

          {/* loader */}
          {this.state.loading &&
            <View style={s.loadingCtBgGray}>
              <ActivityIndicator />
              <Text style={s.loading}>Carregando...</Text></View>}

      </Container>
    )
  }
}

const s2 = StyleSheet.create({
  topTextCt: {paddingHorizontal: 20, paddingTop: 30, paddingBottom: 15, backgroundColor: "#F2F4F7", alignSelf: 'center'},
  topText: {textAlign: 'center', fontSize: 15, color: '#999', lineHeight: 22},

  searchCt: {paddingHorizontal: 20, paddingTop: 0, paddingBottom: 20, backgroundColor: "#F2F4F7", alignSelf: 'center'},
  rightBtn: {},
  iconRightBtn: {
    color: 'white', 
    fontSize: ifWeb(22, 20), 
    marginTop: ifWeb(2, ifIos(0, 5)), 
    marginRight: ifAndroid(0, 0)
  },
  itemBtnTxt: {color: '#bbb', fontFamily: 'Roboto', fontSize: 15},
  itemBtn: {},
  cellRight: {flexDirection: 'row-reverse', flex: 0},

  pillCt: {borderWidth: 1, borderColor: '#ccc', padding: 3, margin: 5, height: 24},
  pillTxt: {color: '#aaa', fontSize: 11, fontFamily: "Roboto"},

  blockCt: {height: 20, padding: 3, margin: 5},
  blockTxt: {color: '#aaa', fontSize: 11, fontFamily: "Roboto"},
  
  txtRequestCt: {marginTop: -20},
  txtRequestTxt: {padding: 0},

  // blockCt: {padding: 3, marginRight: 5},
  // blockTxt: {color: '#aaa', fontSize: 14, fontFamily: "Roboto"},
})