import { StyleSheet } from "react-native";
import buttons from "../pieces/buttons";
import cells from "../pieces/cells";
import forms from "../pieces/forms";
import { common, header, i } from "../pieces/shared";
import { ifMob } from "../../utils/utils";

const s = StyleSheet.create({
  ...common,
  ...header, 
  ...cells,
  ...buttons,
  ...forms,

  contBg: ifMob({backgroundColor: '#F2F4F7'})
})

const i2 = i;
export { s, i2 as i };

