import { rmsg } from "./utils-view"
import { toast } from "../../plataform/react-toastify"
import { isWeb } from "./utils"
import debounce from 'lodash.debounce';

class NetChecker {
  showMsg: Function
  showWait = 1000
  onlineDuration = 4000
  offlineDuration = 12000
  lastStatus = null

  constructor() {
    this.showMsg = debounce(this._showMsg, this.showWait)
  }

  netListener = (state) => {
    if (!state || typeof(state.isInternetReachable) != 'boolean')
      return

    const status = state.isInternetReachable

    if (this.mustShow(status))
      this.showMsg(status)
  }

  mustShow = (status) => {
    let justInitedLastStatus = false

    if (this.lastStatus === null) {
      this.lastStatus = status
      justInitedLastStatus = true
    }

    if (this.lastStatus === status) {
      return (justInitedLastStatus && !status)
    } else {
      this.lastStatus = status
      return true
    }
  }

  _showMsg = (status) => {
    if (isWeb)
      toast.dismiss()

    if (status)
      rmsg("Você está online", {type: 's', duration: this.onlineDuration})
    else
      rmsg("Você está offline", {type: 'e', duration: this.offlineDuration})
  }
}

export const netChecker = new NetChecker()
