import Sentry from '../../../plataform/sentry';
import { Button, Container, Form, Icon, Input, Item, Label, Spinner, Text, Title, Toast, View } from "native-base";
import React, { Fragment } from "react";
import { Image, ImageBackground, Keyboard, Platform, StatusBar, ScrollView, StyleSheet } from "react-native";
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { appendConf, Config, passThrough, isDev, resetAddress } from "../../config";
import { i, s } from "../../theme/screens/login";
import conn from "../../utils/conn";
import { anyBlank, blank, simpleCalert, isMob, isWeb, addKey, userCompany, ifWeb, ifSmall, base64, ifIos, ci, isAndroid, logScreen, isIos, getUriQuery } from "../../utils/utils";
import NetInfo from "@react-native-community/netinfo";
import { pushNotifRouter } from '../../../plataform/notif/push-notif-router';
import { PushNotif } from '../../../plataform/notif/push-notif';
import { netChecker } from '../../utils/net-checker';
import pubSub from '../../utils/pub-sub';
import { menuHandler } from '../../utils/menu-handler';
import { rmsg } from '../../utils/utils-view';

enum pushCmd {
  registerOpenner,
  unRegisterOpenner,
  requestPermissions
}

interface Props {navigation: any}

interface State {
  username: string,
  password: string,
  fetching: boolean,
  isAttempting: boolean,
  isAttemptingTk: any,
  showPass: boolean,
  capsLockOn: boolean,
  allwaysShowSignin: boolean,
  pickupUsers: any[],
}

export class SigninScreen extends React.Component<Props, State> {
  usernameRef: any|null = null
  passwordRef: any|null = null
  passwordCt: any|null = null
  submitBtnRef: any|null = null
  signupBtnRef: any|null = null
  focusListener: any
  netCheckUnsubscribe: any

  constructor (props) {
    super(props)
    this.state = {
      username: '',
      password: '',
      allwaysShowSignin: false,
      fetching: false,
      isAttempting: false,
      isAttemptingTk: {},
      showPass: false,
      capsLockOn: false,
      pickupUsers: [],
    }
    this.focusListener = this.props.navigation.addListener('willFocus', () => {
      this.willFocus()
    })
    this.pushNotif(pushCmd.registerOpenner)
  }

  // notifications

  pushNotif = (cmd: pushCmd) => {
    if (isWeb)
      return

    switch (cmd) {
      case pushCmd.registerOpenner:      
        pushNotifRouter.registerOpenner(this.notifOpenner.bind(this))
        break;
      case pushCmd.unRegisterOpenner:
        pushNotifRouter.unRegisterOpenner()      
        break;
      case pushCmd.requestPermissions:
        PushNotif.requestPermissions()
        break;
      default:
        break;
    }
  }

  notifOpenner(screen, navParams) {
    this.props.navigation.push(screen, navParams)
  }

  // Callbacks

  async componentDidMount(){
    Sentry.addBreadcrumb({message: `mounted SigninScreen`})
    logScreen('signin')
    this.checkLogged()
    this.updatePickupUsers()
    this.netCheckUnsubscribe = NetInfo.addEventListener(netChecker.netListener)
    pubSub.subscribe('logout', this.logout)
  }

  logout = (msg: string) => {
    if (msg) {
      rmsg(msg)
    }
    menuHandler.exit()
  }

  componentWillUnmount(){
    this.pushNotif(pushCmd.unRegisterOpenner)
    this.focusListener.remove()
    this.netCheckUnsubscribe()
    pubSub.unsubscribe('logout', this.logout)
  }

  willFocus = () => {
    this.updatePickupUsers()
  }

  // login

  onLoginTk(user: any) {
    this.onLogin(
      base64(`${Config.hmacPrefix}:${user.id}:${user.token}`), 
      user
    )
  }

  onLogin(token?: string, tkUser?:any) {
    const { username, password } = this.state

    if (isWeb && window['OneSignal'] && window['OneSignal'].showSlidedownPrompt)
      window['OneSignal'].showSlidedownPrompt()

    if (!passThrough && !token) {
      if (anyBlank(username, password)) {
        return simpleCalert("Preencha todos os campos", "Erro")
      }
    }

    const isAttemptingTk = {}
    if (tkUser) isAttemptingTk[tkUser.id] = true
    this.setState({isAttempting: true, isAttemptingTk: isAttemptingTk})

    let data:any = {username: username, password: password, token: token}
    if (passThrough) data = Config.devlogin

    conn.fetch('authenticate', 'POST', data).then(resp => {
      resp.success ?
        this.onSuccess(resp) :
        this.onError(new Error(resp.errContent || "Tente novamente."), !!token)
    }).catch(this.onError)
  }

  signinUser(user, clearFields = true){
    conn.setUser(user)
      .then(() => {
        this.enterLoggedArea()
        if (clearFields)
          setTimeout(() => {
            this.setState({
              isAttempting: false,
              isAttemptingTk: {},
              username: '',
              password: '',
              allwaysShowSignin: false,
            })
          }, 500)
      })
  }

  onSuccess(resp){
    console.log('onSuccess', resp)
    this.setState({showPass: false})

    const user = resp.item
    user.sigmeta = resp.sigmeta
    appendConf(user.sigmeta)

    this.signinUser(user)
  }

  onError = (error, withToken = false) => {
    console.log('onError', error)
    this.setState({isAttempting: false, isAttemptingTk: {}}, () => {
      // if (!withToken)
        setTimeout(() => {
          simpleCalert("Erro: " + error.message)
        }, 20)
    })
  }

  enterLoggedArea = () => {
    if (isMob)
      this.pushNotif(pushCmd.requestPermissions)
    
    this.props.navigation.navigate(isWeb ? 
      "loggedNavWrapper" : 
      "dummyScreen")
  }

  // UI

  handleChangeUsername = (text) => {
    this.setState({ username: text })
  }

  handleChangePass = (text) => {
      this.setState({ password: text })
  }

  showPassTapped = () => {
    this.setState({showPass: !this.state.showPass})
  }

  // Util

  checkLogged(){
    let token:string = ''
    
    if (isWeb) {
      const query = getUriQuery()
      token = query['sk'] || ''
    }

    if (token) {
      resetAddress()
      this.onLogin(token)
    } else if (passThrough) {
      this.onLogin()
    } else {
      conn.getUser().then(user => {
        if (!user) return
        appendConf(user.sigmeta || user.app_login_data) // tocleanup: user.app_login_data
        this.enterLoggedArea()
      })
    }
  }

  onLastFieldBlur = () => {
    if (!blank(this.state.username) && !blank(this.state.password)) {
      Keyboard.dismiss()
      setTimeout(() => {
        this.onLogin()
      }, 500)
    }
  }

  setTabIndex() {
    let tabindex = 1
    "usernameRef passwordRef submitBtnRef signupBtnRef".split(/\s+/).forEach(ref => {
      if (this[ref]) {
        this[ref]._root.setNativeProps({tabIndex: tabindex})
        tabindex += 1
      }
    })
  }

  disableTabNav = (component) => {
    if (component)
      // TODO: remove return null and check with tom commented line
      return null
      // component._root.setNativeProps({tabIndex: -1})
  }

  getPickupUsers = async () => {
    let p = await conn.getPickupUsers()
    if (p == []) {
      p = [
        {
            "id": 3220,
            "email": "mateusscmoura@hotmail.com",
            "name": "Mateus",
            "phone": "",
            "username": "Madm",
            "is_admin": true,
            "company_id": 2,
            "group_name": "Imobicon",
            "company_name": "Imobicon",
            "product_label": "imóvel",
            "token": "/Z9CIhJTZWrhrSRqYlV0gXWMxWExPTN15DtsUIwwO/0=\n",
            "allow_reply_channels": 3,
            "sigmeta": {
                "uappend": "22443711",
                "onesignalApiId": "3e7b99f0-204a-4c76-8eea-d99221affc82",
                "apiId": "1",
                "apiKey": "BQ7J3QgqqG2HgYzPG5bQmKEm73ceWWgwm2LJ2cL",
                "apiBasicAuth": "admin:8d9f8d",
                "initialActiveFilter": "prio_any_task"
            },
        }
    ]
    }
    return !blank(p) ? p : null
  }

  exitAllAccounts = async () => {
    await conn.clearPickupUsers()
    this.updatePickupUsers()
  }

  updatePickupUsers = async () => {
    this.setState({
      pickupUsers: await this.getPickupUsers()
    })
  }

  backToSignin = () => {
    this.setState({
      allwaysShowSignin: true
    })
  }

  btnUserLabel = (user) => {
    let lbl = `${user.username}@${userCompany(user)}`
    const max = 22
    if (lbl.length > max)
      lbl = `${lbl.substr(0, max - 3).trim()}...`
    return lbl
  }

  render () {
    const { username, password } = this.state
    const editable = !this.state.fetching
    let inputOpt = {}

    if (Platform.OS != 'web')
      inputOpt = {...inputOpt, spellCheck: false, keyboardType: "default", autoCapitalize: 'none', autoCorrect: false, selectTextOnFocus: true, blurOnSubmit: false}

    return (
      <ImageBackground source={i.loginBg} style={s.bgImg}>
      <KeyboardAwareScrollView keyboardShouldPersistTaps={'handled'}>
      <StatusBar barStyle="light-content"/>
      {/* 'marginBottom: 20' fix the white stripe at bottom on web */}
      <Container style={StyleSheet.flatten([s.cnt, ifWeb({marginBottom: 20})])} testID='loginView'> 
        <View style={s.login}>

          {/* logo */}
          <View>
            <Image source={ifWeb({uri: '/static/img/logo.png'}, i.logo)} style={s.logo}/>
            <Title style={s.logoName}>Leadfy</Title></View>
            
          {(!blank(this.state.pickupUsers) && !this.state.allwaysShowSignin) &&
            <View style={s.form}>
              <View style={{height: ifWeb(280, 220), marginTop: ifWeb(-40, 40), width: ifWeb(350, ifIos(340, 320)), paddingTop: 7, paddingBottom: 7, alignSelf: "center", backgroundColor: 'white'}}>
                <ScrollView>
                  {this.state.pickupUsers.map((option, index) =>
                  <Fragment key={option.id}>
                    <Button block onPress={() => this.onLoginTk(option)} style={StyleSheet.flatten([s.btn, {marginTop: 10}])} disabled={this.state.isAttempting}
                        activeOpacity={1} ref={c => this.submitBtnRef = c} testID={option.username}>
                      <Text style={s.btnTxt}>{this.btnUserLabel(option)}</Text>
                      {this.state.isAttemptingTk[option.id] &&
                        <Spinner color='white' size="small" />}</Button>
                  </Fragment>
                  )}
                  <View style={{marginTop: 20}}></View>
                </ScrollView>
              </View>
              <View style={{marginTop: 10}}></View>
              <View style={{height: 35}}>
                <Button style={s.linkBtn} transparent onPress={() => this.backToSignin() }
                    ref={c => this.signupBtnRef = c} testID='signinWithNewAccount'>
                  <Text style={s.linkTxt}>Entrar com uma nova conta</Text>
                </Button>
              </View>
              <View style={{height: 35}}>
                <Button style={s.linkBtn} transparent onPress={() => this.exitAllAccounts() }
                    ref={c => this.signupBtnRef = c} testID='leaveAllAccount'>
                  <Text style={s.linkTxt}>Sair de todas as contas</Text>
                </Button>
              </View>
            </View>
          ||
          // {/* form */}
          <View style={s.form}>
            {/* <Form style={s.loginForm} getRef={(a: React.RefObject<Form>) => {if (a) a._root current._root . ._root.setNativeProps({opacity: 0.1})}}> */}
            <Form style={s.loginForm}>

              {/* User */}
              <Label style={s.inputLbl}>Usuário</Label>
              <Item regular style={s.formitem} ref={this.disableTabNav}>
                <Input style={s.input} editable={editable}
                  ref={c => this.usernameRef = c}
                  value={username}
                  textContentType="username"
                  returnKeyType="next"
                  onSubmitEditing={() => this.passwordRef._root.focus()}
                  onChangeText={this.handleChangeUsername}
                  autoFocus={isWeb} {...inputOpt}
                  testID='userLogin' /></Item>

              {/* Password */}
              <Label style={s.inputLbl}>Senha</Label>
              <Item regular style={s.formitem} ref={this.disableTabNav}>
                {/* TODO (web): Fix 'Password field is not contained in a form' https://github.com/necolas/react-native-web/issues/1156
                      Currently it not working with passwordCt above. */}
                <Input style={s.input} editable={editable}
                  ref={c => this.passwordRef = c}
                  onSubmitEditing={this.onLastFieldBlur}
                  value={password}
                  textContentType="password"
                  returnKeyType="go"
                  selectTextOnFocus={true}
                  blurOnSubmit={false}
                  onChangeText={this.handleChangePass}
                  secureTextEntry={!this.state.showPass} {...inputOpt}
                  testID='passwordLogin' />

                {this.state.capsLockOn &&
                  <Icon name='keyboard-caps' type='MaterialCommunityIcons' style={s.eye} ref={this.disableTabNav} />}

                {this.state.showPass &&
                  <Icon name='md-eye-off' onPress={this.showPassTapped} style={s.eye} ref={this.disableTabNav} />}
                {!this.state.showPass &&
                  <Icon name='md-eye' onPress={this.showPassTapped} style={s.eye} ref={this.disableTabNav} />}
              </Item>
            </Form>

            {/* button */}
            <View style={s.formSubmit}>
              <Button block onPress={() => this.onLogin()} style={s.btn} disabled={this.state.isAttempting}
                  activeOpacity={1} ref={c => this.submitBtnRef = c} testID='submitLogin'>
                <Text style={s.btnTxt}>Entrar</Text>
                {this.state.isAttempting &&
                  <Spinner color='white' size="small" />}</Button></View>

            {/* signup */}
            {this.state.pickupUsers &&
            <View style={{height: 35}}>
              <Button style={s.linkBtn} transparent onPress={() => this.setState({allwaysShowSignin: false}) }
                  ref={c => this.signupBtnRef = c} testID='chooseLoggedAccount'>
                <Text style={s.linkTxt}>Escolher conta logada</Text>
              </Button>
            </View>}
            {!isIos &&
            <View style={{marginTop: 10}}>
              <Button style={[s2.btnSignup, this.state.pickupUsers ? {marginTop: 20} : {marginTop: 40}]} transparent onPress={() => this.props.navigation.navigate('signupScreen') }
                  ref={c => this.signupBtnRef = c} testID='cadUserBtn'>
                <Text style={s2.btnTxtSignup}>30 dias Grátis!</Text>
              </Button>
              <Text style={[s.linkTxt, {marginTop: 10, fontSize: 12, textAlign: 'center'}]}>Clique e cadastre-se para obter {'\n'}30 dias de teste grátis</Text>
            </View>}
          </View>
          }

          {isDev && <Text style={{textAlign: 'center'}}>{`baseUrl: ${Config.baseUrl}`}</Text>}
          {/* <Text style={{textAlign: 'center'}}>{`baseUrl: ${Config.baseUrl} isDev: ${Config.isDev}`}</Text> */}

          {/* version */}
          <View style={s.versionCt}>
              <Text style={[s.versionTx, isWeb ? {marginTop: this.state.allwaysShowSignin ? (this.state.pickupUsers ? 50 : 20) : (this.state.pickupUsers ? 192 : 80)} : null]}>versão {Config.appVersion}</Text></View>

        </View>
      </Container>
      </KeyboardAwareScrollView>
      </ImageBackground>
    )
  }
}

const s2 = StyleSheet.create({
  btnSignup: {
    marginTop: 60,
    backgroundColor: "#5dc6ab",
    width: ifSmall(200, 250),
    height: 50,
    borderRadius: 5,
    alignSelf: "center",
    shadowColor: "black",
    shadowOffset: {width: 0, height: 1},
    shadowOpacity: .2,
    shadowRadius: 2,
    justifyContent: 'center'
  },
  btnTxtSignup: {
      fontSize: ifWeb(18, 17),
      fontFamily: "Roboto-Medium",
      color: '#fff',
  },
})