import { StyleSheet } from "react-native";
import {common, header, i} from "../pieces/shared"
import list from "../pieces/list";
import noMessage from "../pieces/noMessage";
import buttons from "../pieces/buttons";

const p:any = {
  ...common,
  ...buttons,
  ...header,
  ...list,
  ...noMessage,

  forwardIcon: {
    // marginTop: 0, 
    // marginBottom: 0,
    // paddingTop: 0,
    // paddingBottom: 0, 
    opacity: 0.4
  },

  msg: {
    marginTop: 30, 
    paddingHorizontal: 50, 
    marginBottom: 20,
    alignSelf: 'center',
    fontSize: 13,
    fontFamily: "Roboto",
    color: "#6A8287",
    flex: 0,
  },
}
const s = StyleSheet.create(p)

const i2 = i;
export {s, i2 as i}
