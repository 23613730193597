import { Button, Text } from "native-base";
import React from "react";
import { s } from "../theme/screens/obs";

export interface WebDatepickerInputProps {
  onClick?: () => void  
  value?: string
  customValue?: string
}

export default class WebDatepickerInput extends React.Component <WebDatepickerInputProps, {}>{
  render () {
    return (
      <Button transparent style={s.cellDateBtn} onPress={this.props.onClick}>
        <Text style={s.cellDateTxt}>
          {this.props.customValue || this.props.value}</Text></Button>
    )
  }
}
