import { ifWeb } from "../../utils/utils";

const font = {
  fontFamily: "Roboto",
  fontSize: 13,
  color: "#6A8287",
}

export default {
  // msg list
  msgsCt: {
    // backgroundColor: "blue",
    ...ifWeb({
      marginTop: 20,
    }, {
      flex: 1
    }),
  },
  msgs: {
    marginRight: 23,
    marginLeft: 7,
  },
  listItem: {
    paddingRight: 0,
  },
  msgIco: {
    flex: 0,
    width: 17, 
    height: 17,
  },
  msgPreview: {
    ...font,
    flex: 1,
    paddingLeft: 15,
    paddingRight: 15,
  },
  msgDate: {    
    ...font,
    flex: 0,
    fontSize: 10,
    opacity: .75,
  },
  notReadMsgBall: { 
    color: 'blue', 
    fontSize: ifWeb(12, 8), 
    marginLeft: 10, 
    marginRight: -5 
  }
}

