export const Config = {
  devlogin: null,
  // isDev: true,
  isDev: false,
  
  appVersion: "4.43.2",
  appVersionDate: '08/07/2024',

  appName: 'LeadfyImob',
  reactotron: {host: '192.168.0.128'},
  apiVersion: "2.1",
  initialActiveFilter: 'todo',
  baseUrl: 'https://api.leadfy.com.br/',
  hmacPrefix: 'jkstewyutwve4vr64%$Vytrftycy',
  sentryDSN: 'https://f1ba8fed9fb0493999537da89250a217@sentry.leadfy.com.br/2',
  sentryDSNWeb: "https://1080241ca19d4be89d63343ee8d26d31@sentry.leadfy.com.br/3",
  documentTitle: 'WebApp - Leadfy Imob',
  appSubdir: '',
  
  storeDeviceToken: 'deviceToken',
  
  apiId: "",
  apiKey: "",
  apiBasicAuth: "",
  uappend: "",
}

export const isDev = Config.isDev === true
export const passThrough = isDev && Config.devlogin !== null

export const appendConf = (atts: any[]) => {
  Object.keys(atts).forEach((k)=> {
    Config[k] = atts[k]
  })
}

// if (isDev) 
  // Config.baseUrl = 'http://192.168.50.145:8080/'
  // Config.baseUrl = 'https://api-alfa.leadfy.com.br/'
  // Config.baseUrl = 'http://10.10.0.207:9293/'
  // Config.baseUrl = 'http://127.0.0.1:3001/'

export const resetAddress = () => {
  window.history.replaceState(null, Config.documentTitle, Config.appSubdir)
}
