import { StyleSheet } from "react-native";
import { ifSmall, ifWeb } from "../../utils/utils";

const btn = {
  borderRadius: 4,
  height: ifSmall(50, 60),
  paddingHorizontal: ifSmall(5, 10),
  marginHorizontal: 5,
}

const btnTxt = {
  fontFamily: "Roboto-Medium",
  fontSize: ifSmall(11.5, 13),
}

const detailBtn = {
  marginTop: 30,
  backgroundColor: "#12B2FE",
  borderRadius: 4,
  alignSelf: "center",
}

const detailBtnReport = {
  marginTop: 15,
  marginBottom: 10,
  marginLeft: 8,
  marginRight: 8,
  backgroundColor: "transparent",
  borderColor: "#10B1E3",
  borderWidth: 2*StyleSheet.hairlineWidth,
}

const btnTxtReport = {
  color: "#10B1E3",
  fontFamily: 'Roboto-Medium',
  height: 20,
}

export default {
  btns: {
    flex: 1,
    flexDirection: 'row',
    paddingHorizontal: 10,
    justifyContent: "center",
    paddingVertical: 15,
  },
  btn1: {
    ...btn,
    backgroundColor: "#12B2FE",
  },
  btn2: {
    ...btn,
    backgroundColor: "#41C980",
  },
  btnGreenSmall: {
    ...btn,
    height: 35,
    backgroundColor: "#41C980",
  },
  btn3: {
    ...btn,
    backgroundColor: "white",
    borderColor: "#DA5746",
    ...ifWeb({
      borderTopWidth: 1,
      borderBottomWidth: 1,
      borderLeftWidth: 1,
      borderRightWidth: 1,
    }, {
      borderWidth: StyleSheet.hairlineWidth,
    })
  },
  btn4: {
    ...btn,
    backgroundColor: "white",
    ...ifWeb({
      borderTopWidth: 1,
      borderBottomWidth: 1,
      borderLeftWidth: 1,
      borderRightWidth: 1,
    }, {
      borderWidth: StyleSheet.hairlineWidth,
    }),
    borderColor: "green",
    marginTop: 18,
  },
  btnTxt: {
    ...btnTxt,
    justifyContent: "center",
  },
  btnTxtGreen: {
    fontSize: 13,
    color: "green",
  },
  btnTxtRed: {
    ...btnTxt,
    color: "#DA5746",
    justifyContent: "center",
  },

  // detail button
  btn: {
    marginTop: 20,
    backgroundColor: "#12B2FE",
    borderRadius: 4,
    alignSelf: "center", 
  },
  naTxt: {
    marginTop: 20,
    alignSelf: "center", 
    color: '#aaa',
    fontSize: 13,
    fontStyle: 'italic'
  },

  // Detail Btn
  detailBtn: {
    ...detailBtn,
  },

  // Detail Btn Report
  detailBtnReport: {
    // ...detailBtn,
    ...detailBtnReport,
  },
  detailBtnReportActive: {
    ...detailBtnReport,
    backgroundColor: "#10B1E3",
  },
  btnTxtReport: {
    ...btnTxtReport,
  },
  btnTxtReportActive: {
    ...btnTxtReport,
    color: "white",
  },

}
