class GlobalVar {
  reloadLeads:Date|any = null
  reloadMsg = ''
  reloadUsers:Date|any = null
  reloadUsersMsg = ''
  reloadGroups:Date|any = null
}

const globalVar = new GlobalVar()
export default globalVar
